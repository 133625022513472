import { Box, Button, Text, useBoolean } from '@chakra-ui/react'
import { Heading, Modal } from 'chakra-lib'
import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn,
  HeadingContainer,
} from 'chakra-lib/layout'
import { useHistory, useParams } from 'react-router-dom'
import { faqTextOptions } from '../Faq.constants'
import { ItemForm } from './ItemForm'
import { useFaqCreate } from './services/useFaqCreate'
import { useFaqRemove } from './services/useFaqRemove'
import { useFaqUpdate } from './services/useFaqUpdate'
import { useFetchItem } from './services/useFetchItem'

export const Item = () => {
  const history = useHistory()
  const { id } = useParams()
  const [removeIsOpen, setOpenRemove] = useBoolean()
  const isNew = !id

  const handleBack = () => {
    history.goBack()
  }

  const { data, isLoading: itemIsLoading } = useFetchItem(id, { enabled: !!id })

  const { mutate: updateItem, isLoading: isUpdating } = useFaqUpdate(id, {
    onSuccess: () => {
      handleBack()
    },
  })

  const { mutate: createItem, isLoading: isCreating } = useFaqCreate({
    onSuccess: () => {
      handleBack()
    },
  })

  const { mutate: removeItem, isLoading: isRemoving } = useFaqRemove(id, {
    onSuccess: () => {
      handleBack()
    },
  })

  const submitHandle = (values) => {
    const payload = {
      question: faqTextOptions.map((option, index) => ({
        language: option.value,
        text: values.list[index]?.question || '',
      })),
      answer: faqTextOptions.map((option, index) => ({
        language: option.value,
        text: values.list[index]?.answer || '',
      })),
      order: values.order,
      category: values.category
    }

    if (isNew) {
      createItem(payload)
    } else {
      updateItem(payload)
    }
  }

  const removeHandle = () => {
    removeItem()
  }

  const list = data
    ? faqTextOptions.map((option) => {
        return {
          question: data.question.find((item) => item.language === option.value)?.text,
          answer: data.answer.find((item) => item.language === option.value)?.text,
        }
      })
    : []

  return (
    <Container>
      <CardContainer onBack={handleBack} indentTop={120}>
        <ContentContainer>
          <ContentContainerColumn>
            <HeadingContainer>
              <Heading>{isNew ? 'Добавить' : 'Обновить'}</Heading>
            </HeadingContainer>
            <ItemForm
              initialValues={
                !isNew ? { list, order: data?.order, category: data?.category } : undefined
              }
              onSubmit={submitHandle}
              onRemove={setOpenRemove.on}
              isLoading={itemIsLoading || isUpdating || isCreating}
            />
          </ContentContainerColumn>
        </ContentContainer>
      </CardContainer>

      <Modal isOpen={removeIsOpen} onClose={setOpenRemove.off} title="Удалить элемент">
        <Text fontSize="20px" color="red.500">
          Внимание!
        </Text>
        <Text>Данное действие нельзя будет отменить.</Text>
        <Box pt="30px">
          <Button w="full" colorScheme="red" isLoading={isRemoving} onClick={removeHandle}>
            Продолжить
          </Button>
        </Box>
      </Modal>
    </Container>
  )
}
