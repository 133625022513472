export const TYPE_DOCUMENTS_LABEL = {
  RUSSIA_PHOTO_FROM_PASSPORT_SUPPORT: 'Фото из паспорта РФ (загруженное поддержкой)',
  SELFIE_WITH_PASSPORT_SUPPORT: 'Селфи (загруженное поддержкой)',
  PASSPORT_FIRST_PAGE_SUPPORT: 'Первая страница паспорта (загруженное поддержкой)',
  ARMENIA_PHOTO_FROM_PASSPORT_SUPPORT: 'Фото из паспорта Армении (загруженное поддержкой)',
  ARMENIA_SELFIE_WITH_PASSPORT_SUPPORT: 'Селфи (загруженное поддержкой)',
  ARMENIA_PASSPORT_FIRST_PAGE_SUPPORT: 'Первая страница паспорта Армении (загруженное поддержкой)',
  ARMENIA_PHOTO_FROM_IDBANK_SUPPORT: 'Фото в системе IDbank (загруженное поддержкой)',
  SELFIE_WITH_PASSPORT: 'Селфи',
  ARMENIA_SELFIE_WITH_PASSPORT: 'Селфи',
  RUSSIA_PHOTO_FROM_PASSPORT: 'Фото из паспорта РФ',
  ARMENIA_PHOTO_FROM_PASSPORT: 'Фото из паспорта Армении',
  ARMENIA_PASSPORT_FIRST_PAGE: 'Первая страница паспорта Армении',
  PASSPORT_FIRST_PAGE: 'Первая страница паспорта',
  ARMENIA_PHOTO_FROM_IDBANK: 'Фото в системе IDbank',
}

export const TYPE_DOCUMENTS_STATUS_LABEL = {
  NEED_APPROVE_IN_REPOSITORY: 'Подтверждение в репозитории',
  NEED_APPROVE: 'Подтверждение',
}
