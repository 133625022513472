import React from 'react'
import { Box, Flex, Grid, useColorModeValue } from '@chakra-ui/react'
import { Container } from '../Container'
import { NavigationMenu } from '../NavigationMenu'

export const Header = ({
  renderLogo,
  renderSubTitle,
  renderNavigationItem,
  renderProfileWidget,
}) => {
  const themeBgSubTitle = useColorModeValue('gray.50', 'var(--chakra-colors-whiteAlpha-200)')
  return (
    <Box borderBottomWidth={1} position="relative" zIndex={10}>
      {renderSubTitle && (
        <Flex
          alignItems="center"
          justifyContent="flex-end"
          height="28px"
          fontSize="0.7rem"
          backgroundColor={themeBgSubTitle}
          zIndex={10}
          color="gray.400"
          padding="0 18px"
        >
          {renderSubTitle}
        </Flex>
      )}
      <Container pl="16px" pr="16px">
        <Grid
          height="70px"
          justifyContent="space-between"
          alignItems="center"
          templateColumns="1fr 1fr"
          width="100%"
        >
          <Flex alignItems="center">
            <Flex alignItems="center" flex="none">
              {renderLogo}
            </Flex>
            <NavigationMenu ml="9px">{renderNavigationItem}</NavigationMenu>
          </Flex>
          <Box justifySelf="flex-end">{renderProfileWidget}</Box>
        </Grid>
      </Container>
    </Box>
  )
}
