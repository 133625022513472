import React from 'react'
import { Grid } from '@chakra-ui/react'
import { useWindowHeight } from '../../useWindowHeight'

export const SnippetsGridContext = React.createContext({})

export const SnippetsContainer = ({ indentTop = 0, children, ...props }) => {
  const windowHeight = useWindowHeight()
  return (
    <SnippetsGridContext.Provider
      value={{
        windowHeight,
        indentTop,
      }}
    >
      <Grid templateColumns="1fr 290px" flex={1} {...props}>
        {children}
      </Grid>
    </SnippetsGridContext.Provider>
  )
}
