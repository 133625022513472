import { Snippets, SnippetsHeading, SnippetsItem, SnippetsList } from 'chakra-lib'
import { HeadingItem, ItemIcon, ItemLink, ItemText } from 'chakra-lib/Snippets'
import {
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBoolean,
  useToast,
} from '@chakra-ui/react'
import useFetchCustomer from './services/useFetchCustomer'
import useFetchEvents from './services/useFetchEvents'
import React, { useEffect } from 'react'
import { SiSublimetext } from 'react-icons/si'
import useFetchReferralTransferCustomer from './services/useFetchReferralTransferCustomer'
import { getDateTimeReadFormat } from 'utils/dateFormatting'
import { ActionButton } from 'components/ActionButton'
import useTransferProcess from './services/useTransferProcess'
import useTransferRefund from './services/useTransferRefund'
import useFetchRef from './services/useFetchRef'
import { useHistory } from 'react-router-dom'
import { InvitedUsersModal } from './InvitedUsersModal/InvitedUsersModal'
import { useSelector } from 'react-redux'
import { getAccess } from '../../../../components/Private'
import { ROLES } from '../../../../model/user.model'

const role = {
  REFERRAL: 'Приглашенный',
  REFERRER: 'Пригласивший',
}

export const Referral = ({ phone }) => {
  const toast = useToast()
  const history = useHistory()
  const user = useSelector((state) => state.authentication.user)
  const isAdmin = getAccess(user.roles, [ROLES.admin])
  const { fetchCustomer, dataCustomer } = useFetchCustomer(phone)
  const { fetchEvents, dataEvents } = useFetchEvents(phone)
  const { fetchReferralTransferCustomer, dataTransferCustomer } =
    useFetchReferralTransferCustomer(phone)
  const { setProcess } = useTransferProcess()
  const { setRefund } = useTransferRefund()
  const { fetchRef, dataRef } = useFetchRef()
  const [isOpenInvitedUsersModal, setOpenInvitedUsersModal] = useBoolean(false)

  const handleProcessClick = (id) =>
    new Promise(async (resolve, reject) => {
      try {
        await setProcess(id)
        await fetchCustomer()
        await fetchReferralTransferCustomer()
        toast({
          description: 'Успешно зачислено на счет',
          status: 'success',
        })
        resolve()
      } finally {
        reject()
      }
    })

  const handleRefundClick = (id) =>
    new Promise(async (resolve, reject) => {
      try {
        await setRefund(id)
        await fetchCustomer()
        await fetchReferralTransferCustomer()
        toast({
          description: 'Успешно отказано',
          status: 'success',
        })
      
        resolve()
      } finally {
        reject()
      }
    })

  useEffect(() => {
    const getData = async () => {
      try {
        await fetchCustomer()
        await fetchEvents()
        await fetchReferralTransferCustomer()
      } catch (e) {
        toast({
          description: 'Пользователь не зарегистрирован в реферальной программе',
          status: 'warning',
        })
      }
    }
    if (isAdmin) {
      getData()
    }
  }, [])

  useEffect(() => {
    if (dataCustomer?.invitationCode) {
      fetchRef(dataCustomer?.invitationCode)
    }
  }, [dataCustomer])

  return (
    <>
      <HStack spacing="20px" mb="30px" alignItems="flex-start">
        <Stack spacing="4px" width="160px">
          <Text fontSize="0.9rem">Баланс бонусов</Text>
          <Text fontSize="1.4rem" fontWeight="600">
            {dataCustomer?.reward}
          </Text>
        </Stack>
        <Stack spacing="4px" width="160px">
          <Text fontSize="0.9rem">Заблокировано</Text>
          <Text fontSize="1.4rem" fontWeight="600">
            {dataCustomer?.blockedReward}
          </Text>
        </Stack>
        <Stack spacing="4px" justifyContent="center" width="190px">
          <Text fontSize="0.9rem">Зарегистрирован по коду</Text>
          {dataCustomer?.invitationCode ? (
            <Text
              fontSize="1.2rem"
              fontWeight="600"
              transition="0.3s"
              cursor="pointer"
              onClick={() => history.push(`/user/phone=${dataRef?.origin}`)}
              _hover={{
                opacity: 0.5,
              }}
            >
              {dataCustomer?.invitationCode}
            </Text>
          ) : (
            <Text fontWeight="600" fontSize="1.2rem" opacity={0.3}>
              Нет
            </Text>
          )}
        </Stack>
        <Stack spacing="4px" justifyContent="center" width="190px">
          <Text fontSize="0.9rem">Приглашены по коду</Text>
          {dataCustomer?.originCode ? (
            <Text
              fontSize="1.2rem"
              fontWeight="600"
              transition="0.3s"
              cursor="pointer"
              onClick={() => setOpenInvitedUsersModal.on()}
              _hover={{
                opacity: 0.5,
              }}
            >
              {dataCustomer?.originCode}
            </Text>
          ) : (
            <Text fontWeight="600" fontSize="1.2rem" opacity={0.3}>
              Нет
            </Text>
          )}
        </Stack>
      </HStack>
      <Tabs variant="soft-rounded" colorScheme="orange" size="sm">
        <TabList>
          <Tab>Переводы</Tab>
          <Tab>События</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            <Snippets
              columns="42px 80px minmax(180px, 1fr) minmax(180px, 1fr) 180px 200px 200px 230px"
              itemWidth="1200px"
            >
              <SnippetsHeading>
                <HeadingItem />
                <HeadingItem>N</HeadingItem>
                <HeadingItem>Бонусы</HeadingItem>
                <HeadingItem>Сумма, ₽</HeadingItem>
                <HeadingItem>Дата операции</HeadingItem>
                <HeadingItem>Дата запроса</HeadingItem>
                <HeadingItem>Статус</HeadingItem>
              </SnippetsHeading>
              <SnippetsList
                activePage={0}
                totalPages={0}
                data={dataTransferCustomer}
                keyField="eventCode"
              >
                {(item) => {
                  const status = {
                    '-1': 'Отклонен',
                    0: 'Не проведен',
                    1: 'Проведен',
                  }

                  return (
                    <SnippetsItem key={item?.id}>
                      <ItemIcon icon={SiSublimetext} />
                      <ItemLink>{item?.rowId}</ItemLink>
                      <ItemText>{item?.amount}</ItemText>
                      <ItemText>{item?.amountRub}</ItemText>
                      <ItemText>{getDateTimeReadFormat(item?.processed)}</ItemText>
                      <ItemText>{getDateTimeReadFormat(item?.created)}</ItemText>
                      <ItemText>{status[String(item?.transferStatus)]}</ItemText>
                      <HStack spacing="10px">
                        <ActionButton
                          colorScheme="green"
                          isDisabled={item?.transferStatus === -1 || item?.transferStatus === 1}
                          onClick={() => handleProcessClick(item?.id)}
                        >
                          Зачислить
                        </ActionButton>
                        <ActionButton
                          isDisabled={item?.transferStatus === -1 || item?.transferStatus === 1}
                          onClick={() => handleRefundClick(item?.id)}
                        >
                          Отклонить
                        </ActionButton>
                      </HStack>
                    </SnippetsItem>
                  )
                }}
              </SnippetsList>
            </Snippets>
          </TabPanel>
          <TabPanel px={0}>
            <Snippets columns="42px minmax(400px, 1fr) 190px 220px 220px 220px" itemWidth="1600px">
              <SnippetsHeading>
                <HeadingItem />
                <HeadingItem>Событие</HeadingItem>
                <HeadingItem>Роль</HeadingItem>
                <HeadingItem>Бонусы</HeadingItem>
                <HeadingItem>Дата</HeadingItem>
                <HeadingItem>Статус</HeadingItem>
              </SnippetsHeading>
              <SnippetsList
                activePage={0}
                totalPages={0}
                data={dataEvents}
                scrollableTarget="scrollableCardContainer"
              >
                {(item, index) => (
                  <SnippetsItem item={index}>
                    <ItemIcon icon={SiSublimetext} />
                    <ItemText>{item?.description}</ItemText>
                    <ItemText>{role[item?.role]}</ItemText>
                    <ItemText>{`${item?.reward}`}</ItemText>
                    <ItemText>{getDateTimeReadFormat(item?.processed)}</ItemText>
                    <ItemText>{item?.isProcessed === 1 ? 'Обработан' : 'В процессе'}</ItemText>
                  </SnippetsItem>
                )}
              </SnippetsList>
            </Snippets>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <InvitedUsersModal
        isOpen={isOpenInvitedUsersModal}
        onClose={() => setOpenInvitedUsersModal.off()}
        originCode={dataCustomer?.originCode}
      />
    </>
  )
}
