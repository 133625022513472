import React from 'react'
import { Button } from '@chakra-ui/react'
import {
  Container,
  FilterHeader,
  SnippetsContainer,
  SnippetsContainerMain,
  SnippetsContainerRightAside,
} from 'chakra-lib/layout'
import { Snippets, SnippetsHeading, SnippetsItem, SnippetsList } from 'chakra-lib'
import { HeadingItem, ItemIcon, ItemLink, ItemText } from 'chakra-lib/Snippets'
import { IoPerson } from 'react-icons/io5'
import { getDateTimeReadFormat } from 'utils/dateFormatting'
import useAccessListFetch from './services/useAccessListFetch'
import { useHistory } from 'react-router-dom'

export const AccessList = () => {
  const history = useHistory()
  const { data, isLoading } = useAccessListFetch()

  return (
    <Container>
      <SnippetsContainer indentTop={70}>
        <SnippetsContainerMain>
          <Snippets columns="42px minmax(200px, 1fr) 220px">
            <SnippetsHeading>
              <HeadingItem />
              <HeadingItem>Имя пользователя</HeadingItem>
              <HeadingItem>Создано</HeadingItem>
            </SnippetsHeading>
            <SnippetsList activePage={0} totalPages={0} data={data} isLoading={isLoading}>
              {(item) => (
                <SnippetsItem to={`/access/edit/${item.id}`}>
                  <ItemIcon icon={IoPerson} />
                  <ItemLink>{item?.username}</ItemLink>
                  <ItemText>{getDateTimeReadFormat(item?.createdTimestamp)}</ItemText>
                </SnippetsItem>
              )}
            </SnippetsList>
          </Snippets>
        </SnippetsContainerMain>
        <SnippetsContainerRightAside>
          <FilterHeader>
            <Button width="100%" onClick={() => history.push(`/access/new`)} colorScheme="orange">
              Создать
            </Button>
          </FilterHeader>
        </SnippetsContainerRightAside>
      </SnippetsContainer>
    </Container>
  )
}
