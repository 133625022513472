import { api } from 'api'
import { useQuery } from 'react-query'
import toast from 'utils/toast'

export const useFetchUserBalance = (userId) => {
  const query = useQuery(['fetchUserBalance', userId], () => api.users.fetchUserBalance(userId), {
    onError: (err) => {
      toast.showError({
        title: 'Произошла ошибка при загрузке баланса',
        description: err.response?.data?.message || err.message,
      })
    },
    enabled: false,
    cacheTime: 0,
  })

  return [query.refetch, query]
}
