import { Box, Flex, Icon, useColorModeValue } from '@chakra-ui/react'

export const ItemIcon = ({ icon, badge, color = 'gray.400', boxSize = '1rem', ...props }) => {
  const themeBgContainer = useColorModeValue('gray.100', 'gray.700')
  const themeBgContainerHover = useColorModeValue('gray.200', 'gray.600')
  return (
    <Flex
      align="center"
      justify="center"
      zIndex={2}
      transition="0.3s"
      position="relative"
      _groupHover={
        {
          //backgroundColor: themeBgHover
        }
      }
      {...props}
    >
      <Box
        width="36px"
        height="36px"
        backgroundColor={themeBgContainer}
        borderRadius="50%"
        transition="0.3s"
        display="flex"
        alignItems="center"
        justifyContent="center"
        _groupHover={{
          backgroundColor: themeBgContainerHover,
        }}
      >
        <Icon as={icon} color={color} boxSize={boxSize} />
        {badge && (
          <Box
            borderRadius="50%"
            border="2px solid white"
            fontSize="10px"
            fontWeight="bold"
            right="-3px"
            bottom="-7px"
            position="absolute"
            boxSize="18px"
          >
            {badge}
          </Box>
        )}
      </Box>
    </Flex>
  )
}
