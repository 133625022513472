import { api } from 'api'
import toast from 'utils/toast'
import { useInfiniteQuery } from 'react-query'

export const useFetchJournalList = ({ objectId }) => {
  let list = []
  let lastPage

  const query = useInfiniteQuery(
    ['passportJournal'],
    ({ pageParam = 0 }) => {
      return api.journal.fetchList({ objectId, page: pageParam, size: 20 })
    },
    {
      getNextPageParam: ({ number }) => number + 1,
      keepPreviousData: true,
      onError: (err) => {
        toast.showError({
          title: 'Произошла ошибка при загрузки истории смены данных',
          description: err.message,
        })
      },
    }
  )

  if (query.data?.pages) {
    const content = query.data.pages.reduce((acc, item) => [...acc, ...item.content], [])
    list = content?.length ? historyListAdapter(content) : []
    lastPage = query.data.pages[query.data.pages.length - 1]
  }

  return {
    list,
    hasMore: !lastPage?.last,
    ...query,
  }
}

const historyListAdapter = (content) => {
  return content.reduce((acc, current) => {
    const { fields, ...rest } = current

    const newFields = fields.map((item, index) => ({
      ...item,
      ...rest,
      id: `${rest.id}-${item.columnName}-${index}`,
    }))

    acc.push(...newFields)
    return acc
  }, [])
}