import React from 'react'
import { Box, Grid } from '@chakra-ui/react'
import { useWindowHeight } from '../../useWindowHeight'
import { BackButton } from './BackButton'

export const CardContainer = ({
  isHideBack,
  indentTop = 0,
  columns = '70px 1fr',
  onBack,
  children,
}) => {
  const windowHeight = useWindowHeight()
  return (
    <Grid templateColumns={columns} flex={1}>
      {!isHideBack ? (
        <BackButton onClick={onBack} height={`${windowHeight - indentTop}px`} />
      ) : (
        <Box />
      )}
      <Box
        height={`${windowHeight - indentTop}px`}
        overflow="auto"
        pr="26px"
        pl="26px"
        //pb="80px"
        pt="30px"
        id="scrollableCardContainer"
      >
        {children}
      </Box>
    </Grid>
  )
}
