import React from 'react'
import { Flex, Spinner as SpinnerChakra } from '@chakra-ui/react'

export const Spinner = ({ color = 'blue.500' }) => {
  return (
    <Flex height="150px" align="center" justify="center">
      <SpinnerChakra color={color} />
    </Flex>
  )
}
