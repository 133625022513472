import { api } from 'api'
import { useMutation, useQueryClient } from 'react-query'
import toast from 'utils/toast'

export const useFaqUpdate = (id, options) => {
  const queryClient = useQueryClient()

  return useMutation(
    (payload) => {
      return api.faq.update({ ...payload, id: Number(id) })
    },
    {
      onSuccess: () => {
        if (options?.onSuccess) {
          options?.onSuccess()
        }
        queryClient.invalidateQueries(['faqList'])

        toast.showSuccess({
          title: 'Элемент обновлен успешно',
        })
      },
      onError: (err) => {
        toast.showError({
          title: 'Произошла ошибка при попытке обновить элемент',
          description: err.response?.data?.message || err.message,
        })
      },
    }
  )
}
