export const styleType = {
  UNDEFINED: 'UNDEFINED',
  INFORMATION: 'INFORMATION',
  OFFERTA: 'OFFERTA',
}

export const styleTypeTitle = {
  [styleType.UNDEFINED]: 'UNDEFINED',
  [styleType.INFORMATION]: 'Информация',
  [styleType.OFFERTA]: 'Оферта',
}

export const styleTypeOptions = Object.values(styleType).map((item) => ({
  label: styleTypeTitle[item],
  value: item,
}))