import { Button, Flex, useToast } from '@chakra-ui/react'
import { CheckboxForm, InputForm, SelectForm, TextAreaForm } from 'chakra-lib'
import { Form, FormHeading, FormSection } from 'chakra-lib/layout'
import { RadioButton } from 'components/RadioButton'
import { DOCUMENT_LOCALIZATION } from 'config/document.config'
import { Field, FieldArray, Formik } from 'formik'
import { isEmpty } from 'lodash'
import { TYPE_LOCALIZATION_MODEL } from 'model/localization.model'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
  createDocument,
  getDocumentItem,
  resetDocumentItem,
  updateDocument,
} from 'store/document.reducer'
import * as Yup from 'yup'
import { styleTypeOptions } from '../../../Document.constants'

const formSchema = Yup.object().shape({
  order: Yup.string().required('Required'),
})

export const DocumentForm = () => {
  const { id } = useParams()
  const toast = useToast()
  const isNew = !id
  const history = useHistory()
  const dispatch = useDispatch()
  const [activeIndex, setActiveIndex] = useState(0)
  const item = useSelector((state) => state.document.item)
  const loading = useSelector((state) => state.document.loading)
  const handleBack = () => {
    history.goBack()
  }

  useEffect(() => {
    if (isNew) {
      dispatch(resetDocumentItem())
    } else {
      dispatch(getDocumentItem(id))
    }
    return () => {
      dispatch(resetDocumentItem())
    }
  }, [])

  const getInitialForm = () => {
    let documentCollection = []
    DOCUMENT_LOCALIZATION.forEach((lang) => {
      const nameItem = item?.names?.find((n) => n.localization === lang)
      const bodyItem = item?.bodies?.find((n) => n.localization === lang)
      documentCollection.push({
        name: !isEmpty(nameItem) ? nameItem.text : '',
        body: !isEmpty(bodyItem) ? bodyItem.text : '',
        idName: !isEmpty(nameItem) ? nameItem.id : 0,
        idBody: !isEmpty(bodyItem) ? bodyItem.id : 0,
        localization: lang,
      })
    })
    return documentCollection
  }
  const getSubmitForm = (list = []) => {
    let names = []
    let bodies = []
    list.forEach((itemDoc) => {
      names.push({
        localization: itemDoc.localization,
        text: itemDoc.name,
        id: itemDoc.idName === 0 ? undefined : itemDoc.idName,
      })
      bodies.push({
        localization: itemDoc.localization,
        text: itemDoc.body,
        id: itemDoc.idBody === 0 ? undefined : itemDoc.idBody,
      })
    })
    return {
      names,
      bodies,
    }
  }
  const handleForm = async (values) => {
    try {
      const dataRequest = {
        ...item,
        needUpdate: values.needUpdate,
        publicAccess: values.publicAccess,
        order: Number(values.order),
        style: values.style !== '' ? values.style : undefined,
        ...getSubmitForm(values.list),
      }
      if (isNew) {
        await dispatch(createDocument(dataRequest))
        toast({
          description: `Документ успешно создан`,
          variant: 'solid',
          status: 'success',
        })
        handleBack()
      } else {
        await dispatch(updateDocument(dataRequest))
        toast({
          description: `Документ успешно обновлен`,
          variant: 'solid',
          status: 'success',
        })
        handleBack()
      }
    } catch (err) {
      if (err.response.status !== 400 && err.response.status !== 401) {
        toast({
          description: `Ошибка загрузки. Код ${err.response.status}`,
          variant: 'solid',
          status: 'error',
        })
      }
    }
  }

  const onlyView = item.removed

  return (
    <Formik
      initialValues={{
        list: getInitialForm(),
        needUpdate: Boolean(item.needUpdate),
        publicAccess: Boolean(item.publicAccess),
        order: item.order !== undefined ? String(item.order) : '0',
        style: item?.style ? item?.style : '',
      }}
      enableReinitialize
      onSubmit={handleForm}
      validationSchema={formSchema}
    >
      {(props) => (
        <Form
          onSubmit={props.handleSubmit}
          footer={
            !onlyView && (
              <Button
                type="submit"
                colorScheme="orange"
                isDisabled={!props.dirty}
                isLoading={loading}
              >
                {isNew ? 'Создать' : 'Обновить'}
              </Button>
            )
          }
        >
          <FormSection>
            <Field
              name="style"
              component={SelectForm}
              label="Тип"
              placeholder="Выберите тип"
              options={styleTypeOptions}
              isDisabled={loading || onlyView}
            />

            <Flex mt="30px">
              <FieldArray
                name="list"
                render={() => (
                  <>
                    {props.values.list &&
                      props.values.list.length > 0 &&
                      props.values.list.map((friend, index) => (
                        <React.Fragment key={index}>
                          <RadioButton
                            isActive={activeIndex === index}
                            onClick={() => setActiveIndex(index)}
                            mr={2}
                          >
                            {TYPE_LOCALIZATION_MODEL[friend.localization] || friend.localization}
                          </RadioButton>
                        </React.Fragment>
                      ))}
                  </>
                )}
              />
            </Flex>

            <Field
              name={`list[${activeIndex}].name`}
              component={InputForm}
              label="Название"
              isDisabled={loading || onlyView}
            />
            <Field
              name={`list[${activeIndex}].body`}
              component={TextAreaForm}
              minHeight="400px"
              label="Содержимое"
              isDisabled={loading || onlyView}
            />
          </FormSection>

          <FormSection>
            <FormHeading>Дополнительно</FormHeading>
            <Field
              type="number"
              name="order"
              component={InputForm}
              label="Сортировка"
              isDisabled={loading || onlyView}
            />
            <Field
              name="needUpdate"
              label="Поднять версию"
              component={CheckboxForm}
              isDisabled={loading || onlyView}
            />
            <Field
              name="publicAccess"
              label="Неавторизованная зона"
              component={CheckboxForm}
              isDisabled={loading || onlyView}
            />
          </FormSection>

        </Form>
      )}
    </Formik>
  )
}
