import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { UserList } from './UserList'
import { Login } from '../Login'
import { useSelector } from 'react-redux'
import { UserCard } from './UserCard'

const Routes = () => {
  const match = useRouteMatch()
  const isAuthenticated = useSelector((state) => state.authentication.isAuthenticated)
  return isAuthenticated ? (
    <>
      <Switch>
        <Route path={`${match.url}/card/:paramTabIndex/:id`} exact>
          <UserCard />
        </Route>
        <Route path={`${match.url}/:params?`}>
          <UserList />
        </Route>
      </Switch>
    </>
  ) : (
    <Login />
  )
}

export default Routes
