import { SectionMenu, SectionMenuItem } from 'chakra-lib/layout'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import Banners from './Banners'
import { BlackList } from './BlackList'
import { DeactivationModules } from './DeactivationModules'
import { Faq } from './Faq'
import { Settings } from './Settings'

const Routes = () => {
  const match = useRouteMatch()

  return (
    <>
      <SectionMenu>
        <SectionMenuItem colorScheme="teal" to="/services/banners">
          Баннеры
        </SectionMenuItem>
        <SectionMenuItem colorScheme="teal" to="/services/settings">
          Настройки
        </SectionMenuItem>
        <SectionMenuItem colorScheme="teal" to="/services/deactivation-modules">
          Деактивация модулей
        </SectionMenuItem>
        <SectionMenuItem colorScheme="teal" to="/services/black-list">
          Черный список
        </SectionMenuItem>
        <SectionMenuItem colorScheme="teal" to="/services/faq">
          FAQ
        </SectionMenuItem>
      </SectionMenu>
      <Switch>
        <Route path={`${match.url}/banners`}>
          <Banners />
        </Route>
        <Route path={`${match.url}/settings`}>
          <Settings />
        </Route>
        <Route path={`${match.url}/deactivation-modules`}>
          <DeactivationModules />
        </Route>
        <Route path={`${match.url}/black-list`}>
          <BlackList />
        </Route>
        <Route path={`${match.url}/faq`}>
          <Faq />
        </Route>
      </Switch>
    </>
  )
}

export default Routes
