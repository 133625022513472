import React from 'react'
import { Select } from '../Select'

export const SelectForm = ({ field, form, options, isMulti = false, ...props }) => {
  const { name, value, touched } = field

  const handleChange = (option) => {
    if (option) {
      form.setFieldValue(name, isMulti ? option.map((item) => item.value) : option.value)
    } else {
      form.setFieldValue(name, '')
    }
  }

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option) => value.indexOf(option.value) >= 0)
        : options.find((option) => option.value === value)
    } else {
      return isMulti ? [] : ''
    }
  }

  const onBlur = () => {
    if (!touched) {
      form.setTouched({ ...form.touched, [name]: true })
    }
  }

  return (
    <Select
      isForm
      name={name}
      value={getValue()}
      onChange={handleChange}
      onBlur={onBlur}
      options={options}
      isMulti={isMulti}
      isInvalid={form.errors[name] && form.touched[name]}
      {...props}
    />
  )
}

