import axios from 'axios'
import { API_URL, SERVER } from 'config/api'

const ENTITY = 'users'

export const users = Object.freeze({
  async fetchUserList(data) {
    const response = await axios.post(`${SERVER}${API_URL}/${ENTITY}/search`, data)
    return response.data
  },
  async setUserEnable(id, data) {
    const response = await axios.post(`${SERVER}${API_URL}/${ENTITY}/${id}/enable`, data)
    return response.data
  },
  async setUserDisable(id, data) {
    const response = await axios.post(`${SERVER}${API_URL}/${ENTITY}/${id}/disable`, data)
    return response.data
  },
  async removeUser(id, data) {
    const response = await axios.post(`${SERVER}${API_URL}/${ENTITY}/${id}/deleteUser`, data)
    return response.data
  },
  async updateUser(id, data, params) {
    const response = await axios.put(`${SERVER}${API_URL}/${ENTITY}/${id}`, data, {
      params,
    })
    return response.data
  },
  async syncUser(id) {
    const response = await axios.get(`${SERVER}${API_URL}/${ENTITY}/sync/${id}`)
    return response.data
  },
  async updateUserInternal(id, data) {
    const response = await axios.put(`${SERVER}${API_URL}/${ENTITY}/edit/${id}`, data)
    return response.data
  },
  async fetchUserBalance(id, data) {
    const response = await axios.post(`${SERVER}${API_URL}/${ENTITY}/${id}/balance`, data)
    return response.data
  },
  async setUserTrusted(id, data) {
    const response = await axios.post(`${SERVER}${API_URL}/${ENTITY}/${id}/trusted`, data)
    return response.data
  },
  async setUserUntrusted(id, data) {
    const response = await axios.post(`${SERVER}${API_URL}/${ENTITY}/${id}/untrusted`, data)
    return response.data
  },
  async clearTransactionsCache(id) {
    const response = await axios.get(`${SERVER}${API_URL}/${ENTITY}/clearcache/${id}`)
    return response.data
  },
})
