import React from 'react'
import { Grid } from '@chakra-ui/react'

export const FormSection = ({ children, ...props }) => {
  return (
    <Grid gridRowGap={4} mb={6} {...props}>
      {children}
    </Grid>
  )
}
