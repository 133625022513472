import React, { useEffect } from 'react'
import { DeleteIcon } from '@chakra-ui/icons'
import { Button, IconButton, useDisclosure } from '@chakra-ui/react'
import { AlertDialog } from 'chakra-lib'
import useDocumentDelete from './useDocumentDelete'
import { useHistory } from 'react-router-dom'

export const Delete = ({ id }) => {
  const history = useHistory()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { deleteItem, isLoadingDeleteItem, isSuccessDeleteItem } = useDocumentDelete()

  const handleDelete = async () => {
    await deleteItem(id)
  }

  useEffect(() => {
    if (isSuccessDeleteItem) {
      history.goBack()
    }
  }, [isSuccessDeleteItem])

  return (
    <>
      <IconButton aria-label="Delete" borderRadius="full" icon={<DeleteIcon />} onClick={onOpen} />
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        title="Удаление документа"
        footer={
          <>
            <Button onClick={onClose}>Отмена</Button>
            <Button isLoading={isLoadingDeleteItem} colorScheme="red" ml={3} onClick={handleDelete}>
              Удалить
            </Button>
          </>
        }
      >
        Вы действительно хотите удалить документ?
      </AlertDialog>
    </>
  )
}
