import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import useQuery from 'components/useQuery'
import { api } from 'api'

const useHistoryListFetch = (id) => {
  const toast = useToast()
  const { isError, isLoading, response, request } = useQuery(() => api.payments.fetchHistory(id))

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось получить историю',
        status: 'error',
      })
    }
  }, [isError])

  return {
    list: response?.history,
    isLoading,
    getHistory: request,
  }
}

export default useHistoryListFetch
