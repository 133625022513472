import { Button } from '@chakra-ui/react'
import { useSetTrustedUser } from './services/useSetTrustedUser'
import { useSetUntrustedUser } from './services/useSetUntrustedUser'

export const TrustUser = ({ userId, trusted, isDisabled }) => {
  const { mutate: setTrusted, isLoading: trustedIsLoading } = useSetTrustedUser(userId)
  const { mutate: setUntrusted, isLoading: untrustedIsLoading } = useSetUntrustedUser(userId)

  const handleSubmit = () => {
    if (trusted) {
      setUntrusted()
    } else {
      setTrusted()
    }
  }

  const isLoading = trustedIsLoading || untrustedIsLoading

  return (
    <Button
      width="100%"
      onClick={handleSubmit}
      colorScheme={!trusted ? 'green' : 'orange'}
      isLoading={isLoading}
      isDisabled={isDisabled}
    >
      {!trusted ? 'Добавить в белый список' : 'Убрать из белого списка'}
    </Button>
  )
}
