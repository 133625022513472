import { useEffect } from 'react'
import useQuery from 'components/useQuery'
import { api } from 'api'
import { exportExcelFile } from 'utils/exportExcelFile'
import { columns } from './columns'
import { useToast } from '@chakra-ui/react'
import {
  getDateReadFormat,
  getDateTimeReadFormat,
  getDateTimeToSubmit,
} from 'utils/dateFormatting'
import { userIdentificationLabel } from 'model/user.model'
import { useSelector } from 'react-redux'
import { checkParam } from 'utils/checkParam'

export const useFetchData = (size) => {
  const toast = useToast()
  const filter = useSelector((state) => state.user.filter)

  const { isError, isLoading, isSuccess, response, request } = useQuery((e) =>
    api.users.fetchUserList(e)
  )

  const getData = async () => {
    const dataRequest = {
      size,
      page: 0,
      lastName: checkParam(filter.lastName),
      firstName: checkParam(filter.firstName),
      middleName: checkParam(filter.middleName),
      phone: checkParam(filter.phone),
      clientId: checkParam(filter.clientId),
      passportNumber: checkParam(filter.passportNumber),
      applicationVersion: checkParam(filter.applicationVersion),
      platform: checkParam(filter.platform),
      identification: checkParam(filter.identification),
      identificationDateFrom: checkParam(getDateTimeToSubmit(filter.identificationDateFrom)),
      identificationDateTo: checkParam(getDateTimeToSubmit(filter.identificationDateTo)),
      registrationDateFrom: checkParam(getDateTimeToSubmit(filter.registrationDateFrom)),
      registrationDateTo: checkParam(getDateTimeToSubmit(filter.registrationDateTo)),
    }
    await request(dataRequest)
  }

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось получить данные',
        status: 'error',
      })
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      const _data = response?.users?.map((i) => ({
        ...i,
        createDate: getDateTimeReadFormat(i.createDate),
        identificationDate: getDateTimeReadFormat(i.identificationDate),
        birthDate: getDateReadFormat(i.birthDate),
        identification: userIdentificationLabel[i.identification] || i.identification,
      }))
      exportExcelFile(_data, {
        sheetName: 'Users',
        columns,
      })
    }
  }, [isLoading])

  return {
    data: response,
    isLoading,
    getData,
  }
}
