import { api } from 'api'
import { useMutation, useQueryClient } from 'react-query'
import toast from 'utils/toast'

export const useClearChache = (userId, { onSuccess }) => {
  const queryClient = useQueryClient()

  return useMutation(
    ['clearTransactionsCache', userId],
    () => api.users.clearTransactionsCache(userId),
    {
      onSuccess: () => {
        onSuccess && onSuccess()
        
        toast.showSuccess({
          title: 'Лимит успешно сброшен',
        })

        queryClient.invalidateQueries(['fetchUserBalance'])
      },
      onError: (err) => {
        toast.showError({
          title: 'Произошла ошибка при сбросе лимита',
          description: err.response?.data?.message || err.message,
        })
      },
    }
  )
}
