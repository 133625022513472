import React from 'react'
import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn,
  HeadingContainer,
} from 'chakra-lib/layout'
import { Heading } from 'chakra-lib'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Box, Text } from '@chakra-ui/react'
import { Delete, DocumentForm } from './elements'
import {styleType} from "../Document.constants";

export const DocumentUpdate = () => {
  const { id } = useParams()
  const isNew = !id
  const history = useHistory()
  const item = useSelector((state) => state.document.item)
  const handleBack = () => {
    history.goBack()
  }

  return (
    <Container>
      <CardContainer onBack={handleBack} indentTop={70}>
        <ContentContainer>
          <ContentContainerColumn>
            <HeadingContainer justifyContent="flex-start" alignItems="baseline">
              <Heading mr="10px">{isNew ? 'Создание документа' : 'Обновление документа'}</Heading>
              <Text color="orange.400">Версия: {item.version || ' - '}</Text>
              {!isNew && !item.removed && item.style !== styleType.OFFERTA && (
                <Box ml="auto">
                  <Delete id={id} />
                </Box>
              )}
            </HeadingContainer>

            <DocumentForm />
          </ContentContainerColumn>
        </ContentContainer>
      </CardContainer>
    </Container>
  )
}
