import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import useQuery from 'components/useQuery'
import { api } from 'api'

const useRolesListFetch = () => {
  const toast = useToast()
  const { isError, isLoading, response, request } = useQuery(() => api.keycloak.fetchRolesList())

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось получить данные',
        status: 'error',
      })
    }
  }, [isError])

  useEffect(() => {
    request()
  }, [])

  return {
    rolesList: response?.data,
    isLoadingRolesList: isLoading,
  }
}

export default useRolesListFetch
