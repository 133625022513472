import React from 'react'
import {
  Box,
  Button,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import useSettingsFetch from './services/useSettingsFetch'
import { Form } from 'chakra-lib'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { InputForm } from 'chakra-lib'
import useSettingsUpdate from './services/useSettingsUpdate'

const formSchema = Yup.object({
  paymentsInterval: Yup.number()
    .integer('invalid decimal')
    .min(0, 'Min')
    .required('Заполните поле'),
})

export const PaymentsIntervalSettings = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { getSettings, isLoadingFetch, data } = useSettingsFetch()
  const { updateSettings, isLoadingUpdate } = useSettingsUpdate()

  const handleOpen = async () => {
    onOpen()
    await getSettings()
  }

  const handleForm = async (values) => {
    const dataRequest = {
      paymentsInterval: Number(values.paymentsInterval),
    }
    await updateSettings({ id: data?.id, data: dataRequest })
    onClose()
  }

  return (
    <>
      <MenuItem onClick={handleOpen}>Интервал между переводами</MenuItem>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Интервал между переводами</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              enableReinitialize
              validationSchema={formSchema}
              initialValues={{
                paymentsInterval: data?.paymentsInterval ? String(data?.paymentsInterval) : '0',
              }}
              onSubmit={handleForm}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <Stack spacing={4}>
                    <Field
                      name="paymentsInterval"
                      label="Время в секундах"
                      component={InputForm}
                      placeholder="0"
                    />
                  </Stack>
                  <Box pt="30px" pb="20px">
                    <Button
                      type="submit"
                      colorScheme="orange"
                      isLoading={isLoadingUpdate || isLoadingFetch}
                    >
                      Сохранить
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
