import { Box, Button, useBoolean } from '@chakra-ui/react'
import { Form, Modal, TextAreaForm } from 'chakra-lib'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { useRemoveUser } from './services/useRemoveUser'

const validationSchema = Yup.object({
  userComment: Yup.string().required('Обязательное поле'),
})

export const DisactivateUser = ({ userId }) => {
  const { mutate, isLoading } = useRemoveUser(userId)
  const [isOpen, setOpenModal] = useBoolean()

  const onSubmitHandle = (values) => {
    mutate(values)
  }

  return (
    <>
      <Button width="100%" onClick={setOpenModal.on} colorScheme="orange" isLoading={isLoading}>
        Деактивировать
      </Button>
      <Modal isOpen={isOpen} onClose={setOpenModal.off} title="Деактивировать пользователя">
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{}}
          validationSchema={validationSchema}
          onSubmit={onSubmitHandle}
        >
          {({ handleSubmit, isValid }) => (
            <Form w="full">
              <Field
                isDisabled={isLoading}
                name="userComment"
                placeholder="Введите комментарий"
                component={TextAreaForm}
              />
              <Box pt="30px">
                <Button
                  colorScheme="orange"
                  isLoading={isLoading}
                  onClick={handleSubmit}
                  isDisabled={!isValid}
                >
                  Деактивировать
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}
