import { api } from 'api'
import toast from 'utils/toast'
import { useMutation } from 'react-query'

export const useUpdateRewards = ({ onSuccess }) => {
  return useMutation((data) => api.referral.rewardDistribution(data), {
    onError: (err) => {
      toast.showError({
        title: 'Произошла ошибка при начислении бонусов',
        description: err.response?.data?.message || err.message,
      })
    },
    onSuccess: (data) => {
      if (onSuccess) {
        onSuccess(data)
      }
      
      toast.showSuccess({
        title: 'Данные успешно внесены',
      })
    },
  })
}
