import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { Login } from '../Login'
import { useSelector } from 'react-redux'
import { DocumentUpdate } from './DocumentUpdate'
import { DocumentList } from './DocumentList'

const Routes = () => {
  const match = useRouteMatch()
  const isAuthenticated = useSelector((state) => state.authentication.isAuthenticated)
  return isAuthenticated ? (
    <>
      <Switch>
        <Route path={`${match.url}/new`} exact>
          <DocumentUpdate />
        </Route>
        <Route path={`${match.url}/edit/:id`} exact>
          <DocumentUpdate />
        </Route>
        <Route path={`${match.url}/:params?`}>
          <DocumentList />
        </Route>
      </Switch>
    </>
  ) : (
    <Login />
  )
}

export default Routes
