import { Box, Button, Text, VStack, useBoolean } from '@chakra-ui/react'
import { Form, Modal, TextAreaForm } from 'chakra-lib'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { useRemoveUser } from './services/useRemoveUser'

const validationSchema = Yup.object({
  userComment: Yup.string().required('Обязательное поле'),
})

export const RemoveUserModal = ({ userId }) => {
  const [isOpen, setOpenModal] = useBoolean()

  const { mutate, isLoading } = useRemoveUser(userId, {
    onSuccess: () => {
      setOpenModal.off()
    },
  })

  const onSubmitHandle = (values) => {
    mutate(values)
  }

  return (
    <>
      <Button width="100%" onClick={setOpenModal.on} colorScheme="red" isLoading={isLoading}>
        Заблокировать
      </Button>
      <Modal isOpen={isOpen} onClose={setOpenModal.off} title="Заблокировать пользователя">
        <VStack alignItems="flex-start" spacing={4}>
          <Text fontSize="20px" color="red.500">
            Внимание!
          </Text>
          <Text>
            Данное действие нельзя будет отменить. Действие равносильно удалению аккаунта. При
            блокировке к телефону и email добавляется префикс _blocked.
          </Text>
          <Formik
            validateOnMount
            enableReinitialize
            initialValues={{}}
            validationSchema={validationSchema}
            onSubmit={onSubmitHandle}
          >
            {({ handleSubmit, isValid }) => (
              <Form w="full">
                <Field
                  isDisabled={isLoading}
                  name="userComment"
                  placeholder="Введите комментарий"
                  component={TextAreaForm}
                />
                <Box pt="30px">
                  <Button
                    colorScheme="red"
                    isLoading={isLoading}
                    onClick={handleSubmit}
                    isDisabled={!isValid}
                  >
                    Заблокировать
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </VStack>
      </Modal>
    </>
  )
}
