import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import useQuery from 'components/useQuery'
import { api } from 'api'

const useFetchEventsCodeOptions = () => {
  const toast = useToast()
  const { isError, isLoading, response, request } = useQuery(() => api.referral.fetchCatalog())

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось получить данные',
        status: 'error',
      })
    }
  }, [isError])

  useEffect(() => {
    request()
  }, [])

  return {
    optionsCode: response?.map((e) => e.eventCode),
    isLoadingOptions: isLoading,
  }
}

export default useFetchEventsCodeOptions
