import { Box, Text } from '@chakra-ui/react'
import { Alert, Tab, TabList, TabPanel, TabPanels, Tabs } from 'chakra-lib'
import { CardContainer, Container } from 'chakra-lib/layout'
import { ImageSnippets } from 'components/ImageSnippets/ImageSnippets'
import { getAccess } from 'components/Private/Private'
import isEmpty from 'lodash/isEmpty'
import { ROLES } from 'model/user.model'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { resetPaymentsList } from 'store/payments.reducer'
import { getUserItem, resetIncomingTransfersList, resetUserItem } from 'store/user.reducer'
import { IdentificationsList } from './IdentificationsList/IdentificationsList'
import { IncomingTransfers } from './IncomingTransfers/IncomingTransfers'
import { Payments } from './Payments'
import { Referral } from './Referral'
import { UploadImageModal } from './UploadImageModal/UploadImageModal'
import { User } from './User'
import { UserEdit } from './UserEdit'

export const UserCard = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()
  const { paramTabIndex } = useParams()
  const user = useSelector((state) => state.authentication.user)
  const isAdmin = getAccess(user.roles, [ROLES.admin])
  const item = useSelector((state) => state.user.item)
  const error = useSelector((state) => state.user.error)
  const loading = useSelector((state) => state.user.loading)

  const [tabIndex, setTabIndex] = useState(Number(paramTabIndex) || 0)
  const isReadyItem = !isEmpty(item)
  const handleBack = () => {
    history.goBack()
  }

  useEffect(() => {
    dispatch(getUserItem(id))
    return () => {
      dispatch(resetUserItem())
      dispatch(resetPaymentsList())
      dispatch(resetIncomingTransfersList())
    }
  }, [])

  useEffect(() => {
    setTabIndex(Number(paramTabIndex))
  }, [paramTabIndex])

  const handleTabsChange = (index) => {
    history.replace(`/user/card/${index}/${id}`)
  }

  return (
    <Container>
      <CardContainer columns="86px 1fr" indentTop={70} onBack={handleBack}>
        {error && (
          <Alert mb="20px" isDisableClose>
            Ошибка загрузки данных
          </Alert>
        )}
        <Tabs
          variant="enclosed-colored"
          colorScheme="orange"
          onChange={handleTabsChange}
          index={tabIndex}
        >
          <TabList>
            <Tab>Данные пользователя</Tab>
            <Tab>Платежи</Tab>
            <Tab>Входящие переводы</Tab>
            <Tab>Документы</Tab>
            <Tab>Идентификация</Tab>
            <Tab>Бонусы</Tab>
            <Tab>Смена данных</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <User userId={id} data={item} isLoading={loading} />
            </TabPanel>
            <TabPanel>
              <Payments user={item} />
            </TabPanel>
            <TabPanel>{isReadyItem && <IncomingTransfers />}</TabPanel>
            <TabPanel>
              <Box mb="30px">
                <UploadImageModal userId={id} />
              </Box>
              <ImageSnippets data={item.identityDocumentScans} />
            </TabPanel>
            <TabPanel>{isReadyItem && <IdentificationsList userId={id} />}</TabPanel>
            <TabPanel>
              {isAdmin ? (
                isReadyItem && <Referral userId={id} phone={item?.phone} />
              ) : (
                <Text>Недоступно для пользователя</Text>
              )}
            </TabPanel>
            <TabPanel>
              <UserEdit userId={id} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </CardContainer>
    </Container>
  )
}
