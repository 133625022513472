import axios from 'axios'
import { API_URL, SERVER } from 'config/api'

const ENTITY = 'scans'

export const scans = Object.freeze({
  async uploadItem(data) {
    const response = await axios.post(`${SERVER}${API_URL}/${ENTITY}/`, data)
    return response.data
  },
})
