export const providersType = {
  smsc: 'smsc',
  paradox: 'paradox',
}

export const providersTypeTitle = {
  [providersType.smsc]: 'СМС-С',
  [providersType.paradox]: 'Paradox',
}

export const providersTypeOptions = Object.values(providersType).map((item) => ({
  title: providersTypeTitle[item],
  value: item,
}))

export const providersCountry = {
  armenianProvider: 'armenianProvider',
  russianProvider: 'russianProvider',
}

export const providersCountryTitle = {
  [providersCountry.armenianProvider]: 'РА',
  [providersCountry.russianProvider]: 'РФ',
}

export const providersCountryOptions = Object.values(providersCountry).map((item) => ({
  title: providersCountryTitle[item],
  value: item,
}))