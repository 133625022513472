import { api } from 'api'
import { useMutation } from 'react-query'
import toast from 'utils/toast'

export const useSaveProvidersSettings = () => {
  return useMutation(
    (payload) => {
      return api.config.saveSmsProviders(payload)
    },
    {
      onSuccess: () => {
        toast.showSuccess({
          title: 'Данные успешно обновлены',
        })
      },
      onError: (err) => {
        toast.showError({
          title: 'Произошла ошибка при сохранении конфигурации провайдеров',
          description: err.response?.data?.message || err.message,
        })
      },
    }
  )
}
