import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { HashRouter as Router } from 'react-router-dom'
import { StoreProvider } from 'components/StoreProvider'
import { ScrollToTop, Theme } from 'chakra-lib'
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <Theme>
      <StoreProvider>
        <Router>
          <QueryClientProvider client={queryClient}>
            <ScrollToTop/>
            <App/>
          </QueryClientProvider>
        </Router>
      </StoreProvider>
    </Theme>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
