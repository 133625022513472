import useQuery from 'components/useQuery'
import { api } from 'api'

const useFetchEvents = (id) => {
  const { isLoading, response, request } = useQuery(() => api.referral.fetchEvents(id))

  return {
    fetchEvents: request,
    dataEvents: response,
    isLoadingEvents: isLoading,
  }
}

export default useFetchEvents
