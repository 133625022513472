import { api } from 'api'
import useQuery from 'components/useQuery'

const useFetchReferralTransferCustomer = (id) => {
  const { response, request } = useQuery(() => api.referral.fetchTransferCustomer(id))

  return {
    fetchReferralTransferCustomer: request,
    dataTransferCustomer: response,
  }
}

export default useFetchReferralTransferCustomer
