import React from 'react'
import { Flex } from '@chakra-ui/react'

export const SectionMenu = ({ children, ...props }) => {
  return (
    <Flex pl="20px" pr="20px" minH="50px" alignItems="center" borderBottomWidth={1} {...props}>
      {children}
    </Flex>
  )
}
