import React, { useContext } from 'react'
import { SnippetsGridContext } from './SnippetsContainer'
import { Flex } from '@chakra-ui/react'

export const SnippetsContainerMain = ({ children, ...props }) => {
  const { windowHeight, indentTop } = useContext(SnippetsGridContext)
  return (
    <Flex
      overflow="hidden"
      flexDirection="column"
      height={`${windowHeight - indentTop}px`}
      borderRightWidth={1}
      {...props}
    >
      {children}
    </Flex>
  )
}
