import React from 'react'
import { Box, Flex } from '@chakra-ui/react'

export const GridFieldHeading = ({ action, children }) => {
  return (
    <Flex justify="space-between" align="center" marginBottom="14px">
      <Box fontSize="1.1rem" fontWeight="semibold">
        {children}
      </Box>
      {action}
    </Flex>
  )
}
