import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import { api } from 'api'
import useQuery from 'components/useQuery'

const useRoleMappingsAdd = (id) => {
  const toast = useToast()
  const { isError, isSuccess, isLoading, request } = useQuery((data) =>
    api.keycloak.createUserRoleMappings(id, data)
  )

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось добавить роль',
        status: 'error',
      })
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Готово',
        description: 'Роль успешно добавлена',
        status: 'success',
      })
    }
  }, [isSuccess])

  return {
    createUserRole: request,
    isLoadingCreateUser: isLoading,
    isSuccessCreateUser: isSuccess,
    isSuccess,
  }
}

export default useRoleMappingsAdd
