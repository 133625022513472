import axios from 'axios'
import { API_URL, SERVER } from 'config/api'

const ENTITY = 'journal'

export const journal = Object.freeze({
  async fetchList(params) {
    const response = await axios.get(`${SERVER}${API_URL}/${ENTITY}`, { params })
    return response.data
  },
})
