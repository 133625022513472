import { api } from 'api'
import { useMutation, useQueryClient } from 'react-query'
import toast from 'utils/toast'

export const useBlackListAdd = (options) => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ subject, description }) => {
      return api.blackList.add({
        subject,
        description,
        created: null,
        kind: 'TOTAL_CNT_BY_RECIPIENT_CARD',
      })
    },
    {
      onSuccess: () => {
        if (options?.onSuccess) {
          options?.onSuccess()
        }
        queryClient.invalidateQueries(['blackList'])

        toast.showSuccess({
          title: 'Добавлен элемент в черный список',
        })
      },
      onError: (err) => {
        toast.showError({
          title: 'Произошла ошибка при попытке добавить элемент',
          description: err.response?.data?.message || err.message,
        })
      },
    }
  )
}
