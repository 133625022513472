import { api } from 'api'
import toast from 'utils/toast'
import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { updateUserItem } from 'store/user.reducer'

export const useUpdateUserInternal = () => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  return useMutation(
    (payload) => {
      const { id, ...data } = payload

      return api.users.updateUserInternal(id, data)
    },
    {
      onSuccess: (data) => {
        dispatch(updateUserItem(data))
        queryClient.invalidateQueries(['passportJournal'])

        toast.showSuccess({
          title: 'Данные успешно обновлены',
        })
      },
      onError: (err) => {
        const data = err.response.data
        const message = data.message || err.message

        toast.showError({
          title: 'Произошла ошибка при обновлении данных пользователя',
          description: message,
        })
      },
    }
  )
}
