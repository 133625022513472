export const providersType = {
  common: 'common',
  mindbox: 'mindbox',
}

export const providersTypeTitle = {
  [providersType.common]: 'Внутренний оператор',
  [providersType.mindbox]: 'Mindbox',
}

export const providersTypeOptions = Object.values(providersType).map((item) => ({
  title: providersTypeTitle[item],
  value: item,
}))
