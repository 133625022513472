import React from 'react'
import { Snippets, SnippetsHeading, SnippetsItem, SnippetsList } from 'chakra-lib'
import { HeadingItem, ItemText } from 'chakra-lib/Snippets'
import { getDateTimeReadFormat } from 'utils/dateFormatting'
import { reportTypeTitle } from '../Reports.constants'

export const ReportsList = ({ data, isLoading, hasMore, onNext }) => {
  return (
    <Snippets columns="100px  150px 150px 150px 150px 150px minmax(150px, 1fr) minmax(200px, 1fr)">
      <SnippetsHeading>
        <HeadingItem>ID</HeadingItem>
        <HeadingItem>Тип реестра</HeadingItem>
        <HeadingItem>С</HeadingItem>
        <HeadingItem>По</HeadingItem>
        <HeadingItem>Старт выгрузки</HeadingItem>
        <HeadingItem>Окончание выгрузки</HeadingItem>
        <HeadingItem>Статус</HeadingItem>
        <HeadingItem>Получатели</HeadingItem>
      </SnippetsHeading>
      <SnippetsList data={data} hasMore={hasMore} isLoading={isLoading} onNext={onNext}>
        {(item) => (
          <SnippetsItem>
            <ItemText>{item?.id}</ItemText>
            <ItemText>{reportTypeTitle[item?.reestr_type] || item?.reestr_type}</ItemText>
            <ItemText>{getDateTimeReadFormat(item?.reestr_period_from)}</ItemText>
            <ItemText>{getDateTimeReadFormat(item?.reestr_period_to)}</ItemText>
            <ItemText>{getDateTimeReadFormat(item?.startDate)}</ItemText>
            <ItemText>{getDateTimeReadFormat(item?.endDate)}</ItemText>
            <ItemText>{item?.resstr_status}</ItemText>
            <ItemText>{item?.senders}</ItemText>
          </SnippetsItem>
        )}
      </SnippetsList>
    </Snippets>
  )
}
