import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { Login } from '../Login'
import { useSelector } from 'react-redux'
import { Payments } from './Payments'
import { PaymentsShow } from './PaymentsShow/PaymentsShow'

const Routes = () => {
  const match = useRouteMatch()
  const isAuthenticated = useSelector((state) => state.authentication.isAuthenticated)
  return isAuthenticated ? (
    <>
      <Switch>
        <Route path={`${match.url}/show/:paramTabIndex/:id`} exact>
          <PaymentsShow />
        </Route>
        <Route path={`${match.url}/:params?`}>
          <Payments />
        </Route>
      </Switch>
    </>
  ) : (
    <Login />
  )
}

export default Routes
