import { api } from 'api'
import toast from 'utils/toast'
import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { updateUserItem } from 'store/user.reducer'

export const useUpdateUser = ({ onSuccess, onError }) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  return useMutation(
    (payload) => {
      const { id, force, ...data } = payload

      return api.users.updateUser(id, data, { force })
    },
    {
      onSuccess: (data) => {
        if (onSuccess) {
          onSuccess(data)
        }

        dispatch(updateUserItem(data))
        queryClient.invalidateQueries(['passportJournal'])

        toast.showSuccess({
          title: 'Данные успешно обновлены',
        })
      },
      onError: (data) => {
        if (onError) {
          onError(data)
        }
      },
    }
  )
}
