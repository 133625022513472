import { FAILURE, REQUEST, SUCCESS } from './actions-type'
import axios from 'axios'
import { API_URL, SERVER } from 'config/api'
import JSONbig from 'json-bigint'
import { checkParam } from 'utils/checkParam'
import { getDateTimeToSubmit } from 'utils/dateFormatting'

const PAGE_SIZE = 20
const START_PAGE = 0

const JSONBigString = JSONbig({
  strict: false,
  storeAsString: true,
})

export const ACTION_TYPES = {
  FETCH_LIST: 'payments/FETCH_LIST',
  RESET_LIST: 'payments/RESET_LIST',
  UPDATE_FILTER: 'payments/UPDATE_FILTER',
  CLEAR_FILTER: 'payments/CLEAR_FILTER',
  RESET_FILTER: 'payments/RESET_FILTER',
  FETCH_ITEM: 'payments/FETCH_ITEM',
  CREATE_ITEM: 'payments/CREATE_ITEM',
  UPDATE_ITEM: 'payments/UPDATE_ITEM',
  RESET_ITEM: 'payments/RESET_ITEM',
  RESET_ERROR: 'payments/RESET_ERROR',
  RESET: 'payments/RESET',
}

const initialState = {
  list: [],
  item: {},
  loading: false,
  loadingUpdate: false,
  errorMessage: '',
  error: false,
  totalItems: 0,
  totalPages: 0,
  activePage: START_PAGE,
  filter: {
    payeeFIO: '',
    payeeCardNumber: '',
    payeePhone: '',
    payerPhone: '',
    paymentAmountMax: '',
    paymentAmountMin: '',
    paymentDateFrom: '',
    paymentDateTo: '',
    paymentDateTo2: '',
    paymentId: '',
    paymentState: '',
    paymentType: '',
  },
}

export const paymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ITEM): {
      return {
        ...state,
        loading: true,
        error: false,
      }
    }
    case REQUEST(ACTION_TYPES.CREATE_ITEM):
    case REQUEST(ACTION_TYPES.UPDATE_ITEM): {
      return {
        ...state,
        loadingUpdate: true,
        errorMessage: '',
        error: false,
      }
    }
    case FAILURE(ACTION_TYPES.FETCH_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ITEM):
    case FAILURE(ACTION_TYPES.CREATE_ITEM):
    case FAILURE(ACTION_TYPES.UPDATE_ITEM): {
      return {
        ...state,
        loading: false,
        loadingUpdate: false,
        errorMessage: action.payload.response.data?.errorText,
        error: true,
      }
    }
    case SUCCESS(ACTION_TYPES.FETCH_LIST): {
      const res = action.payload.data
      const list = res.page === 0 ? res.payments : state.list.concat(res.payments)
      return {
        ...state,
        loading: false,
        list,
        totalItems: res.totalElements,
        totalPages: res.totalPages,
        activePage: res.page,
        error: false,
      }
    }
    case SUCCESS(ACTION_TYPES.FETCH_ITEM): {
      const res = action.payload.data
      return {
        ...state,
        item: res,
        loading: false,
        error: false,
      }
    }
    case SUCCESS(ACTION_TYPES.CREATE_ITEM): {
      return {
        ...state,
        item: action.payload.data,
        loading: false,
        loadingUpdate: false,
        errorMessage: '',
        error: false,
      }
    }
    case SUCCESS(ACTION_TYPES.UPDATE_ITEM): {
      return {
        ...state,
        item: action.payload.data,
        loading: false,
        loadingUpdate: false,
        errorMessage: '',
        error: false,
      }
    }
    case ACTION_TYPES.UPDATE_FILTER: {
      return {
        ...state,
        list: [],
        filter: {
          ...initialState.filter,
          ...action.filter,
        },
      }
    }
    case ACTION_TYPES.CLEAR_FILTER: {
      return {
        ...state,
        list: [],
        filter: {
          ...initialState.filter,
        },
      }
    }
    case ACTION_TYPES.RESET_FILTER: {
      return {
        ...state,
        filter: {
          ...initialState.filter,
        },
      }
    }
    case ACTION_TYPES.RESET_LIST: {
      return {
        ...state,
        list: [],
        loading: false,
        loadingUpdate: false,
        totalItems: 0,
        totalPages: 0,
        activePage: START_PAGE,
        error: false,
      }
    }
    case ACTION_TYPES.RESET_ITEM: {
      return {
        ...state,
        item: {},
        loading: false,
        loadingUpdate: false,
        error: false,
      }
    }
    case ACTION_TYPES.RESET: {
      return {
        ...initialState,
      }
    }
    case ACTION_TYPES.RESET_ERROR: {
      return {
        ...state,
        errorMessage: '',
        error: false,
      }
    }
    default:
      return state
  }
}

const API_URL_PAYMENTS = `${SERVER}${API_URL}/payments`

export const getPaymentsList = (page = 1, pageSize = 20) => {
  const requestUrl = `${API_URL_PAYMENTS}?size=${pageSize}&page=${page}`
  return {
    type: ACTION_TYPES.FETCH_LIST,
    payload: axios.get(requestUrl),
  }
}

export const getPaymentsListSearch = (dataRequest) => (dispatch, getState) => {
  const filter = getState().payments.filter
  const { payeeCardNumber } = filter
  const requestUrl = `${API_URL_PAYMENTS}/search`

  const payeeCardNumberValue = payeeCardNumber && payeeCardNumber.slice(0, 6) + payeeCardNumber.slice(-4)

  dispatch({
    type: ACTION_TYPES.FETCH_LIST,
    payload: axios.post(
      requestUrl,
      {
        size: PAGE_SIZE,
        page: START_PAGE,
        payeeFIO: checkParam(filter.payeeFIO),
        payeeCardNumber: checkParam(payeeCardNumberValue),
        payeePhone: checkParam(filter.payeePhone),
        payerPhone: checkParam(filter.payerPhone),
        paymentAmountMax: checkParam(filter.paymentAmountMax),
        paymentAmountMin: checkParam(filter.paymentAmountMin),
        paymentDateFrom: checkParam(getDateTimeToSubmit(filter.paymentDateFrom)),
        paymentDateTo: checkParam(getDateTimeToSubmit(filter.paymentDateTo)),
        paymentId: checkParam(filter.paymentId),
        paymentState: checkParam(filter.paymentState),
        paymentType: checkParam(filter.paymentType),
        ...dataRequest,
      },
      { transformResponse: [(data) => JSONBigString.parse(data)] }
    ),
  })
}

export const getNextPage =
  (dataRequest = {}) =>
  (dispatch, getState) => {
    const activePage = getState().payments.activePage
    dispatch(
      getPaymentsListSearch({
        ...dataRequest,
        page: activePage + 1,
      })
    )
  }

export const getPaymentsItem = (id) => {
  const requestUrl = `${API_URL_PAYMENTS}/${id}`
  return {
    type: ACTION_TYPES.FETCH_ITEM,
    payload: axios.get(requestUrl, { transformResponse: [(data) => JSONBigString.parse(data)] }),
  }
}

export const updateFilter = (params) => async (dispatch) => {
  await dispatch({
    type: ACTION_TYPES.UPDATE_FILTER,
    filter: {
      ...params,
    },
  })
  dispatch(
    getPaymentsListSearch({
      page: START_PAGE,
    })
  )
}

export const clearFilter = () => async (dispatch) => {
  await dispatch({
    type: ACTION_TYPES.CLEAR_FILTER,
  })
  dispatch(
    getPaymentsListSearch({
      page: START_PAGE,
    })
  )
}

export const resetFilter = () => {
  return {
    type: ACTION_TYPES.RESET_FILTER,
  }
}

export const resetPaymentsList = () => {
  return {
    type: ACTION_TYPES.RESET_LIST,
  }
}

export const resetPaymentsItem = () => {
    return {
        type: ACTION_TYPES.RESET_ITEM,
    }
}

export const resetError = () => {
    return {
        type: ACTION_TYPES.RESET_ERROR,
    }
}

export const reset = () => {
    return {
        type: ACTION_TYPES.RESET,
    }
}
