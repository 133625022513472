import { Button } from '@chakra-ui/react'
import { CheckboxForm, Heading, InputForm, SelectForm, TextAreaForm } from 'chakra-lib'
import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn,
  Form,
  FormGrid,
  FormHeading,
  FormSection,
  HeadingContainer,
} from 'chakra-lib/layout'
import { InputDateForm } from 'components/InputDateForm'
import { InputTimeForm } from 'components/InputTimeForm'
import { Field, Formik } from 'formik'
import moment from 'moment'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Delete } from './components/Delete/Delete'
import { IconUploaderForm } from './components/IconUploaderForm'
import useCreateBannerItem from './services/useCreateBannerItem'
import useFetchBannerItem from './services/useFetchBannerItem'
import useUpdateBannerItem from './services/useUpdateBannerItem'
import {
  bannerIdentificationId,
  bannerIdentificationOptions,
  bannerTypes,
} from '../Banners.constants'

const isInvalidTime = (time) => {
  return moment(time, 'HH:mm', true).isValid()
}

const isInvalidDate = (date) => {
  return moment(date, 'DD.MM.YYYY', true).isValid()
}

const formSchema = Yup.object().shape({
  subject: Yup.string().required('Required'),
  body: Yup.string().required('Required'),
  locale: Yup.string().required('Required'),
  bannerType: Yup.string().required('Required'),
  severity: Yup.string().test('severity', 'Заполните поле', (e) => !Number(isNaN(e) || e < 0)),
  start: Yup.object({
    date: Yup.string().test('startDate', 'Заполните поле', (e) => isInvalidDate(e)),
    time: Yup.string().test('startTime', 'Заполните поле', (e) => isInvalidTime(e)),
  }),
  end: Yup.object({
    date: Yup.string().test('endDate', 'Заполните поле', (e) => isInvalidDate(e)),
    time: Yup.string().test('endTime', 'Заполните поле', (e) => isInvalidTime(e)),
  }),
})

const format = {
  date: 'DD.MM.YYYY',
  time: 'HH:mm',
}

export const Item = () => {
  const history = useHistory()
  const { id } = useParams()
  const isNew = !id

  const { isLoadingFetchItem, data } = useFetchBannerItem(id)
  const { createItem } = useCreateBannerItem()
  const { updateItem } = useUpdateBannerItem()

  const isLoading = isLoadingFetchItem

  const handleBack = () => {
    history.goBack()
  }

  const handleForm = async (values) => {
    const dataRequest = {
      ...values,
      isActive: values?.isActive ? 1 : 0,
      severity: Number(values?.severity),
      start: moment(
        `${values?.start.date} ${values?.start.time}`,
        `${'DD.MM.YYYY'} ${'HH:mm'}`
      ).format(),
      end: moment(`${values?.end.date} ${values?.end.time}`, `${'DD.MM.YYYY'} ${'HH:mm'}`).format(),
      maxVersionAndroid: values?.maxVersionAndroid === '' ? null : values?.maxVersionAndroid,
      maxVersionIOs: values?.maxVersionIOs === '' ? null : values?.maxVersionIOs,
      minVersionAndroid: values?.minVersionAndroid === '' ? null : values?.minVersionAndroid,
      minVersionIOs: values?.minVersionIOs === '' ? null : values?.minVersionIOs,
      identifications: values?.identifications.map((item) => ({
        identificationState: item,
        id: bannerIdentificationId[item],
      })),
    }
    if (isNew) {
      await createItem(dataRequest)
    } else {
      await updateItem({ id, data: dataRequest })
    }

    handleBack()
  }

  return (
    <Container>
      <CardContainer onBack={handleBack} indentTop={120}>
        <ContentContainer>
          <ContentContainerColumn>
            <HeadingContainer>
              <Heading>{isNew ? 'Создание баннера' : 'Обновление баннера'}</Heading>
              {!isNew && <Delete id={id} />}
            </HeadingContainer>
            <Formik
              initialValues={{
                imgView: data?.imgView ?? null,
                identifications: data?.identifications
                  ? data.identifications.map((item) => item?.identificationState)
                  : [],
                subject: data?.subject ?? '',
                body: data?.body ?? '',
                locale: data?.locale ?? 'ru',
                bannerType: data?.bannerType ?? '',
                severity: data?.severity ?? '0',
                maxVersionAndroid: data?.maxVersionAndroid ?? '',
                maxVersionIOs: data?.maxVersionIOs ?? '',
                minVersionAndroid: data?.minVersionAndroid ?? '',
                minVersionIOs: data?.minVersionIOs ?? '',
                end: {
                  date: data?.end
                    ? moment(data?.end).format(format.date)
                    : moment().format(format.date),
                  time: data?.end
                    ? moment(data?.end).format(format.time)
                    : moment().format(format.time),
                },
                start: {
                  date: data?.start
                    ? moment(data?.start).format(format.date)
                    : moment().format(format.date),
                  time: data?.start
                    ? moment(data?.start).format(format.time)
                    : moment().format(format.time),
                },
                tags: data?.tags,
                isActive: data?.isActive === 1,
              }}
              enableReinitialize
              onSubmit={handleForm}
              validationSchema={formSchema}
            >
              {(props) => (
                <Form
                  onSubmit={props.handleSubmit}
                  footer={
                    <Button type="submit" colorScheme="orange" isLoading={isLoading}>
                      {isNew ? 'Создать' : 'Обновить'}
                    </Button>
                  }
                >
                  <FormSection>
                    <Field
                      name="imgView"
                      label="Картинка"
                      component={IconUploaderForm}
                      isDisabled={isLoading}
                    />
                    <Field
                      name="subject"
                      component={InputForm}
                      label="Тема"
                      isDisabled={isLoading}
                    />
                    <Field
                      name="identifications"
                      component={SelectForm}
                      placeholder=""
                      label="Группа пользователей"
                      isDisabled={isLoading}
                      options={bannerIdentificationOptions}
                      isMulti
                    />
                    <Field
                      name="body"
                      component={TextAreaForm}
                      label="Сообщение"
                      minH="160px"
                      isDisabled={isLoading}
                    />
                    <FormGrid columns={2}>
                      <Field
                        isDisabled={isLoading}
                        name="bannerType"
                        label="Тип"
                        component={SelectForm}
                        placeholder="Выберите ..."
                        options={[
                          {
                            label: 'Info',
                            value: bannerTypes.info,
                          },
                          {
                            label: 'Alert',
                            value: bannerTypes.alert,
                          },
                          {
                            label: 'Market',
                            value: bannerTypes.market,
                          },
                        ]}
                      />
                      <Field
                        name="severity"
                        component={InputForm}
                        label="Важность"
                        isDisabled={isLoading}
                      />
                    </FormGrid>
                    <Field
                      isDisabled={isLoading}
                      name="locale"
                      label="Язык"
                      component={SelectForm}
                      placeholder="Выберите ..."
                      options={[
                        {
                          label: 'Русский (ru)',
                          value: 'ru',
                        },
                        {
                          label: 'Армянский (hy)',
                          value: 'hy',
                        },
                      ]}
                    />
                    <Field name="tags" component={InputForm} label="Теги" isDisabled={isLoading} />
                  </FormSection>
                  <FormSection>
                    <FormHeading>Время работы</FormHeading>
                    <FormGrid columns={2}>
                      <Field
                        isHideError
                        isDisabled={isLoading}
                        name="start.date"
                        label="Дата старта"
                        placeholder="01.01.2023"
                        component={InputDateForm}
                      />
                      <Field
                        isHideError
                        isDisabled={isLoading}
                        name="start.time"
                        label="Время"
                        placeholder="00:00"
                        component={InputTimeForm}
                      />
                    </FormGrid>
                    <FormGrid columns={2}>
                      <Field
                        isHideError
                        isDisabled={isLoading}
                        name="end.date"
                        label="Дата финиша"
                        placeholder="01.01.2023"
                        component={InputDateForm}
                      />
                      <Field
                        isHideError
                        isDisabled={isLoading}
                        name="end.time"
                        label="Время"
                        placeholder="00:00"
                        component={InputTimeForm}
                      />
                    </FormGrid>
                  </FormSection>
                  <FormSection>
                    <FormHeading>Ограничения</FormHeading>
                    <FormGrid columns={2}>
                      <Field
                        isHideError
                        isDisabled={isLoading}
                        name="maxVersionAndroid"
                        label="Максимальная версия Android"
                        placeholder="Для всех"
                        component={InputForm}
                      />
                      <Field
                        isHideError
                        isDisabled={isLoading}
                        name="minVersionAndroid"
                        label="Минимальная версия Android"
                        placeholder="Для всех"
                        component={InputForm}
                      />
                    </FormGrid>
                    <FormGrid columns={2}>
                      <Field
                        isHideError
                        isDisabled={isLoading}
                        name="maxVersionIOs"
                        label="Максимальная версия IOS"
                        placeholder="Для всех"
                        component={InputForm}
                      />
                      <Field
                        isHideError
                        isDisabled={isLoading}
                        name="minVersionIOs"
                        label="Минимальная версия IOS"
                        placeholder="Для всех"
                        component={InputForm}
                      />
                    </FormGrid>
                  </FormSection>
                  <FormSection>
                    <Field
                      name="isActive"
                      component={CheckboxForm}
                      label="Активно"
                      isDisabled={isLoading}
                    />
                  </FormSection>
                </Form>
              )}
            </Formik>
          </ContentContainerColumn>
        </ContentContainer>
      </CardContainer>
    </Container>
  )
}
