import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import { api } from 'api'
import useQuery from 'components/useQuery'

const useDeleteBannerItem = () => {
  const toast = useToast()
  const { isError, isLoading, isSuccess, request } = useQuery((data) => api.banner.deleteItem(data))

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось получить данные',
        status: 'error',
      })
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Готово',
        description: 'Баннер успешно удален',
        status: 'success',
      })
    }
  }, [isSuccess])

  return {
    deleteItem: request,
    isLoadingDeleteItem: isLoading,
    isSuccessDeleteItem: isSuccess,
  }
}

export default useDeleteBannerItem
