import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Snippets, SnippetsHeading, SnippetsItem, SnippetsList } from 'chakra-lib'
import React, { useEffect } from 'react'
import useFetchCustomerRelatedBy from './services/useFetchCustomerRelatedBy'
import { HeadingItem, ItemIcon, ItemText } from 'chakra-lib/Snippets'
import { IoPerson } from 'react-icons/io5'

export const InvitedUsersModal = ({ isOpen, onClose, originCode }) => {
  const { fetchCustomerRelatedBy, dataCustomerRelatedBy } = useFetchCustomerRelatedBy()

  useEffect(() => {
    const fetchData = async () => {
      if (originCode && isOpen) {
        await fetchCustomerRelatedBy(originCode)
      }
    }
    fetchData()
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Приглашены по коду</ModalHeader>
        <ModalCloseButton mt={1} />
        <ModalBody minH="300px" pb="30px">
          <Snippets columns="42px 1fr">
            <SnippetsHeading>
              <HeadingItem />
              <HeadingItem>Пользователь</HeadingItem>
            </SnippetsHeading>
            <SnippetsList
              activePage={0}
              totalPages={0}
              data={dataCustomerRelatedBy}
              keyField="customerId"
            >
              {(item) => {
                return (
                  <SnippetsItem target="_blank" to={`/user/phone=${item?.customerId}`}>
                    <ItemIcon icon={IoPerson} />
                    <ItemText>{item?.customerId}</ItemText>
                  </SnippetsItem>
                )
              }}
            </SnippetsList>
          </Snippets>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
