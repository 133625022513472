export const columns = [
  {
    accessor: 'firstName',
    header: 'Фамилия',
    key: 'firstName',
  },
  {
    accessor: 'lastName',
    header: 'Имя',
    key: 'lastName',
  },
  {
    accessor: 'middleName',
    header: 'Отчество',
    key: 'middleName',
  },
  {
    accessor: 'phone',
    header: 'Телефон',
    key: 'phone',
  },
  {
    accessor: 'identityCountry',
    header: 'Страна',
    key: 'identityCountry',
  },
  {
    accessor: 'identification',
    header: 'Идентификация',
    key: 'identification',
  },
  {
    accessor: 'createDate',
    header: 'Дата регистрации',
    key: 'createDate',
  },
  {
    accessor: 'identificationDate',
    header: 'Дата идентификации',
    key: 'identificationDate',
  },
  {
    accessor: 'clientId',
    header: 'ClienID',
    key: 'clientId',
  },
  {
    accessor: 'email',
    header: 'Email',
    key: 'email',
  },
  {
    accessor: 'platform',
    header: 'Платформа приложения',
    key: 'platform',
  },
  {
    accessor: 'applicationVersion',
    header: 'Версия приложения',
    key: 'applicationVersion',
  },
  {
    accessor: 'birthDate',
    header: 'Дата рождения',
    key: 'birthDate',
  },
]
