import { useBoolean } from '@chakra-ui/react'
import _ from 'lodash'
import { IconUploader } from '../IconUploader'

export const IconUploaderForm = ({ field, form, onDelete, ...props }) => {
  const [isOpenUpload, setIsOpenUpload] = useBoolean()

  const handleUploadFile = (e) => {
    const dataRequest = {
      name: e.name,
      file: e.uri,
      type: e.type,
    }
    form.setFieldValue(field.name, dataRequest.file)
    setIsOpenUpload.off()
  }

  const handleDeleteFile = () => {
    form.setFieldValue(field.name, null)
  }

  return (
    <IconUploader
      isCropRequired
      isOpen={isOpenUpload}
      onOpen={() => setIsOpenUpload.on()}
      onClose={() => setIsOpenUpload.off()}
      onDelete={handleDeleteFile}
      {...field}
      isInvalid={_.get(form.errors, field.name) && _.get(form.touched, field.name)}
      value={field.value}
      onUploaderChange={handleUploadFile}
      optionsCropper={{
        aspectRatio: 1,
        maxCropBoxHeight: 600,
        maxCropBoxWidth: 600,
        minCropBoxHeight: 100,
        minCropBoxWidth: 100,
      }}
      optionsCompress={{
        maxWidth: 600,
        maxHeight: 600,
      }}
      optionsCroppedCanvas={{
        maxWidth: 600,
        maxHeight: 600,
        fillColor: 'transparent',
      }}
      {...props}
    />
  )
}
