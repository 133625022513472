import { Box, FormControl, Input, InputGroup, Stack } from '@chakra-ui/react'
import MaskedInput from 'react-text-mask'
import { FormLabel } from 'chakra-lib'

export const InputTime = ({
  name,
  onChange,
  isHideError,
  isInvalid,
  error,
  value,
  label,
  ...props
}) => {
  return (
    <FormControl isInvalid={isInvalid}>
      {label && <FormLabel>{label}</FormLabel>}
      <Stack spacing={4}>
        <Box>
          <InputGroup>
            <MaskedInput
              mask={[/[0-9]/, /[0-9]/, ':', /[0-9]/, /[0-9]/]}
              id={name}
              value={value}
              render={(ref, props) => <Input id={name} ref={ref} {...props} />}
              onChange={(e) => onChange(e.target.value)}
              {...props}
            />
          </InputGroup>
          {error && isInvalid && !isHideError && (
            <Box fontSize="0.7rem" color="red.500" mt="4px">
              {error}
            </Box>
          )}
        </Box>
      </Stack>
    </FormControl>
  )
}
