import { Box, Button, useBoolean } from '@chakra-ui/react'
import { Modal, Select } from 'chakra-lib'
import { useState } from 'react'
import { identificationOptions } from './ChangeUserIdentification.constants'
import { useChangeUserIdentification } from './services/useChangeUserIdentification'

export const ChangeUserIdentification = ({ initialValues = {}, userId, isDisabled }) => {
  const initialState = { identification: initialValues.identification }

  const { mutate, isLoading } = useChangeUserIdentification(userId)
  const [isOpen, setOpenModal] = useBoolean()
  const [values, setValues] = useState(initialState)

  const onChange = (value) => {
    setValues((state) => ({ ...state, ...value }))
  }

  const handleSubmit = () => {
    mutate(values)
    setOpenModal.off()
  }

  const onClose = () => {
    setValues(initialState)
    setOpenModal.off()
  }

  return (
    <>
      <Button
        width="100%"
        onClick={setOpenModal.on}
        colorScheme="gray"
        isLoading={isLoading}
        isDisabled={isDisabled}
      >
        Изменить
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} title="Изменить идентификацию">
        <Select
          name="identification"
          label="Статус"
          isSearchable={false}
          placeholder="Выберите"
          value={values.identification}
          options={identificationOptions}
          onChange={(event) => onChange({ identification: event.value })}
        />
        <Box pt={12}>
          <Button colorScheme="orange" isLoading={isLoading} onClick={handleSubmit}>
            Продолжить
          </Button>
        </Box>
      </Modal>
    </>
  )
}
