import axios from 'axios'
import { API_URL, SERVER } from 'config/api'

const ENTITY = 'faq'

export const faq = Object.freeze({
  async fetchList(params) {
    const response = await axios.get(`${SERVER}${API_URL}/${ENTITY}/list`, { params })
    return response.data
  },
  async fetchItem(id) {
    const response = await axios.get(`${SERVER}${API_URL}/${ENTITY}/${id}`)
    return response.data
  },
  async create(data) {
    const response = await axios.post(`${SERVER}${API_URL}/${ENTITY}`, data)
    return response.data
  },
  async update(data) {
    const response = await axios.put(`${SERVER}${API_URL}/${ENTITY}`, data)
    return response.data
  },
  async remove(id) {
    const response = await axios.delete(`${SERVER}${API_URL}/${ENTITY}/${id}`)
    return response.data
  },
})
