import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import { api } from 'api'
import useQuery from 'components/useQuery'

const useTransferRefund = () => {
  const toast = useToast()
  const { isError, request } = useQuery((data) => api.referral.refund(data))

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось отправить данные',
        status: 'error',
      })
    }
  }, [isError])

  return {
    setRefund: request,
  }
}

export default useTransferRefund
