import { Button } from '@chakra-ui/react'
import { Snippets, SnippetsHeading, SnippetsItem, SnippetsList } from 'chakra-lib'
import { HeadingItem, ItemIcon, ItemText } from 'chakra-lib/Snippets'
import {
  Container,
  FilterHeader,
  FilterStack,
  SnippetsContainer,
  SnippetsContainerMain,
  SnippetsContainerRightAside,
} from 'chakra-lib/layout'
import { getAccess } from 'components/Private'
import { ROLES } from 'model/user.model'
import { useRef } from 'react'
import { IoDocument } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getDateTimeReadFormat } from 'utils/dateFormatting'
import { faqCategoryTitle, faqLang } from '../Faq.constants'
import { ListFilter } from './ListFilter'
import { useFetchFaqList } from './services/useFetchFaqList'

export const List = () => {
  const history = useHistory()
  const { list, hasMore, fetchNextPage, isLoading, fetch } = useFetchFaqList()
  const filtersRef = useRef()
  const user = useSelector((state) => state.authentication.user)
  const isAdmin = getAccess(user.roles, [ROLES.admin])

  const onChangeFilters = (state) => {
    filtersRef.current = state
    const { search } = state

    fetch({ search })
  }

  return (
    <Container>
      <SnippetsContainer indentTop={122}>
        <SnippetsContainerMain>
          <Snippets columns="42px minmax(250px, 1fr) minmax(250px, 1fr) minmax(120px, 1fr) minmax(200px, 1fr) minmax(150px, 1fr)">
            <SnippetsHeading position="sticky" top="0" zIndex={3}>
              <HeadingItem />
              <HeadingItem>Вопрос</HeadingItem>
              <HeadingItem>Ответ</HeadingItem>
              <HeadingItem>Порядковый номер</HeadingItem>
              <HeadingItem>Категория</HeadingItem>
              <HeadingItem>Создан </HeadingItem>
            </SnippetsHeading>
            <SnippetsList
              data={list}
              isLoading={isLoading}
              hasMore={hasMore}
              onNext={() => fetchNextPage()}
            >
              {(item) => {
                const hasQuestion = item?.question.length !== 0
                const hasAnswer = item?.answer.length !== 0

                return (
                  <SnippetsItem to={`/services/faq/show/${item.id}`}>
                    <ItemIcon icon={IoDocument} />
                    <ItemText noOfLines={hasQuestion ? 3 : undefined}>
                      {hasQuestion
                        ? item.question.find((item) => item.language === faqLang.RU).text
                        : undefined}
                    </ItemText>
                    <ItemText noOfLines={hasAnswer ? 3 : undefined}>
                      {hasAnswer
                        ? item.answer.find((item) => item.language === faqLang.RU).text
                        : undefined}
                    </ItemText>
                    <ItemText>{item?.order}</ItemText>
                    <ItemText>{item?.category && faqCategoryTitle[item?.category]}</ItemText>
                    <ItemText>{getDateTimeReadFormat(item?.created)}</ItemText>
                  </SnippetsItem>
                )
              }}
            </SnippetsList>
          </Snippets>
        </SnippetsContainerMain>
        <SnippetsContainerRightAside>
          <FilterHeader>
            <Button
              width="100%"
              onClick={() => history.push(`/services/faq/new`)}
              colorScheme="orange"
              isDisabled={!isAdmin}
            >
              Добавить
            </Button>
          </FilterHeader>
          <FilterStack>
            <ListFilter onChange={onChangeFilters} />
          </FilterStack>
        </SnippetsContainerRightAside>
      </SnippetsContainer>
    </Container>
  )
}
