import React, { useState } from 'react'
import { Badge, Box, Grid, Image, Text, useColorModeValue } from '@chakra-ui/react'
import Lightbox from '../Lightbox/Lightbox'
import { getDateReadFormat } from 'chakra-lib/utils/dateFormatting'
import { TYPE_DOCUMENTS_LABEL, TYPE_DOCUMENTS_STATUS_LABEL } from 'model/documents.model'

export const Item = ({ item, ...props }) => {
  const { url, scanType, status, createDate } = item
  return (
    <Box
      role="group"
      width="100%"
      cursor="pointer"
      transition="0.2s"
      borderWidth={1}
      borderRadius="6px"
      backgroundColor={useColorModeValue('white', 'gray.800')}
      _hover={{
        borderColor: useColorModeValue('gray.300', 'gray.600'),
      }}
      {...props}
    >
      <Box
        width="100%"
        height="220px"
        borderTopLeftRadius="5px"
        borderTopRightRadius="5px"
        backgroundColor={useColorModeValue('gray.100', 'gray.700')}
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        <Image maxHeight="220px" src={url} />
      </Box>
      <Box padding="16px">
        <Badge
          colorScheme="purple"
          padding="1px 10px"
          borderRadius={100}
          fontSize="0.68rem"
          marginBottom="4px"
        >
          {TYPE_DOCUMENTS_STATUS_LABEL[status] || status}
        </Badge>
        <Text>{TYPE_DOCUMENTS_LABEL[scanType] || scanType}</Text>
        <Text fontSize="0.8rem" color="gray.400" mt="10px">
          Создано: {getDateReadFormat(createDate)}
        </Text>
      </Box>
    </Box>
  )
}

export const ImageSnippets = ({ data }) => {
  const [urlImage, setUrlImage] = useState('')
  const openImage = (url) => {
    setUrlImage(url)
  }
  const closeImage = () => {
    setUrlImage('')
  }
  return (
    <>
      <Grid templateColumns={`repeat(auto-fill, minmax(260px, 1fr))`} gap={6}>
        {data &&
          data.map((item) => (
            <Item key={item.id} item={item} onClick={() => openImage(item.url)} />
          ))}
      </Grid>
      {urlImage !== '' && <Lightbox image={urlImage} title={''} onClose={closeImage} />}
    </>
  )
}
