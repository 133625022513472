import React from 'react'
import {
  Box,
  Button,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import useSettingsFetch from './services/useSettingsFetch'
import { Form } from 'chakra-lib'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { InputForm } from 'chakra-lib'
import useSettingsUpdate from './services/useSettingsUpdate'
import { SwitchForm } from '../../../SwitchForm'

const formSchema = Yup.object({
  circuitBreakerFreq1: Yup.number()
    .integer('invalid decimal')
    .min(0, 'Min')
    .required('Заполните поле'),
  circuitBreakerFreq2: Yup.number()
    .integer('invalid decimal')
    .min(0, 'Min')
    .required('Заполните поле'),
  circuitBreakerPeriod1: Yup.number()
    .integer('invalid decimal')
    .min(0, 'Min')
    .required('Заполните поле'),
  circuitBreakerPeriod2: Yup.number()
    .integer('invalid decimal')
    .min(0, 'Min')
    .required('Заполните поле'),
})

export const ErrorOpenApiSettings = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { getSettings, isLoadingFetch, data } = useSettingsFetch()
  const { updateSettings, isLoadingUpdate } = useSettingsUpdate()

  const handleOpen = async () => {
    onOpen()
    await getSettings()
  }

  const handleForm = async (values) => {
    const dataRequest = {
      ...data,
      circuitBreakerEnabled: values?.circuitBreakerEnabled ? 1 : 0,
      circuitBreakerFreq1: Number(values.circuitBreakerFreq1),
      circuitBreakerFreq2: Number(values.circuitBreakerFreq2),
      circuitBreakerPeriod1: Number(values.circuitBreakerPeriod1),
      circuitBreakerPeriod2: Number(values.circuitBreakerPeriod2),
    }

    await updateSettings(dataRequest)
    onClose()
  }

  return (
    <>
      <MenuItem onClick={handleOpen}>Настройка ошибок OpenApi</MenuItem>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Настройка ошибок OpenApi</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              enableReinitialize
              validationSchema={formSchema}
              initialValues={{
                circuitBreakerEnabled: data?.circuitBreakerEnabled === 1,
                circuitBreakerFreq1: String(data?.circuitBreakerFreq1) ?? 0,
                circuitBreakerFreq2: String(data?.circuitBreakerFreq2) ?? 0,
                circuitBreakerPeriod1: String(data?.circuitBreakerPeriod1) ?? 0,
                circuitBreakerPeriod2: String(data?.circuitBreakerPeriod2) ?? 0,
              }}
              onSubmit={handleForm}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <Stack spacing={4}>
                    <Field name="circuitBreakerEnabled" label="Активно" component={SwitchForm} />
                    <Field
                      name="circuitBreakerPeriod1"
                      label="Период 1 (секунды)"
                      component={InputForm}
                      placeholder="0"
                    />
                    <Field
                      name="circuitBreakerFreq1"
                      label="Число попыток 1"
                      component={InputForm}
                      placeholder="0"
                    />
                    <Field
                      name="circuitBreakerPeriod2"
                      label="Период 2 (секунды)"
                      component={InputForm}
                      placeholder="0"
                    />
                    <Field
                      name="circuitBreakerFreq2"
                      label="Число попыток 2"
                      component={InputForm}
                      placeholder="0"
                    />
                  </Stack>
                  <Box pt="30px" pb="20px">
                    <Button
                      type="submit"
                      colorScheme="orange"
                      isLoading={isLoadingUpdate || isLoadingFetch}
                    >
                      Сохранить
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
