import React, { useMemo } from 'react'
import { Button } from '@chakra-ui/react'
import {
  Container,
  FilterHeader,
  SnippetsContainer,
  SnippetsContainerMain,
  SnippetsContainerRightAside,
} from 'chakra-lib/layout'
import { Snippets, SnippetsHeading, SnippetsItem, SnippetsList } from 'chakra-lib'
import { HeadingItem, ItemIcon, ItemLink, ItemText } from 'chakra-lib/Snippets'
import useFetchReferralCatalog from './services/useFetchReferralCatalog'
import { useHistory } from 'react-router-dom'
import { SiSublimetext } from 'react-icons/si'
import { filter } from 'lodash'

export const List = () => {
  const history = useHistory()
  const { data, isLoading } = useFetchReferralCatalog()

  const _data = useMemo(() => filter(data, (e) => !e?.hidden), [data])

  return (
    <Container>
      <SnippetsContainer indentTop={120}>
        <SnippetsContainerMain>
          <Snippets columns="42px minmax(400px, 1fr) 380px 380px 400px 400px" itemWidth="2200px">
            <SnippetsHeading>
              <HeadingItem />
              <HeadingItem>Код</HeadingItem>
              <HeadingItem>Описание</HeadingItem>
              <HeadingItem>Вознаграждение приглашенного</HeadingItem>
              <HeadingItem>Вознаграждение пригласившего</HeadingItem>
              <HeadingItem>Зависимость</HeadingItem>
            </SnippetsHeading>
            <SnippetsList
              activePage={0}
              totalPages={0}
              data={_data}
              keyField="eventCode"
              isLoading={isLoading}
            >
              {(item) => (
                <SnippetsItem to={`/referral/catalog/show/${item.eventCode}`}>
                  <ItemIcon icon={SiSublimetext} />
                  <ItemLink>{item?.eventCode}</ItemLink>
                  <ItemText>{item?.description}</ItemText>
                  <ItemText>{`${item?.referralReward}`}</ItemText>
                  <ItemText>{`${item?.referrerReward}`}</ItemText>
                  <ItemText>{item?.relatedEvents}</ItemText>
                </SnippetsItem>
              )}
            </SnippetsList>
          </Snippets>
        </SnippetsContainerMain>
        <SnippetsContainerRightAside>
          <FilterHeader>
            <Button
              width="100%"
              onClick={() => history.push(`/referral/catalog/new`)}
              colorScheme="orange"
            >
              Создать
            </Button>
          </FilterHeader>
        </SnippetsContainerRightAside>
      </SnippetsContainer>
    </Container>
  )
}
