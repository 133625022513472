import React, { useCallback, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { SnippetsContext } from './Snippets'
import { Box, useColorModeValue } from '@chakra-ui/react'

export const SnippetsItem = ({ to, children, ...props }) => {
  const { columns, gap, itemWidth } = useContext(SnippetsContext)
  const themeColor = useColorModeValue('black', 'white')
  const themeBorderColor = useColorModeValue('gray.100', 'gray.700')
  const themeBgHover = useColorModeValue('gray.50', 'gray.700')
  const history = useHistory()

  const onClickHandle = useCallback(() => {
    if (to) {
      history.push(to)
    }
  }, [to])

  return (
    <Box
      onClick={onClickHandle}
      display="grid"
      role="group"
      gridGap={gap}
      minWidth={itemWidth}
      gridTemplateColumns={columns}
      padding="12px 16px"
      minHeight="62px"
      transition="0.3s"
      borderBottomWidth="1px"
      borderColor={themeBorderColor}
      color={themeColor}
      cursor={to && 'pointer'}
      _hover={{
        backgroundColor: themeBgHover,
      }}
      {...props}
    >
      {children}
    </Box>
  )
}
