import React from 'react'
import { FormLabel } from '../FormLabel'
import { FormControl, Textarea } from '@chakra-ui/react'

export const TextArea = React.forwardRef(
  ({ name, isInvalid, label, placeholder, onChange, value, textAlign = 'left', ...props }, ref) => {
    return (
      <FormControl isInvalid={isInvalid}>
        {label && <FormLabel>{label}</FormLabel>}
        <Textarea
          ref={ref}
          id={name}
          placeholder={placeholder}
          textAlign={textAlign}
          value={value || ''}
          onChange={onChange}
          {...props}
        />
      </FormControl>
    )
  }
)
