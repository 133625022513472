import React, { useMemo } from 'react'
import { Box, Button, Flex, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import { ImageCropper } from '../ImageCropper'
import empty from './empty-image.svg'
import { FormLabel } from 'chakra-lib'
import _ from 'lodash'

export const FormUploadImage = ({
  field,
  form,
  label,
  isLoading,
  isCropRequired,
  optionsCompress,
  optionsCropper,
  optionsCroppedCanvas,
  colorScheme = 'blue',
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const errorColor = useColorModeValue('#E53E3E', '#fc8181')
  const isInvalid = _.get(form.errors, field.name) && _.get(form.touched, field.name)
  const error = _.get(form.errors, field.name)

  const value = useMemo(() => {
    if (field.value !== '') {
      return JSON.parse(field.value)
    }
    return {
      name: '',
      uri: '',
      type: '',
    }
  }, [field.value])

  const handleChange = (e) => {
    form.setFieldValue(field.name, JSON.stringify(e))
  }

  return (
    <>
      <Box>
        {label && <FormLabel>{label}</FormLabel>}
        <Flex
          alignItems="center"
          borderRadius={6}
          borderWidth={1}
          padding="16px 20px"
          {...field}
          {...props}
          {...(error &&
            isInvalid && {
              borderColor: errorColor,
              boxShadow: `0 0 0 1px ${errorColor}`,
            })}
        >
          <Flex
            width="100px"
            height="100px"
            name="F"
            marginRight={6}
            borderRadius="8px"
            overflow="hidden"
            backgroundImage={empty}
          >
            {value.uri !== '' && (
              <Flex
                flex={1}
                backgroundImage={value.uri}
                backgroundSize="contain"
                backgroundRepeat="no-repeat"
                backgroundPosition="center"
              />
            )}
          </Flex>
          <Button variant="outline" onClick={onOpen} isLoading={isLoading} size="sm">
            Выбрать
          </Button>
        </Flex>
      </Box>
      <ImageCropper
        colorScheme={colorScheme}
        isOpen={isOpen}
        onClose={onClose}
        file={value}
        isCropRequired={isCropRequired}
        onChange={handleChange}
        optionsCompress={optionsCompress}
        optionsCropper={optionsCropper}
        optionsCroppedCanvas={optionsCroppedCanvas}
      />
    </>
  )
}
