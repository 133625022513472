import React from 'react'
import { Flex } from '@chakra-ui/react'

export const FormHeading = ({ children }) => {
  return (
    <Flex alignItems="center" fontSize="1.17rem" fontWeight="semibold" padding="14px 0 4px 0">
      {children}
    </Flex>
  )
}
