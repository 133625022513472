import React from 'react'
import { Box, Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import { FormFooter } from './FormFooter'
import { useFormikContext } from 'formik'

export const Form = ({ footer, children, onSubmit, ...props }) => {
  const dataForm = useFormikContext()
  const hasError = dataForm ? !dataForm.isValid && dataForm.submitCount > 0 : false
  const errors = Object.values(dataForm.errors)

  return (
    <Box {...props}>
      <form onSubmit={onSubmit}>
        {children}
        {hasError && (
          <Box pt="20px">
            <Flex alignItems="center" color="red.500" fontSize="0.87rem">
              <Text>Есть ошибки в форме:</Text>
            </Flex>
            <UnorderedList alignItems="flexStart" color="red.500" fontSize="0.87rem">
              {errors.map((error, index) => {
                if (typeof error === 'object') {
                  return Object.values(error).map((item, index) => {
                    return <ListItem key={index}>{item}</ListItem>
                  })
                }
                return <ListItem key={index}>{error}</ListItem>
              })}
            </UnorderedList>
          </Box>
        )}
        {footer && <FormFooter>{footer}</FormFooter>}
      </form>
    </Box>
  )
}
