import React from 'react'
import _ from 'lodash'
import { InputTime } from '../InputTime'

export const InputTimeForm = ({ field, form, sizeInput, label, ...props }) => {
  const handleChange = (e) => {
    form.setFieldValue(field.name, e)
  }

  return (
    <InputTime
      name={field.name}
      label={label}
      isInvalid={_.get(form.errors, field.name) && _.get(form.touched, field.name)}
      error={_.get(form.errors, field.name)}
      {...field}
      onChange={handleChange}
      {...props}
    />
  )
}

