import { useMutation, useQueryClient } from 'react-query'
import toast from 'utils/toast'
import { DATE_FORMAT } from 'utils/dateFormatting'
import moment from 'moment/moment'
import { api } from 'api'

export const useSendPaymentsRegistry = ({ onSuccess }) => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ date, type }) => {
      const momentFrom = moment(date.from, DATE_FORMAT)
      const momentTo = moment(date.to, DATE_FORMAT)

      const body = {
        from: date ? momentFrom.format(dateFormat) : undefined,
        to: date ? momentTo.format(dateFormat) : undefined,
        type,
      }

      return api.payments.createReport(body)
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
        queryClient.invalidateQueries(['reportsList'])

        toast.showSuccess({
          title: 'Заявка отправлена успешно',
        })
      },
      onError: (err) => {
        toast.showError({
          title: 'Произошла ошибка при попытке отправить заявку',
          description: err.response?.data?.message || err.message,
        })
      },
    }
  )
}

const dateFormat = 'YYYY-MM-DD'
