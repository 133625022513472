import { FAILURE, REQUEST, SUCCESS } from './actions-type'
import axios from 'axios'
import qs from 'qs'
import { Storage } from 'utils/storage'
import { API_URL, SERVER, SERVER_KEYCLOAK } from 'config/api'

const AUTH_TOKEN_KEY = 'authenticationToken'
const REFRESH_TOKEN_KEY = 'refreshToken'

export const ACTION_TYPES = {
  LOGIN: 'authentication/LOGIN',
  REFRESH_LOGIN: 'authentication/REFRESH_LOGIN',
  CHECK: 'authentication/CHECK',
  RESET: 'authentication/RESET',
  SET_ERROR_MESSAGE: 'authentication/SET_ERROR_MESSAGE',
}

const initialState = {
  user: '',
  isAuthenticated: false,
  refreshAuthenticated: false,
  loading: false,
  errorMessage: '',
  error: false,
}

export const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.REFRESH_LOGIN): {
      return {
        ...state,
        loading: true,
        isAuthenticated: false,
        refreshAuthenticated: true,
        errorMessage: '',
        error: false,
      }
    }
    case REQUEST(ACTION_TYPES.LOGIN):
    case REQUEST(ACTION_TYPES.CHECK): {
      return {
        ...state,
        loading: true,
        errorMessage: '',
        error: false,
      }
    }
    case FAILURE(ACTION_TYPES.REFRESH_LOGIN): {
      return {
        ...state,
        loading: false,
        refreshAuthenticated: false,
        errorMessage: action.payload.message,
        //error: true,
      }
    }
    case FAILURE(ACTION_TYPES.LOGIN): {
      return {
        ...state,
        loading: false,
        error: true,
        //errorMessage: action.payload.response.data
      }
    }
    case FAILURE(ACTION_TYPES.CHECK): {
      return {
        ...state,
        //error: true,
        loading: false,
      }
    }
    case SUCCESS(ACTION_TYPES.CHECK): {
      return {
        ...state,
        user: action.payload.data,
        loading: false,
        errorMessage: '',
        isAuthenticated: true,
        error: false,
      }
    }
    case SUCCESS(ACTION_TYPES.REFRESH_LOGIN): {
      return {
        ...state,
        loading: false,
        refreshAuthenticated: false,
        error: false,
        errorMessage: '',
      }
    }
    case SUCCESS(ACTION_TYPES.LOGIN): {
      return {
        ...state,
        loading: false,
        error: false,
      }
    }
    case ACTION_TYPES.SET_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    }
    case ACTION_TYPES.RESET: {
      return {
        ...state,
        user: '',
        errorMessage: '',
        isAuthenticated: false,
      }
    }
    default:
      return state
  }
}

const API_LOGIN_URL = `${SERVER_KEYCLOAK}keycloak/auth/realms/idpay_realm/protocol/openid-connect/token`
const API_URL_USERS = `${SERVER}${API_URL}/users`

const headers = { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' }

export const loginAuth = (username, password) => async (dispatch) => {
  try {
    const data = {
      client_id: 'admin-console-api-client',
      grant_type: 'password',
      scope: 'openid',
      username,
      password,
    }
    const result = await dispatch({
      type: ACTION_TYPES.LOGIN,
      payload: axios({
        method: 'post',
        url: API_LOGIN_URL,
        data: qs.stringify({
          ...data,
        }),
        headers,
      }),
    })
    const bearerToken = result?.value?.data['access_token']
    const refreshToken = result?.value?.data['refresh_token']
    if (bearerToken) {
      Storage.local.set(AUTH_TOKEN_KEY, bearerToken)
      Storage.local.set(REFRESH_TOKEN_KEY, refreshToken)
    }
    await dispatch(checkAuth())
  } catch (err) {
    const errorMessage = err.response?.data?.['error_description']
    dispatch({
      type: ACTION_TYPES.SET_ERROR_MESSAGE,
      errorMessage: errorMessage ? errorMessage : err.message,
    })
  }
}

export const refreshLoginAuth = () => async (dispatch) => {
  try {
    const localRefreshToken = Storage.local.get(REFRESH_TOKEN_KEY)
    const data = {
      client_id: 'admin-console-api-client',
      grant_type: 'refresh_token',
      refresh_token: localRefreshToken,
    }
    const result = await dispatch({
      type: ACTION_TYPES.REFRESH_LOGIN,
      payload: axios({
        method: 'post',
        url: API_LOGIN_URL,
        data: qs.stringify({
          ...data,
        }),
        headers,
      }),
    })
    const bearerToken = result?.value?.data['access_token']
    const refreshToken = result?.value?.data['refresh_token']
    if (bearerToken) {
      Storage.local.set(AUTH_TOKEN_KEY, bearerToken)
      Storage.local.set(REFRESH_TOKEN_KEY, refreshToken)
    }
    await dispatch(checkAuth())
  } catch (err) {
    dispatch({
      type: ACTION_TYPES.SET_ERROR_MESSAGE,
      errorMessage: err.message,
    })
  }
}

export const checkAuth = () => {
  const requestUrl = `${API_URL_USERS}/me`
  return {
    type: ACTION_TYPES.CHECK,
    payload: axios.get(requestUrl),
  }
}

export const clearAuthToken = () => {
  if (Storage.local.get(AUTH_TOKEN_KEY)) {
    Storage.local.remove(AUTH_TOKEN_KEY)
  }
  if (Storage.local.get(REFRESH_TOKEN_KEY)) {
    Storage.local.remove(REFRESH_TOKEN_KEY)
  }
}

export const resetAuth = () => {
  clearAuthToken()
  return {
    type: ACTION_TYPES.RESET,
  }
}
