import React, { useEffect, useState } from 'react'
import { Badge, Box, Stack, Text } from '@chakra-ui/react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { getDateTimeReadFormat } from 'utils/dateFormatting'
import { getPaymentsItem, resetPaymentsItem } from 'store/payments.reducer'
import {
  PAYMENT_STATE,
  PAYMENT_TYPE,
  TYPE_PAYMENT_LABEL,
  TYPE_PAYMENT_SOURCE_LABEL,
  TYPE_PAYMENT_STATE_COLOR,
  TYPE_PAYMENT_STATE_LABEL,
} from 'model/payments.model'
import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn,
  GridField,
  GridFieldHeading,
  GridFieldItem,
  RightAside,
} from 'chakra-lib/layout'
import { CompletePaymentModal } from './CompletePaymentModal/CompletePaymentModal'
import { ROLES } from 'model/user.model'
import { PrivateBox } from 'components/Private/Private'
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'chakra-lib'
import { HistoryList } from './HistoryList/HistoryList'
import { FinishedPaymentModal } from './FinishedPaymentModal/FinishedPaymentModal'
import { rbNullDistinationTitle } from '../Payments.constants'

export const PaymentsShow = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id, paramTabIndex } = useParams()
  const user = useSelector((state) => state.authentication.user)
  const item = useSelector((state) => state.payments.item)
  const loading = useSelector((state) => state.payments.loading)
  const isReadyItem = !isEmpty(item)
  const [tabIndex, setTabIndex] = useState(Number(paramTabIndex) || 0)
  const handleBack = () => {
    history.goBack()
  }

  useEffect(() => {
    dispatch(getPaymentsItem(id))
    return () => {
      dispatch(resetPaymentsItem())
    }
  }, [])

  useEffect(() => {
    setTabIndex(Number(paramTabIndex))
  }, [paramTabIndex])

  const handleTabsChange = (index) => {
    history.replace(`/payments/show/${index}/${id}`)
  }

  const distination = `${item?.destinationAmount ?? ''} ${item?.destinationCurrency ?? ''}`
  const distinationRender =
    item.destinationAmount === 0 && item.paymentType === PAYMENT_TYPE.RF_RB_TRANSFER_TO_CARD
      ? rbNullDistinationTitle
      : distination

  return (
    <Container>
      <CardContainer columns="86px 1fr" indentTop={70} onBack={handleBack}>
        <Tabs
          variant="enclosed-colored"
          colorScheme="orange"
          onChange={handleTabsChange}
          index={tabIndex}
        >
          <TabList>
            <Tab>Данные платежа</Tab>
            <Tab>История</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <ContentContainer>
                <ContentContainerColumn>
                  <GridField>
                    <GridFieldHeading>Данные перевода</GridFieldHeading>
                    <GridFieldItem label="Сумма списания" isLoading={loading} isReady={isReadyItem}>
                      {`${item?.amount} ${item?.currency}`}
                    </GridFieldItem>
                    <GridFieldItem label="Сумма комиссии" isLoading={loading} isReady={isReadyItem}>
                      {`${item?.commission ?? ''} ${item?.commissionCurrency ?? ''}`}
                    </GridFieldItem>
                    <GridFieldItem
                      label="Сумма к выдаче получателю"
                      isLoading={loading}
                      isReady={isReadyItem}
                    >
                      {distinationRender}
                    </GridFieldItem>
                    <GridFieldItem label="Тип" isLoading={loading} isReady={isReadyItem}>
                      {TYPE_PAYMENT_LABEL[item?.paymentType] || item.paymentType}
                    </GridFieldItem>
                    {[
                      PAYMENT_TYPE.RF_RA_TRANSFER_TO_CARD,
                      PAYMENT_TYPE.RF_RB_TRANSFER_TO_CARD,
                    ].includes(item?.paymentType) && (
                      <>
                        <GridFieldItem
                          label="ФИО получателя"
                          isLoading={loading}
                          isReady={isReadyItem}
                        >
                          {item?.payeeFIO}
                        </GridFieldItem>
                      </>
                    )}
                    {item?.paymentDestination && (
                      <GridFieldItem
                        as={Link}
                        label="Карта получателя"
                        to={`/payments/payeeCardNumber=${item?.paymentDestination}`}
                        isLoading={loading}
                        isReady={isReadyItem}
                        transition="0.2s"
                        _hover={{
                          opacity: 0.6,
                        }}
                      >
                        {item?.paymentDestination}
                      </GridFieldItem>
                    )}
                    <GridFieldItem
                      as={Link}
                      to={`/user/phone=${item?.payeePhone}`}
                      label="Получатель"
                      isLoading={loading}
                      isReady={isReadyItem}
                      transition="0.2s"
                      _hover={{
                        opacity: 0.6,
                      }}
                    >
                      {item?.payeePhone}
                    </GridFieldItem>
                    <GridFieldItem
                      as={Link}
                      to={`/user/phone=${item?.payerPhone}`}
                      label="Отправитель"
                      isLoading={loading}
                      isReady={isReadyItem}
                      transition="0.2s"
                      _hover={{
                        opacity: 0.6,
                      }}
                    >
                      {item?.payerPhone}
                    </GridFieldItem>
                    <GridFieldItem label="Дата" isLoading={loading} isReady={isReadyItem}>
                      {getDateTimeReadFormat(item?.paymentDate)}
                    </GridFieldItem>
                    <GridFieldItem label="PaymentID" isLoading={loading} isReady={isReadyItem}>
                      {item?.id}
                    </GridFieldItem>
                    <GridFieldItem
                      label={`Источник (${TYPE_PAYMENT_SOURCE_LABEL[item.paymentSourceType]})`}
                      isLoading={loading}
                      isReady={isReadyItem}
                    >
                      {item.paymentSource
                        ? item.paymentSource
                        : item.paymentType === 'WITHDRAW_TO_CARD'
                          ? 'Внутренний счёт'
                          : item.paymentSource}
                    </GridFieldItem>

                    {item.state === PAYMENT_STATE.NOT_ACQUIRED && (
                      <>
                        <GridFieldItem label="Код ошибки" isLoading={loading} isReady={isReadyItem}>
                          {item?.subStatusCode}
                        </GridFieldItem>
                        <GridFieldItem
                          label="Расшифровка ошибки"
                          isLoading={loading}
                          isReady={isReadyItem}
                        >
                          {item?.subStatusDescription}
                        </GridFieldItem>
                      </>
                    )}
                  </GridField>
                  {item?.retryComment && (
                    <GridField>
                      <GridFieldHeading>Комментарий</GridFieldHeading>
                      <GridFieldItem isLoading={loading} isReady={isReadyItem}>
                        {item?.retryComment}
                      </GridFieldItem>
                    </GridField>
                  )}
                  {(item.state === 'MANUAL_CHECK' || item.state === 'ROLLBACK_WITH_REFUND') &&
                    item?.description && (
                      <GridField>
                        <GridFieldHeading>Ошибка платежа</GridFieldHeading>
                        <GridFieldItem isLoading={loading} isReady={isReadyItem}>
                          {item?.description}
                        </GridFieldItem>
                      </GridField>
                    )}
                </ContentContainerColumn>
                <ContentContainerColumn>
                  <Box position="sticky" top="10px">
                    <RightAside>
                      <Text fontSize="0.82rem" color="gray.400" mb={2}>
                        Статус
                      </Text>
                      <Badge
                        padding="1px 10px"
                        borderRadius={5}
                        fontSize="1rem"
                        width="100%"
                        textAlign="center"
                        pt={3}
                        pb={3}
                        whiteSpace="none"
                        colorScheme={TYPE_PAYMENT_STATE_COLOR[item.state] || 'gray'}
                      >
                        {TYPE_PAYMENT_STATE_LABEL[item.state] || item.state}
                      </Badge>
                      {item.state === PAYMENT_STATE.MANUAL_CHECK &&
                        item.paymentType !== PAYMENT_TYPE.RF_RB_TRANSFER_TO_CARD && (
                          <PrivateBox role={user.roles} hasAnyRole={[ROLES.admin]}>
                            <Stack spacing={2} pt="20px">
                              <CompletePaymentModal id={id} />
                            </Stack>
                          </PrivateBox>
                        )}

                      {[
                        PAYMENT_STATE.MANUAL_CHECK,
                        PAYMENT_STATE.FINISHED_WITH_ERRORS,
                        PAYMENT_STATE.ACQUIRING_CANCEL_ERROR,
                      ].includes(item.state) &&
                        item.paymentType !== PAYMENT_TYPE.RF_RB_TRANSFER_TO_CARD && (
                          <PrivateBox role={user.roles} hasAnyRole={[ROLES.admin]}>
                            <Stack spacing={2} pt="20px">
                              <FinishedPaymentModal id={id} />
                            </Stack>
                          </PrivateBox>
                        )}
                    </RightAside>
                  </Box>
                </ContentContainerColumn>
              </ContentContainer>
            </TabPanel>
            <TabPanel>
              <HistoryList id={id} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </CardContainer>
    </Container>
  )
}
