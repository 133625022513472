import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  HStack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Modal, Select } from 'chakra-lib'
import useRolesListFetch from './useRolesListFetch'
import { filter, isEmpty, some } from 'lodash'
import { allowedRoles } from '../../constants/allowedRoles'
import useRoleMappingsAdd from './useRoleMappingsAdd'
import useRoleMappingsFetch from './useRoleMappingsFetch'
import useRoleMappingsDelete from './useRoleMappingsDelete'

export const Role = ({ id }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [value, setValue] = useState({})
  const { rolesList } = useRolesListFetch()
  const { data, fetchRoleMappingItem } = useRoleMappingsFetch(id)
  const { createUserRole, isLoadingCreateUser, isSuccessCreateUser } = useRoleMappingsAdd(id)
  const { deleteUserRole } = useRoleMappingsDelete(id)

  const valueList = useMemo(() => {
    if (isEmpty(data)) {
      return
    }
    const list = filter(data?.realmMappings, (e) => allowedRoles.includes(e.name))
    return list.map((i) => ({ label: i.name, value: i.id }))
  }, [data])

  const optionsList = useMemo(() => {
    const list = filter(rolesList, (e) => {
      return allowedRoles.includes(e.name) && !some(valueList, { label: e.name, value: e.id })
    })
    return list.map((i) => ({ label: i.name, value: i.id }))
  }, [rolesList, valueList])

  const handleClose = () => {
    onClose()
    setValue({})
  }

  const handleAddRole = async () => {
    const dataRequest = [
      {
        id: value.value,
        name: value.label,
      },
    ]
    await createUserRole(dataRequest)
    fetchRoleMappingItem()
  }

  const handleDeleteRole = async (e) => {
    const dataRequest = [
      {
        id: e.value,
        name: e.label,
      },
    ]
    await deleteUserRole(dataRequest)
    fetchRoleMappingItem()
  }

  useEffect(() => {
    if (isSuccessCreateUser) {
      handleClose()
    }
  }, [isSuccessCreateUser])

  return (
    <>
      {isEmpty(valueList) ? (
        <Text>Для данного пользователя роли не назначены</Text>
      ) : (
        <HStack alignItems="flex-start">
          {valueList?.map((item) => (
            <Tag key={item.value} borderRadius="full" variant="solid" colorScheme="orange">
              <TagLabel>{item.label}</TagLabel>
              <TagCloseButton onClick={() => handleDeleteRole(item)} />
            </Tag>
          ))}
        </HStack>
      )}
      <Box>
        <Button colorScheme="gray" onClick={onOpen} size="sm">
          Добавить роль
        </Button>
      </Box>
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        title="Добавление роли"
        footer={
          <Button colorScheme="orange" isLoading={isLoadingCreateUser} onClick={handleAddRole}>
            Добавить
          </Button>
        }
      >
        <Select
          autoFocus
          isSearchable={false}
          placeholder="Выберите роль"
          name="value"
          value={value.value}
          options={optionsList}
          onChange={(e) => setValue(e)}
          label="Роли"
        />
      </Modal>
    </>
  )
}
