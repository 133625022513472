import React from 'react'
import { MdSettings } from 'react-icons/md'
import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useColorMode,
} from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ROLES, ROLES_LABEL } from 'model/user.model'
import { PrivateBox } from '../Private/Private'
import {
  LunaSettings,
  PaymentsIntervalSettings,
  ErrorOpenApiSettings,
  EkengSettings,
  SmsProviders,
  Registry,
  MailingProviders,
} from './elements'

export const ProfileWidget = ({ ...props }) => {
  const history = useHistory()
  const { colorMode, toggleColorMode } = useColorMode()
  const user = useSelector((state) => state.authentication.user)
  const handleLogout = () => {
    history.push('/logout')
  }
  const getRolesString = () => {
    const result = user?.roles?.map((item) => `${ROLES_LABEL[item]}, `).join('')
    return result.slice(0, -2)
  }
  return (
    <Flex alignItems="center" {...props}>
      <Box mr={4} lineHeight="1.5" flex={1}>
        <Text fontSize="0.88rem" textAlign="right" fontWeight="semibold">
          {user.login}
        </Text>
        <Text fontSize="0.7rem" textAlign="right" color="gray.400">
          {getRolesString()}
        </Text>
      </Box>
      <Menu closeOnSelect>
        <MenuButton
          as={IconButton}
          isRound
          size="sm"
          width="40px"
          height="40px"
          minWidth="36px"
          fontSize="1rem"
          variant="outline"
          color="gray.500"
          icon={<MdSettings />}
        />
        <MenuList
          minWidth="240px"
          boxShadow="rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.1) 0px 9px 24px"
          borderWidth={0}
        >
          <PrivateBox role={user.roles} hasAnyRole={[ROLES.admin]}>
            <PaymentsIntervalSettings />
            <LunaSettings />
            <EkengSettings />
            <ErrorOpenApiSettings />
            <SmsProviders />
            <Registry />
            <MailingProviders />
          </PrivateBox>
          <MenuDivider />
          <MenuItem onClick={toggleColorMode}>
            {colorMode === 'light' ? 'Темная тема' : 'Светлая тема'}
          </MenuItem>
          <MenuDivider />
          <MenuItem onClick={handleLogout}>Выход</MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  )
}
