export const reportType = {
  booking: 'BOOKKEEPING',
  marketers: 'MARKETERS',
}

export const reportTypeTitle = {
  [reportType.booking]: 'Бухгалтерия',
  [reportType.marketers]: 'Маркетинг',
}

export const reportTypeOptions = Object.values(reportType).map((item) => ({
  label: reportTypeTitle[item],
  value: item,
}))