import {
  PAYMENT_STATE,
  PAYMENT_TYPE,
  TYPE_PAYMENT_LABEL,
  TYPE_PAYMENT_STATE_LABEL,
} from '../../model/payments.model'

export const paymentStateOptions = [
  PAYMENT_STATE.ACCEPTED,
  PAYMENT_STATE.PAY,
  PAYMENT_STATE.ACQUIRING,
  PAYMENT_STATE.FINISHED,
  PAYMENT_STATE.FINISHED_WITH_ERRORS,
  PAYMENT_STATE.REJECTED_BY_PROVIDER,
  PAYMENT_STATE.ROLLBACK_WITH_REFUND,
  PAYMENT_STATE.NOT_ACQUIRED,
  PAYMENT_STATE.MANUAL_CHECK,
  PAYMENT_STATE.UNKNOWN,
  PAYMENT_STATE.PROCESSING,
  PAYMENT_STATE.ACQUIRING_CANCEL_ERROR,
  PAYMENT_STATE.PREPARED,
  PAYMENT_STATE.NOT_ENOUGH_MONEY,
  PAYMENT_STATE.NEW,
  PAYMENT_STATE.LIMIT_EXCEEDED,
].map((item) => ({
  value: item,
  label: TYPE_PAYMENT_STATE_LABEL[item],
}))

export const paymentTypeOptions = Object.values(PAYMENT_TYPE)
  .filter((item) => item !== PAYMENT_TYPE.UNDEFINED_TYPE)
  .map((item) => ({
    value: item,
    label: TYPE_PAYMENT_LABEL[item],
  }))

export const rbNullDistinationTitle = 'Н/Д'
