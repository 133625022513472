export const faqCategory = {
  FaqCategory_Application: 'FaqCategory_Application',
  FaqCategory_Client: 'FaqCategory_Client',
  FaqCategory_Limit: 'FaqCategory_Limit',
  FaqCategory_Registration: 'FaqCategory_Registration',
  FaqCategory_Transfer: 'FaqCategory_Transfer',
  FaqCategory_Invalid: 'FaqCategory_Invalid',
}

export const faqCategoryTitle = {
  [faqCategory.FaqCategory_Application]: 'Приложение',
  [faqCategory.FaqCategory_Client]: 'Клиентам IDBank',
  [faqCategory.FaqCategory_Limit]: 'Лимиты',
  [faqCategory.FaqCategory_Registration]: 'Регистрация',
  [faqCategory.FaqCategory_Transfer]: 'Переводы/Комиссия',
  [faqCategory.FaqCategory_Invalid]: 'Invalid',
}

export const faqCategoryOptions = Object.values(faqCategory)
  .filter((item) => item !== faqCategory.FaqCategory_Invalid)
  .map((item) => ({
    value: item,
    label: faqCategoryTitle[item],
  }))

export const faqLang = {
  RU: 'RU',
  EN: 'EN',
  HY: 'HY',
}

export const faqLangTitle = {
  [faqLang.RU]: 'Русский',
  [faqLang.EN]: 'Английский',
  [faqLang.HY]: 'Армянский',
}

export const faqTextOptions = Object.values(faqLang).map((item) => ({
  value: item,
  label: faqLangTitle[item],
}))
