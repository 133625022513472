import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn,
  Form,
  FormSection,
} from 'chakra-lib/layout'
import { InputForm } from 'chakra-lib'
import { Field, Formik } from 'formik'
import { Button, useToast } from '@chakra-ui/react'
import useFetchSettings from './services/useFetchSettings'
import { useMemo, useState } from 'react'
import { find, isEmpty } from 'lodash'
import * as Yup from 'yup'
import useUpdateSettings from './services/useUpdateSettings'

const formSchema = Yup.object().shape({
  refLinkUseCount: Yup.string().test('reward', 'Заполните поле', (e) => !Number(isNaN(e) || e < 0)),
  refLinkCodeMaxSymbols: Yup.string().test(
    'reward',
    'Заполните поле',
    (e) => !Number(isNaN(e) || e < 0)
  ),
  conversion: Yup.string().test('reward', 'Заполните поле', (e) => !Number(isNaN(e) || e < 0)),
})

export const Settings = () => {
  const toast = useToast()
  const { dataSettings, isLoadingFetchSettings } = useFetchSettings()
  const { updateSettings } = useUpdateSettings()
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)

  const data = useMemo(() => {
    if (isEmpty(dataSettings)) {
      return {
        refLinkUseCount: 0,
        refLinkCodeMaxSymbols: 0,
        conversion: 0,
        maxTransfer: 0,
        minTransfer: 0,
      }
    }
    const refLinkUseCount = find(dataSettings, (e) => e.key === 'REF_LINK_USE_COUNT')
    const refLinkCodeMaxSymbols = find(dataSettings, (e) => e.key === 'REF_LINK_CODE_MAX_SYMBOLS')
    const conversion = find(dataSettings, (e) => e.key === 'CONVERSION')
    const maxTransfer = find(dataSettings, (e) => e.key === 'MAX_TRANSFER')
    const minTransfer = find(dataSettings, (e) => e.key === 'MIN_TRANSFER')
    return {
      refLinkUseCount: refLinkUseCount?.value,
      refLinkCodeMaxSymbols: refLinkCodeMaxSymbols?.value,
      conversion: conversion?.value,
      maxTransfer: maxTransfer.value,
      minTransfer: minTransfer.value,
    }
  }, [dataSettings])

  const handleForm = async (values) => {
    try {
      setIsLoadingUpdate(true)
      await updateSettings({
        id: 'REF_LINK_USE_COUNT',
        data: {
          key: 'REF_LINK_USE_COUNT',
          value: Number(values?.refLinkUseCount),
        },
      })
      await updateSettings({
        id: 'REF_LINK_CODE_MAX_SYMBOLS',
        data: {
          key: 'REF_LINK_CODE_MAX_SYMBOLS',
          value: Number(values?.refLinkCodeMaxSymbols),
        },
      })
      await updateSettings({
        id: 'CONVERSION',
        data: {
          key: 'CONVERSION',
          value: Number(values?.conversion),
        },
      })
      await updateSettings({
        id: 'MAX_TRANSFER',
        data: {
          key: 'MAX_TRANSFER',
          value: Number(values?.maxTransfer),
        },
      })
      await updateSettings({
        id: 'MIN_TRANSFER',
        data: {
          key: 'MIN_TRANSFER',
          value: Number(values?.minTransfer),
        },
      })
      toast({
        description: 'Настройки успешно обновлены',
        status: 'success',
      })
    } catch (e) {
      toast({
        description: 'Неудалось сохранить настройки',
        status: 'error',
      })
    } finally {
      setIsLoadingUpdate(false)
    }
  }

  const isLoading = isLoadingFetchSettings || isLoadingUpdate

  return (
    <Container>
      <CardContainer indentTop={120} isHideBack>
        <ContentContainer>
          <ContentContainerColumn>
            <Formik
              initialValues={{
                refLinkUseCount: String(data?.refLinkUseCount),
                refLinkCodeMaxSymbols: String(data?.refLinkCodeMaxSymbols),
                conversion: String(data?.conversion),
                maxTransfer: String(data?.maxTransfer),
                minTransfer: String(data?.minTransfer),
              }}
              enableReinitialize
              onSubmit={handleForm}
              validationSchema={formSchema}
            >
              {(props) => (
                <Form
                  onSubmit={props.handleSubmit}
                  footer={
                    <Button type="submit" colorScheme="orange" isLoading={isLoading}>
                      Обновить
                    </Button>
                  }
                >
                  <FormSection>
                    <Field
                      name="refLinkUseCount"
                      component={InputForm}
                      label="Максимальное число использования ссылки"
                      isDisabled={isLoading}
                    />
                    <Field
                      name="refLinkCodeMaxSymbols"
                      component={InputForm}
                      label="Количество символов в ссылке"
                      isDisabled={isLoading}
                    />
                    <Field
                      name="conversion"
                      component={InputForm}
                      label="Коэффициент конверсии при списании"
                      isDisabled={isLoading}
                    />
                    <Field
                      name="maxTransfer"
                      component={InputForm}
                      label="Максимальная сумма списания бонусов"
                      isDisabled={isLoading}
                    />
                    <Field
                      name="minTransfer"
                      component={InputForm}
                      label="Минимальная сумма списания бонусов"
                      isDisabled={isLoading}
                    />
                  </FormSection>
                </Form>
              )}
            </Formik>
          </ContentContainerColumn>
        </ContentContainer>
      </CardContainer>
    </Container>
  )
}
