import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn,
  Form,
  FormGrid,
  FormLabel,
  FormSection,
  HeadingContainer,
} from 'chakra-lib/layout'
import { Heading, InputForm, SelectForm, TextAreaForm } from 'chakra-lib'
import { Button } from '@chakra-ui/react'
import { useHistory, useParams } from 'react-router-dom'
import { Field, Formik } from 'formik'
import useFetchCatalogItem from './services/useFetchCatalogItem'
import * as Yup from 'yup'
import useCreateCatalogItem from './services/useCreateCatalogItem'
import useFetchEventsCodeOptions from './services/useFetchEventsCodeOptions'
import { Delete } from './components/Delete/Delete'
import React, { useMemo } from 'react'
import useUpdateCatalogItem from './services/useUpdateCatalogItem'
import { isEmpty } from 'lodash'
import { catalogEntryTypeOptions } from './Item.constants'

const formSchema = Yup.object().shape({
  eventCode: Yup.string().required('Required'),
  referralReward: Yup.string().test('reward', 'Заполните поле', (e) => !Number(isNaN(e) || e < 0)),
  referrerReward: Yup.string().test('reward', 'Заполните поле', (e) => !Number(isNaN(e) || e < 0)),
})

export const Item = () => {
  const history = useHistory()
  const { id } = useParams()
  const isNew = !id

  const { isLoadingFetchItem, data } = useFetchCatalogItem(id)
  const { createItem, isLoadingCreateItem } = useCreateCatalogItem()
  const { updateItem, isLoadingUpdateItem } = useUpdateCatalogItem(id)
  const { optionsCode } = useFetchEventsCodeOptions()

  const options = useMemo(() => {
    if (isEmpty(optionsCode)) {
      return []
    }
    return [
      {
        label: 'Нет',
        value: null,
      },
      ...optionsCode?.map((e) => ({
        label: e,
        value: e,
      })),
    ]
  }, [optionsCode])

  const isLoading = isLoadingFetchItem || isLoadingCreateItem || isLoadingUpdateItem
  const isReadOnly = isLoading

  const handleBack = () => {
    history.goBack()
  }

  const handleForm = async (values) => {
    const dataRequest = {
      ...values,
      referralReward: Number(values?.referralReward),
      referrerReward: Number(values?.referrerReward),
      count: Number(values?.count),
      catalogEntryType: values.catalogEntryType || null,
      amount: values.amount || null,
    }

    if (isNew) {
      await createItem(dataRequest)
    } else {
      await updateItem(dataRequest)
    }

    handleBack()
  }

  return (
    <Container>
      <CardContainer onBack={handleBack} indentTop={120}>
        <ContentContainer>
          <ContentContainerColumn>
            <HeadingContainer>
              <Heading>{isNew ? 'Создание события' : 'Обновление события'}</Heading>
              {!isNew && <Delete id={id} />}
            </HeadingContainer>
            <Formik
              initialValues={{
                eventCode: data?.eventCode,
                description: data?.description,
                relatedEvents: data?.relatedEvents,
                referralReward: String(data?.referralReward ?? 0),
                referrerReward: String(data?.referrerReward ?? 0),
                count: String(data?.count ?? 0),
                catalogEntryType: data?.catalogEntryType,
                amount: data?.amount,
              }}
              enableReinitialize
              onSubmit={handleForm}
              validationSchema={formSchema}
            >
              {(formProps) => (
                <Form
                  onSubmit={formProps.handleSubmit}
                  {...(!isReadOnly && {
                    footer: (
                      <Button type="submit" colorScheme="orange" isLoading={isLoading}>
                        {isNew ? 'Создать' : 'Обновить'}
                      </Button>
                    ),
                  })}
                >
                  <FormSection>
                    <Field
                      name="eventCode"
                      component={InputForm}
                      label="Код"
                      isReadOnly={!isNew}
                      isDisabled={isLoading}
                    />
                    <Field
                      name="description"
                      component={TextAreaForm}
                      label="Описание"
                      isReadOnly={isReadOnly}
                      isDisabled={isLoading}
                    />
                    <Field
                      isDisabled={isLoading || isReadOnly}
                      name="relatedEvents"
                      label="Зависимое событие"
                      component={SelectForm}
                      placeholder="Выберите ..."
                      options={options}
                    />
                    <FormGrid columns={2}>
                      <Field
                        name="referralReward"
                        component={InputForm}
                        label="Вознаграждение"
                        isReadOnly={isReadOnly}
                        isDisabled={isLoading}
                      />
                      <Field
                        name="referrerReward"
                        component={InputForm}
                        label="Вознаграждение реферера"
                        isReadOnly={isReadOnly}
                        isDisabled={isLoading}
                      />
                    </FormGrid>
                    <Field
                      name="count"
                      component={InputForm}
                      label="Курс перевода бонусов в деньги"
                      isReadOnly={isReadOnly}
                      isDisabled={isLoading}
                    />

                    <FormLabel>
                      Настройка суммы выплат (заполнять для события выплат бонусов)
                    </FormLabel>
                    <Field
                      name="catalogEntryType"
                      component={SelectForm}
                      label="Тип платежа"
                      placeholder="Выберите тип платежа"
                      isReadOnly={isReadOnly}
                      isDisabled={isLoading}
                      options={catalogEntryTypeOptions}
                      isClearable
                    />
                    <Field
                      name="amount"
                      component={InputForm}
                      type="number"
                      label="Сумма выплат"
                      placeholder="Введите сумму выплат"
                      isReadOnly={isReadOnly}
                      isDisabled={isLoading}
                    />
                  </FormSection>
                </Form>
              )}
            </Formik>
          </ContentContainerColumn>
        </ContentContainer>
      </CardContainer>
    </Container>
  )
}
