import { Box, Button, Flex, HStack, VStack } from '@chakra-ui/react'
import { InputForm, SelectForm, TextAreaForm } from 'chakra-lib'
import { Form } from 'chakra-lib/layout'
import { RadioButton } from 'components/RadioButton'
import { Field, FieldArray, Formik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'
import { faqCategoryOptions, faqTextOptions } from '../Faq.constants'

const formSchema = Yup.object({
  // subject: Yup.string().required('Поле обязательно'),
})

export const ItemForm = ({ onSubmit, onRemove, initialValues, isLoading }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <Formik
      enableReinitialize
      initialValues={{ list: [], ...initialValues }}
      onSubmit={onSubmit}
      validationSchema={formSchema}
      validateOnMount
    >
      {(props) => {
        return (
          <Form>
            <VStack spacing="20px" alignItems="flex-start">
              <Flex mt="30px">
                <FieldArray
                  name="list"
                  render={() => (
                    <>
                      {faqTextOptions.map((option, index) => (
                        <React.Fragment key={index}>
                          <RadioButton
                            isActive={activeIndex === index}
                            onClick={() => setActiveIndex(index)}
                            mr={2}
                          >
                            {option.label}
                          </RadioButton>
                        </React.Fragment>
                      ))}
                    </>
                  )}
                />
              </Flex>

              <Field
                name={`list[${activeIndex}].question`}
                label="Вопрос"
                component={TextAreaForm}
                minHeight="200px"
                isDisabled={isLoading}
              />
              <Field
                name={`list[${activeIndex}].answer`}
                label="Ответ"
                component={TextAreaForm}
                minHeight="200px"
                isDisabled={isLoading}
              />
              <Field
                name="category"
                label="Категория"
                component={SelectForm}
                isDisabled={isLoading}
                options={faqCategoryOptions}
                placeholder="Выберите категорию"
              />
              <Field
                name="order"
                label="Порядковый номер"
                component={InputForm}
                isDisabled={isLoading}
              />
            </VStack>

            <Box pt="30px">
              <HStack spacing={4} justifyContent="space-between">
                <Button
                  onClick={() => props.handleSubmit()}
                  colorScheme="orange"
                  isLoading={isLoading}
                  isDisabled={!props.isValid}
                  minW="120px"
                >
                  {initialValues ? 'Обновить' : 'Добавить'}
                </Button>
                {initialValues && (
                  <Button
                    onClick={onRemove}
                    colorScheme="red"
                    isLoading={isLoading}
                    isDisabled={!props.isValid}
                    minW="120px"
                  >
                    Удалить
                  </Button>
                )}
              </HStack>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}
