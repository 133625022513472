import { api } from 'api'
import { useQuery } from 'react-query'
import toast from 'utils/toast'

export const useFetchItem = (id, options) => {
  return useQuery(['useFetchFaqItem', id], () => api.faq.fetchItem(id), {
    onError: (err) => {
      toast.showError({
        title: 'Произошла ошибка при загрузке faq',
        description: err.response?.data?.message || err.message,
      })
    },
    cacheTime: 0,
    ...options,
  })
}
