import { Box, Button, VStack } from '@chakra-ui/react'
import { InputForm, TextAreaForm } from 'chakra-lib'
import { Form } from 'chakra-lib/layout'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'

const formSchema = Yup.object({
  subject: Yup.string().required('Поле обязательно'),
})

export const ItemForm = ({ onSubmit, isLoading }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      onSubmit={onSubmit}
      validationSchema={formSchema}
      validateOnMount
    >
      {(props) => {
        return (
          <Form>
            <VStack spacing="20px">
              <Field
                name="subject"
                label="Номер карты/телефона"
                component={InputForm}
                isDisabled={isLoading}
              />
              <Field
                name="description"
                label="Комментарий"
                component={TextAreaForm}
                minHeight="100px"
                isDisabled={isLoading}
              />
            </VStack>

            <Box pt="30px">
              <Button
                onClick={() => props.handleSubmit()}
                colorScheme="orange"
                isLoading={isLoading}
                isDisabled={!props.isValid}
              >
                Добавить
              </Button>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}
