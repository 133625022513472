import React, { useCallback, useEffect } from 'react'
import {
  Box,
  Button,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Skeleton,
  useBoolean,
  VStack,
} from '@chakra-ui/react'
import { useFetchProvidersSettings } from './hooks/useFetchProvidersSettings'
import { providersTypeOptions } from './MailingProviders.constants'
import { useSaveProvidersSettings } from './hooks/useSaveProvidersSettings'

export const MailingProviders = () => {
  const [isOpen, setIsOpen] = useBoolean()
  const [provider, setProvider] = React.useState()

  const { data, isLoading: settingsIsLoading } = useFetchProvidersSettings({ enabled: isOpen })
  const { mutate, isLoading: settingsIsSaving } = useSaveProvidersSettings()

  useEffect(() => {
    if (data) {
      setProvider(data.currentProvider)
    }
  }, [data])

  const onSubmitHandle = useCallback(() => {
    mutate(provider)
  }, [provider])

  const onChangeProvider = useCallback((value) => {
    setProvider(value)
  }, [])

  const formIsDisabled = settingsIsLoading || settingsIsSaving
  const availableProviders = data?.availableProviders || []

  return (
    <>
      <MenuItem onClick={setIsOpen.on}>Оператор рассылки email</MenuItem>
      <Modal isOpen={isOpen} onClose={setIsOpen.off}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Оператор рассылки email</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              {providersTypeOptions.map(({ title, value }) => (
                <RadioGroup
                  key={value}
                  size="lg"
                  w="full"
                  onChange={onChangeProvider}
                  value={provider}
                >
                  <Skeleton h="40px" isLoaded={!!data} borderRadius="5px">
                    {availableProviders.includes(value) ? (
                      <Radio value={value} isDisabled={formIsDisabled}>
                        {title}
                      </Radio>
                    ) : null}
                  </Skeleton>
                </RadioGroup>
              ))}
            </VStack>
            <Box pt="30px" pb="20px">
              <Button
                onClick={onSubmitHandle}
                colorScheme="orange"
                isLoading={settingsIsLoading || settingsIsSaving}
              >
                Обновить
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
