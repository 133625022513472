import { FAILURE, REQUEST, SUCCESS } from './actions-type'
import axios from 'axios'
import { API_URL, SERVER } from 'config/api'
import { checkParam } from 'utils/checkParam'
import { getDateTimeToSubmit } from 'utils/dateFormatting'
import { getBoolean } from 'utils/getBoolean'

const PAGE_SIZE = 20
const START_PAGE = 0

export const ACTION_TYPES = {
  FETCH_LIST: 'user/FETCH_LIST',
  FETCH_INCOMING_TRANSFERS: 'user/FETCH_INCOMING_TRANSFERS',
  UPDATE_INCOMING_TRANSFERS: 'user/UPDATE_INCOMING_TRANSFERS',
  RESET_LIST: 'user/RESET_LIST',
  UPDATE_FILTER: 'user/UPDATE_FILTER',
  CLEAR_FILTER: 'user/CLEAR_FILTER',
  RESET_FILTER: 'user/RESET_FILTER',
  FETCH_ITEM: 'user/FETCH_ITEM',
  CREATE_ITEM: 'user/CREATE_ITEM',
  UPDATE_ITEM: 'user/UPDATE_ITEM',
  RESET_ITEM: 'user/RESET_ITEM',
  RESET_LIST_INCOMING_TRANSFERS: 'user/RESET_LIST_INCOMING_TRANSFERS',
  RESET_ERROR: 'user/RESET_ERROR',
  RESET: 'user/RESET',
}

const initialState = {
  list: [],
  item: {},
  loading: false,
  loadingUpdate: false,
  errorMessage: '',
  error: false,
  totalItems: 0,
  totalPages: 0,
  activePage: START_PAGE,
  trusted: false,
  filter: {
    clientId: '',
    firstName: '',
    lastName: '',
    middleName: '',
    passportNumber: '',
    phone: '',
    email: '',
    platform: '',
    applicationVersion: '',
    identificationDateFrom: '',
    identificationDateTo: '',
    registrationDateFrom: '',
    registrationDateTo: '',
    identification: '',
    accountNumber: '',
    contractNumber: '',
  },
  incomingTransfers: {
    loading: false,
    list: [],
    fromDate: '',
    toDate: '',
    limit: 20,
    typeDate: 'week',
  },
}

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_INCOMING_TRANSFERS): {
      return {
        ...state,
        incomingTransfers: {
          ...state.incomingTransfers,
          loading: true,
        },
      }
    }
    case REQUEST(ACTION_TYPES.FETCH_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ITEM): {
      return {
        ...state,
        loading: true,
        error: false,
      }
    }
    case REQUEST(ACTION_TYPES.CREATE_ITEM):
    case REQUEST(ACTION_TYPES.UPDATE_ITEM): {
      return {
        ...state,
        loadingUpdate: true,
        errorMessage: '',
        error: false,
      }
    }
    case FAILURE(ACTION_TYPES.FETCH_INCOMING_TRANSFERS): {
      return {
        ...state,
        incomingTransfers: {
          ...state.incomingTransfers,
          loading: false,
        },
      }
    }
    case FAILURE(ACTION_TYPES.FETCH_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ITEM):
    case FAILURE(ACTION_TYPES.CREATE_ITEM):
    case FAILURE(ACTION_TYPES.UPDATE_ITEM): {
      return {
        ...state,
        loading: false,
        loadingUpdate: false,
        errorMessage: action.payload.response.data?.errorText,
        error: true,
      }
    }
    case SUCCESS(ACTION_TYPES.FETCH_INCOMING_TRANSFERS): {
      return {
        ...state,
        incomingTransfers: {
          ...state.incomingTransfers,
          loading: false,
          list: action.payload.data,
        },
      }
    }
    case SUCCESS(ACTION_TYPES.FETCH_LIST): {
      const list =
        action.payload.data.page === 0
          ? action.payload.data.users
          : state.list.concat(action.payload.data.users)
      return {
        ...state,
        loading: false,
        list,
        totalItems: action.payload.data.totalElements,
        totalPages: action.payload.data.totalPages,
        activePage: action.payload.data.page,
        error: false,
      }
    }
    case SUCCESS(ACTION_TYPES.FETCH_ITEM): {
      return {
        ...state,
        item: action.payload.data,
        loading: false,
        error: false,
      }
    }
    case SUCCESS(ACTION_TYPES.CREATE_ITEM): {
      return {
        ...state,
        item: action.payload.data,
        loading: false,
        loadingUpdate: false,
        errorMessage: '',
        error: false,
      }
    }
    case SUCCESS(ACTION_TYPES.UPDATE_ITEM): {
      return {
        ...state,
        item: action.payload.data,
        loading: false,
        loadingUpdate: false,
        errorMessage: '',
        error: false,
      }
    }
    case ACTION_TYPES.UPDATE_FILTER: {
      return {
        ...state,
        list: [],
        filter: {
          ...initialState.filter,
          ...action.filter,
        },
      }
    }
    case ACTION_TYPES.CLEAR_FILTER: {
      return {
        ...state,
        list: [],
        filter: {
          ...initialState.filter,
        },
      }
    }
    case ACTION_TYPES.RESET_FILTER: {
      return {
        ...state,
        filter: {
          ...initialState.filter,
        },
      }
    }
    case ACTION_TYPES.RESET_LIST: {
      return {
        ...state,
        list: [],
        loading: false,
        loadingUpdate: false,
        totalItems: 0,
        totalPages: 0,
        activePage: START_PAGE,
        error: false,
      }
    }
    case ACTION_TYPES.RESET_ITEM: {
      return {
        ...state,
        item: {},
        loading: false,
        loadingUpdate: false,
        error: false,
      }
    }
    case ACTION_TYPES.UPDATE_INCOMING_TRANSFERS: {
      return {
        ...state,
        incomingTransfers: {
          ...state.incomingTransfers,
          ...action.data,
        },
      }
    }
    case ACTION_TYPES.RESET_LIST_INCOMING_TRANSFERS: {
      return {
        ...state,
        incomingTransfers: {
          ...state.incomingTransfers,
          list: [],
        },
      }
    }
    case ACTION_TYPES.RESET: {
      return {
        ...initialState,
      }
    }
    case ACTION_TYPES.RESET_ERROR: {
      return {
        ...state,
        errorMessage: '',
        error: false,
      }
    }
    default:
      return state
  }
}

const API_URL_USERS = `${SERVER}${API_URL}/users`

export const getUserList = (page = 1, pageSize = 20) => {
  const requestUrl = `${API_URL_USERS}?size=${pageSize}&page=${page}`
  return {
    type: ACTION_TYPES.FETCH_LIST,
    payload: axios.get(requestUrl),
  }
}

export const getUserListSearch = (dataRequest) => (dispatch, getState) => {
  const filter = getState().user.filter
  const requestUrl = `${API_URL_USERS}/search`

  dispatch({
    type: ACTION_TYPES.FETCH_LIST,
    payload: axios.post(requestUrl, {
      size: PAGE_SIZE,
      page: START_PAGE,
      lastName: checkParam(filter.lastName),
      firstName: checkParam(filter.firstName),
      middleName: checkParam(filter.middleName),
      phone: checkParam(filter.phone),
      email: checkParam(filter.email),
      clientId: checkParam(filter.clientId),
      passportNumber: checkParam(filter.passportNumber),
      applicationVersion: checkParam(filter.applicationVersion),
      platform: checkParam(filter.platform),
      identification: checkParam(filter.identification),
      identificationDateFrom: checkParam(getDateTimeToSubmit(filter.identificationDateFrom)),
      identificationDateTo: checkParam(getDateTimeToSubmit(filter.identificationDateTo)),
      registrationDateFrom: checkParam(getDateTimeToSubmit(filter.registrationDateFrom)),
      registrationDateTo: checkParam(getDateTimeToSubmit(filter.registrationDateTo)),
      enabled: filter.enabled ? JSON.parse(filter.enabled) : undefined,
      trusted: getBoolean(filter.trusted),
      ...dataRequest,
    }),
  })
}

export const getIncomingTransfers = (id, dataRequest) => {
  const requestUrl = `${API_URL_USERS}/${id}/idbankIncomingTransfers`
  return {
    type: ACTION_TYPES.FETCH_INCOMING_TRANSFERS,
    payload: axios.post(requestUrl, {
      limit: PAGE_SIZE,
      offset: START_PAGE,
      fromDate: '2021-06-22T14:52:19.957Z',
      toDate: '2021-07-22T14:52:19.957Z',
      ...dataRequest,
    }),
  }
}

export const getNextPage = () => (dispatch, getState) => {
  const activePage = getState().user.activePage
  dispatch(
    getUserListSearch({
      page: activePage + 1,
    })
  )
}

export const getUserItem = (id) => {
  const requestUrl = `${API_URL_USERS}/${id}`
  return {
    type: ACTION_TYPES.FETCH_ITEM,
    payload: axios.get(requestUrl),
  }
}

export const updateUserItem = (data) => {
  return {
    type: SUCCESS(ACTION_TYPES.UPDATE_ITEM),
    payload: { data },
  }
}

export const updateIncomingTransfers = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_INCOMING_TRANSFERS,
    data,
  }
}

export const updateFilter = (params) => async (dispatch) => {
  await dispatch({
    type: ACTION_TYPES.UPDATE_FILTER,
    filter: {
      ...params,
    },
  })
  dispatch(
    getUserListSearch({
      page: START_PAGE,
    })
  )
}

export const clearFilter = () => async (dispatch) => {
  await dispatch({
    type: ACTION_TYPES.CLEAR_FILTER,
  })
  dispatch(
    getUserListSearch({
      page: START_PAGE,
    })
  )
}

export const resetIncomingTransfersList = () => {
  return {
    type: ACTION_TYPES.RESET_LIST_INCOMING_TRANSFERS,
  }
}

export const resetFilter = () => {
  return {
    type: ACTION_TYPES.RESET_FILTER,
  }
}

export const resetUserList = () => {
  return {
    type: ACTION_TYPES.RESET_LIST,
  }
}

export const resetUserItem = () => {
  return {
    type: ACTION_TYPES.RESET_ITEM,
  }
}

export const resetError = () => {
  return {
    type: ACTION_TYPES.RESET_ERROR,
  }
}

export const reset = () => {
  return {
    type: ACTION_TYPES.RESET,
  }
}
