import React from 'react'
import { Box, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react'
import { InfoIcon } from '@chakra-ui/icons'
import { useWindowHeight } from 'chakra-lib'

export const PlugMobile = () => {
  const windowHeight = useWindowHeight()
  const themeBg = useColorModeValue('gray.50', 'gray.800')
  const themeBgContainer = useColorModeValue('white', 'gray.700')
  const themeBorderWidth = useColorModeValue('1px', 'none')
  return (
    <Box
      width="100%"
      height={`${windowHeight}px`}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      backgroundColor={themeBg}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        width="370px"
        backgroundColor={themeBgContainer}
        p="40px"
        borderRadius={8}
        borderWidth={themeBorderWidth}
      >
        <Icon as={InfoIcon} color="orange.300" mb="20px" />
        <Text>IDpay Admin не поддерживает мобильную версию</Text>
      </Flex>
    </Box>
  )
}
