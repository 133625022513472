import axios from 'axios'
import { SERVER } from 'config/api'

const ENTITY = 'banner'

export const banner = Object.freeze({
  async fetchList(data) {
    const response = await axios.post(`${SERVER}banners-service/api/v1/${ENTITY}`, data)
    return response.data
  },
  async fetchItem(id) {
    const response = await axios.get(`${SERVER}banners-service/api/v1/${ENTITY}/${id}`)
    return response.data
  },
  async deleteItem(id) {
    const response = await axios.delete(`${SERVER}banners-service/api/v1/${ENTITY}/${id}`)
    return response.data
  },
  async createItem(data) {
    const response = await axios.post(`${SERVER}banners-service/api/v1/${ENTITY}/add`, data)
    return response.data
  },
  async updateItem({ id, data }) {
    const response = await axios.put(`${SERVER}banners-service/api/v1/${ENTITY}/${id}`, data)
    return response.data
  },
})
