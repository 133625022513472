import React from 'react'
import { HStack } from '@chakra-ui/react'

export const NavigationMenu = ({ children, ...props }) => {
  return (
    <HStack spacing={2} pl="20px" pr="20px" justifySelf="center" {...props}>
      {children}
    </HStack>
  )
}
