import React from 'react'
import { Box, Text, useColorModeValue } from '@chakra-ui/react'

export const ItemText = ({ isCenter = null, isSmall = null, children, ...props }) => {
  const themeColorFontEmpty = useColorModeValue('gray.400', 'gray.600')

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={isCenter ? 'center' : 'start'}
      textOverflow="ellipsis"
      wordBreak="break-word"
      fontSize={isSmall ? '0.9rem' : '1rem'}
      lineHeight="1.4"
      {...props}
    >
      {children ? children : <Text color={themeColorFontEmpty}>Нет данных</Text>}
    </Box>
  )
}
