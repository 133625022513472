import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { Login } from '../Login'
import { useSelector } from 'react-redux'
import { SectionMenu, SectionMenuItem } from 'chakra-lib/layout'
import Catalog from './Catalog'
import { Transfer } from './Transfer'
import { Settings } from './Settings'
import { RewardDistribution } from './RewardDistribution'

const Routes = () => {
  const match = useRouteMatch()
  const isAuthenticated = useSelector((state) => state.authentication.isAuthenticated)
  return isAuthenticated ? (
    <>
      <SectionMenu>
        <SectionMenuItem colorScheme="teal" to="/referral/catalog">
          Каталог событий
        </SectionMenuItem>
        <SectionMenuItem colorScheme="teal" to="/referral/transfer">
          Переводы
        </SectionMenuItem>
        <SectionMenuItem colorScheme="teal" to="/referral/settings">
          Настройки
        </SectionMenuItem>
        <SectionMenuItem colorScheme="teal" to="/referral/rewardDistribution">
          Назначение наград
        </SectionMenuItem>
      </SectionMenu>
      <Switch>
        <Route path={`${match.url}/catalog`}>
          <Catalog />
        </Route>
        <Route path={`${match.url}/transfer`}>
          <Transfer />
        </Route>
        <Route path={`${match.url}/settings`}>
          <Settings />
        </Route>
        <Route path={`${match.url}/rewardDistribution`}>
          <RewardDistribution />
        </Route>
      </Switch>
    </>
  ) : (
    <Login />
  )
}

export default Routes
