import React from 'react'
import {
  Box,
  Button,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import useEkengSettingsFetch from './services/useEkengSettingsFetch'
import { Form } from 'chakra-lib'
import { Field, Formik } from 'formik'
import useEkengSettingsUpdate from './services/useEkengSettingsUpdate'
import { SwitchForm } from '../../../SwitchForm'

export const EkengSettings = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { getEkengSettings, isLoadingFetch, data } = useEkengSettingsFetch()
  const { updateEkengSettings, isLoadingUpdate } = useEkengSettingsUpdate()

  const handleOpen = async () => {
    onOpen()
    await getEkengSettings()
  }

  const handleForm = async (values) => {
    const dataRequest = {
      ...data,
      ...values,
    }
    await updateEkengSettings(dataRequest)
    onClose()
  }

  return (
    <>
      <MenuItem onClick={handleOpen}>Режим ЕКЕНГ</MenuItem>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Режим ЕКЕНГ</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              enableReinitialize
              initialValues={{
                automaticErrorHandling: data?.automaticErrorHandling,
              }}
              onSubmit={handleForm}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <Stack spacing={4}>
                    <Field
                      name="automaticErrorHandling"
                      label="Автоматический режим"
                      component={SwitchForm}
                    />
                  </Stack>
                  <Box pt="30px" pb="20px">
                    <Button
                      type="submit"
                      colorScheme="orange"
                      isLoading={isLoadingUpdate || isLoadingFetch}
                    >
                      Сохранить
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
