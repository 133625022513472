import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import useQuery from 'components/useQuery'
import { api } from 'api'
import { getUserItem } from 'store/user.reducer'
import { useDispatch } from 'react-redux'

const useApproveIdentification = (id) => {
  const toast = useToast()
  const dispatch = useDispatch()
  const { isError, isLoading, isSuccess, request } = useQuery((data) =>
    api.identifications.approveIdentification(id, data)
  )

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось изменить статус пользователя',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      dispatch(getUserItem(id))
      toast({
        title: 'Готово',
        description: 'Пользвательл успешно подтвержден',
        status: 'success',
      })
    }
  }, [isError])

  return {
    approve: request,
    isLoading,
    isSuccess,
  }
}

export default useApproveIdentification
