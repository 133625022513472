export const catalogEntryType = {
  ONETIME: 'ONETIME',
  SUM: 'SUM',
}

export const catalogEntryTypeTitle = {
  [catalogEntryType.ONETIME]: 'Единоразовый',
  [catalogEntryType.SUM]: 'Суммарный',
}

export const catalogEntryTypeOptions = Object.values(catalogEntryType).map((value) => ({
  label: catalogEntryTypeTitle[value],
  value,
}))