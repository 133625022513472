import React, { useCallback, useEffect } from 'react'
import {
  Box,
  Button,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  useBoolean,
  VStack,
} from '@chakra-ui/react'
import { useFetchProvidersSettings } from './hooks/useFetchProvidersSettings'
import { providersCountryOptions, providersTypeOptions } from './SmsProviders.constants'
import { useSaveProvidersSettings } from './hooks/useSaveProvidersSettings'

export const SmsProviders = () => {
  const [isOpen, setIsOpen] = useBoolean()
  const [providers, setProviders] = React.useState({})

  const { data, isLoading: settingsIsLoading } = useFetchProvidersSettings({ enabled: isOpen })
  const { mutate, isLoading: settingsIsSaving } = useSaveProvidersSettings()

  useEffect(() => {
    if (data) {
      setProviders({
        armenianProvider: data.armenianProvider,
        russianProvider: data.russianProvider,
      })
    }
  }, [data])

  const onSubmitHandle = useCallback(() => {
    mutate(providers)
  }, [providers])

  const onChangeProvider = useCallback((value) => {
    setProviders((state) => ({
      ...state,
      ...value,
    }))
  }, [])

  const formIsDisabled = settingsIsLoading | settingsIsSaving
  const availableProviders = data?.availableProviders || []

  return (
    <>
      <MenuItem onClick={setIsOpen.on}>SMS провайдеры</MenuItem>
      <Modal isOpen={isOpen} onClose={setIsOpen.off}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>SMS провайдеры</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Skeleton h="94px" isLoaded={!!data} borderRadius="5px">
              <VStack>
                <SimpleGrid w="full" columns={2}>
                  {providersTypeOptions.map(({ value, title }) =>
                    availableProviders.includes(value) ? (
                      <Text key={value} fontWeight={500}>{title}</Text>
                    ) : null
                  )}
                </SimpleGrid>

                {providersCountryOptions.map((country) => (
                  <RadioGroup
                    key={country.value}
                    size="lg"
                    w="full"
                    onChange={(value) => onChangeProvider({ [country.value]: value })}
                    value={providers[country.value]}
                  >
                    <Stack direction="row" justifyContent="space-around">
                      <SimpleGrid w="full" columns={2}>
                        {providersTypeOptions.map(({ value }) =>
                          availableProviders.includes(value) ? (
                            <Radio key={value} value={value} isDisabled={formIsDisabled}>
                              {country.title}
                            </Radio>
                          ) : null
                        )}
                      </SimpleGrid>
                    </Stack>
                  </RadioGroup>
                ))}
              </VStack>
            </Skeleton>
            <Box pt="30px" pb="20px">
              <Button
                onClick={onSubmitHandle}
                colorScheme="orange"
                isLoading={settingsIsLoading || settingsIsSaving}
              >
                Обновить
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
