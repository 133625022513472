import { FaArrowAltCircleRight } from 'react-icons/fa'
import { IoCard } from 'react-icons/io5'
import { Image } from '@chakra-ui/react'
import RA from './images/RA.png'
import RB from './images/RB.png'

export const PAYMENT_STATE = {
  ACCEPTED: 'ACCEPTED',
  PAY: 'PAY',
  ACQUIRING: 'ACQUIRING',
  FINISHED: 'FINISHED',
  REJECTED_BY_PROVIDER: 'REJECTED_BY_PROVIDER',
  ROLLBACK: 'ROLLBACK',
  ROLLBACK_WITH_REFUND: 'ROLLBACK_WITH_REFUND',
  NOT_ACQUIRED: 'NOT_ACQUIRED',
  MANUAL_CHECK: 'MANUAL_CHECK',
  UNKNOWN: 'UNKNOWN',
  NEED_CANCEL_PAYMENT_IN_SOVCOM: 'NEED_CANCEL_PAYMENT_IN_SOVCOM',
  PREPARED: 'PREPARED',
  PROCESSING: 'PROCESSING',
  APPROVED_BY_BANK: 'APPROVED_BY_BANK',
  REVERSING_BY_BANK_FAILED: 'REVERSING_BY_BANK_FAILED',
  PENDING_BANK_APPROVAL: 'PENDING_BANK_APPROVAL',
  APPROVED_BY_PC: 'APPROVED_BY_PC',
  INITIATED: 'INITIATED',
  CANCELED_BY_BANK: 'CANCELED_BY_BANK',
  REVERSE_REQUESTED: 'REVERSE_REQUESTED',
  CANCELING: 'CANCELING',
  REVERSING_BY_BANK: 'REVERSING_BY_BANK',
  REVERSED_BY_BANK: 'REVERSED_BY_BANK',
  REVERSED: 'REVERSED',
  CANCELING_BY_BANK: 'CANCELING_BY_BANK',
  CANCELING_BY_BANK_FAILED: 'CANCELING_BY_BANK_FAILED',
  CANCELING_FAILED: 'CANCELING_FAILED',
  MANUAL_SENDING_REQUIRED: 'MANUAL_SENDING_REQUIRED',
  FINISHED_WITH_ERRORS: 'FINISHED_WITH_ERRORS',
  ACQUIRING_CANCEL_ERROR: 'ACQUIRING_CANCEL_ERROR',
  NOT_ENOUGH_MONEY: 'NOT_ENOUGH_MONEY',
  NEW: 'NEW',
  LIMIT_EXCEEDED: 'LIMIT_EXCEEDED',
}

export const TYPE_PAYMENT_STATE_LABEL = {
  [PAYMENT_STATE.ACCEPTED]: 'Принят в обработку',
  [PAYMENT_STATE.PAY]: 'В обработке (банк)',
  [PAYMENT_STATE.ACQUIRING]: 'В обработке (процессинг)',
  [PAYMENT_STATE.FINISHED]: 'Проведен',
  [PAYMENT_STATE.REJECTED_BY_PROVIDER]: 'Отклонен (банк)',
  [PAYMENT_STATE.ROLLBACK]: 'Отменен',
  [PAYMENT_STATE.ROLLBACK_WITH_REFUND]: 'Отменен (с возвратом)',
  [PAYMENT_STATE.NOT_ACQUIRED]: 'Отклонен (процессинг)',
  [PAYMENT_STATE.MANUAL_CHECK]: 'На проверке',
  [PAYMENT_STATE.UNKNOWN]: 'Внутренняя ошибка',
  [PAYMENT_STATE.NEED_CANCEL_PAYMENT_IN_SOVCOM]: 'Ошибка проведения (банк)',
  [PAYMENT_STATE.PREPARED]: 'Предварительно одобренный',
  [PAYMENT_STATE.PROCESSING]: 'В обработке',
  [PAYMENT_STATE.APPROVED_BY_BANK]: 'Проведен',
  [PAYMENT_STATE.REVERSING_BY_BANK_FAILED]: 'Ошибка отмены (РБС)',
  [PAYMENT_STATE.PENDING_BANK_APPROVAL]: 'Ожидает подтверждения (РБС)',
  [PAYMENT_STATE.APPROVED_BY_PC]: 'Обработан (ПЦ)',
  [PAYMENT_STATE.INITIATED]: 'Не обрабатывался',
  [PAYMENT_STATE.CANCELED_BY_BANK]: 'Отклонен (РБС)',
  [PAYMENT_STATE.REVERSE_REQUESTED]: 'В обработке на отмену',
  [PAYMENT_STATE.CANCELING]: 'В обработке на отмену',
  [PAYMENT_STATE.REVERSING_BY_BANK]: 'В обработке на отмену (РБС)',
  [PAYMENT_STATE.REVERSED_BY_BANK]: 'В обработке на отмену (ПЦ)',
  [PAYMENT_STATE.REVERSED]: 'Отменен',
  [PAYMENT_STATE.CANCELING_BY_BANK]: 'Отменен (РБС)',
  [PAYMENT_STATE.CANCELING_BY_BANK_FAILED]: 'Ошибка отмены (РБС)',
  [PAYMENT_STATE.CANCELING_FAILED]: 'Ошибка отмены (ПЦ)',
  [PAYMENT_STATE.MANUAL_SENDING_REQUIRED]: 'Ручной разбор',
  [PAYMENT_STATE.FINISHED_WITH_ERRORS]: 'Проведен с ошибками',
  [PAYMENT_STATE.ACQUIRING_CANCEL_ERROR]: 'Не удалось вернуть деньги на карту',
  [PAYMENT_STATE.NOT_ENOUGH_MONEY]: 'Недостаточно средств',
  [PAYMENT_STATE.NEW]: 'Валидация расхождений',
  [PAYMENT_STATE.LIMIT_EXCEEDED]: 'Превышен лимит',
}

export const TYPE_PAYMENT_STATE_COLOR = {
  ACCEPTED: 'green',
  PAY: 'orange',
  ACQUIRING: 'orange',
  FINISHED: 'green',
  REJECTED_BY_PROVIDER: 'red',
  ROLLBACK: 'red',
  ROLLBACK_WITH_REFUND: 'red',
  NOT_ACQUIRED: 'red',
  MANUAL_CHECK: 'gray',
  UNKNOWN: 'red',
  NEED_CANCEL_PAYMENT_IN_SOVCOM: 'red',
  FINISHED_WITH_ERRORS: 'orange',
  PREPARED: 'orange',
  LIMIT_EXCEEDED: 'orange',
}

export const PAYMENT_TYPE = {
  WITHDRAW_TO_CARD: 'WITHDRAW_TO_CARD',
  RF_RA_TRANSFER_TO_CARD: 'RF_RA_TRANSFER_TO_CARD',
  RF_RA_TRANSFER: 'RF_RA_TRANSFER',
  RF_RB_TRANSFER_TO_CARD: 'RF_RB_TRANSFER_TO_CARD',
  RF_IDBANK_TRANSFER_TO_CARD: 'RF_IDBANK_TRANSFER_TO_CARD',
  UNDEFINED_TYPE: 'UNDEFINED_TYPE',
}

export const TYPE_PAYMENT_LABEL = {
  [PAYMENT_TYPE.WITHDRAW_TO_CARD]: 'Перевод на карту',
  [PAYMENT_TYPE.RF_RA_TRANSFER_TO_CARD]: 'Перевод на карту РА',
  [PAYMENT_TYPE.RF_RA_TRANSFER]: 'Переводы из РФ в РА',
  [PAYMENT_TYPE.RF_RB_TRANSFER_TO_CARD]: 'Переводы из РФ в РБ',
  [PAYMENT_TYPE.RF_IDBANK_TRANSFER_TO_CARD]: 'Платежи по карте в IDBank',
}

export const TYPE_PAYMENT_ICON = {
  [PAYMENT_TYPE.RF_RA_TRANSFER]: FaArrowAltCircleRight,
  [PAYMENT_TYPE.UNDEFINED_TYPE]: IoCard,
  [PAYMENT_TYPE.WITHDRAW_TO_CARD]: IoCard,
  [PAYMENT_TYPE.RF_RA_TRANSFER_TO_CARD]: IoCard,
  [PAYMENT_TYPE.RF_RB_TRANSFER_TO_CARD]: IoCard,
  [PAYMENT_TYPE.RF_IDBANK_TRANSFER_TO_CARD]: IoCard,
}

export const TYPE_PAYMENT_COLOR = {
  [PAYMENT_TYPE.RF_RA_TRANSFER]: 'green.400',
  [PAYMENT_TYPE.UNDEFINED_TYPE]: 'orange.400',
  [PAYMENT_TYPE.WITHDRAW_TO_CARD]: 'orange.400',
  [PAYMENT_TYPE.RF_RA_TRANSFER_TO_CARD]: 'green.400',
  [PAYMENT_TYPE.RF_RB_TRANSFER_TO_CARD]: 'green.400',
  [PAYMENT_TYPE.RF_IDBANK_TRANSFER_TO_CARD]: 'green.400',
}

export const TYPE_PAYMENT_BADGE = {
  [PAYMENT_TYPE.RF_RA_TRANSFER]: <Image src={RA} />,
  [PAYMENT_TYPE.RF_RA_TRANSFER_TO_CARD]: <Image src={RA} />,
  [PAYMENT_TYPE.RF_RB_TRANSFER_TO_CARD]: <Image src={RB} />,
  [PAYMENT_TYPE.RF_IDBANK_TRANSFER_TO_CARD]: <Image src={RA} />,
}

export const TYPE_PAYMENT_SOURCE_LABEL = {
  CARD: 'Карта',
  ACCOUNT: 'Аккаунт',
  UNDEFINED_TYPE: 'Неопределен',
}
