export const formFields = {
  accountNumber: 'accountNumber',
  contractNumber: 'contractNumber',
  clientId: 'clientId',
}

export const formFieldsLabel = {
  [formFields.accountNumber]: 'Номер счета',
  [formFields.contractNumber]: 'Номер договора',
  [formFields.clientId]: 'ClientID',
}

