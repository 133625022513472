import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import useQuery from 'components/useQuery'
import { api } from 'api'

const useUpdateBannerItem = () => {
  const toast = useToast()
  const { isError, isLoading, isSuccess, request } = useQuery((data) => api.banner.updateItem(data))

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось обновить данные',
        status: 'error',
      })
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Готово',
        description: 'Баннер успешно обновлен',
        status: 'success',
      })
    }
  }, [isSuccess])

  return {
    updateItem: request,
    isLoadingUpdateItem: isLoading,
  }
}

export default useUpdateBannerItem
