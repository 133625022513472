import { Box, Button } from '@chakra-ui/react'
import { InputDateForm } from 'chakra-lib/InputDateForm'
import { Form } from 'chakra-lib/layout'
import { Field, Formik } from 'formik'
import moment from 'moment'
import { useCallback } from 'react'
import { useFetchPaymentsRegistry } from '../hooks/useFetchPaymentsRegistry'

export const RuForm = () => {
  const { mutate: sendRegistry, isLoading } = useFetchPaymentsRegistry()

  const onSubmitHandle = useCallback((values) => {
    sendRegistry(values)
  }, [])

  return (
    <Formik enableReinitialize initialValues={{}} onSubmit={onSubmitHandle}>
      {(props) => (
        <Form>
          <Field
            isDisabled={isLoading}
            name="date"
            label="Дата"
            placeholder="Выберите дату"
            component={InputDateForm}
            autoComplete="off"
            dayPickerProps={{
              disabledDays: {
                after: moment().add(-1, 'd').toDate(),
              },
            }}
          />

          <Box pt="40px">
            <Button onClick={props.handleSubmit} colorScheme="orange" isLoading={isLoading}>
              Отправить РА реестры
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
