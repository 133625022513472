import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import { api } from 'api'
import useQuery from 'components/useQuery'

const useUploadScans = () => {
  const toast = useToast()
  const { isError, isLoading, isSuccess, request } = useQuery((e) => api.scans.uploadItem(e))

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось загрузить данные',
        status: 'error',
      })
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Готово',
        description: 'Фотография успешно добавлена',
        status: 'success',
      })
    }
  }, [isSuccess])

  return {
    uploadScans: request,
    isLoading,
    isSuccess,
  }
}

export default useUploadScans
