import { Button } from '@chakra-ui/react'
import { InputSearch } from 'chakra-lib'
import { useEffect, useRef, useState } from 'react'

export const ListFilter = ({ onChange }) => {
  const [state, setState] = useState({})
  const prevState = useRef(state)

  useEffect(() => {
    if (state !== prevState.current) {
      onChange(state)
      prevState.current = state
    }
  }, [state])

  const handleFilter = (value) => {
    setState((state) => ({ ...state, ...value }))
  }

  const handleResetFilter = () => {
    setState({})
  }

  return (
    <>
      <InputSearch
        hideIcon
        label="Номер карты/телефона"
        value={state.subject}
        onChange={(value) => handleFilter({ subject: value })}
      />
      <Button size="sm" width="full" onClick={handleResetFilter}>
        Очистить фильтр
      </Button>
    </>
  )
}
