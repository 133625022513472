import { useToast } from '@chakra-ui/react'
import { api } from 'api'
import { useMutation } from 'react-query'
import { useDispatch } from 'react-redux'
import { getUserItem } from 'store/user.reducer'

export const useRemoveUser = (id) => {
  const toast = useToast()
  const dispatch = useDispatch()

  return useMutation((data) => api.users.setUserDisable(id, data), {
    onError: () => {
      toast({
        title: 'Ошибка',
        description: 'Не удалось изменить статус пользователя',
        status: 'error',
      })
    },
    onSuccess: () => {
      toast({
        title: 'Готово',
        description: 'Пользователь успешно заблокирован',
        status: 'success',
      })
      dispatch(getUserItem(id))
    },
  })
}
