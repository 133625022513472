import { Box, Button, useBoolean } from '@chakra-ui/react'
import { Form, Modal, TextAreaForm } from 'chakra-lib'
import * as Yup from 'yup'
import { useEnableUser } from './services/useEnableUser'
import { Field, Formik } from 'formik'

const validationSchema = Yup.object({
  userComment: Yup.string().required('Обязательное поле'),
})

export const ActivateUser = ({ userId }) => {
  const { mutate, isLoading } = useEnableUser(userId)
  const [isOpen, setOpenModal] = useBoolean()

  const onSubmitHandle = (values) => {
    mutate(values)
  }

  return (
    <>
      <Button
        width="100%"
        onClick={setOpenModal.on}
        colorScheme="blue"
        variant="outline"
        isLoading={isLoading}
      >
        Активировать
      </Button>
      <Modal isOpen={isOpen} onClose={setOpenModal.off} title="Активировать пользователя">
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{}}
          validationSchema={validationSchema}
          onSubmit={onSubmitHandle}
        >
          {({ handleSubmit, isValid }) => (
            <Form w="full">
              <Field
                isDisabled={isLoading}
                name="userComment"
                placeholder="Введите комментарий"
                component={TextAreaForm}
              />
              <Box pt="30px">
                <Button
                  colorScheme="orange"
                  isLoading={isLoading}
                  onClick={handleSubmit}
                  isDisabled={!isValid}
                >
                  Активировать
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}
