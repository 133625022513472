import React from 'react'
import { Box, Flex, useColorModeValue } from '@chakra-ui/react'
import { Link, useLocation } from 'react-router-dom'

const COLOR_TITLE_SELECTED = {
  orange: {
    light: 'gray.800',
    dark: 'orange.300',
  },
  blue: {
    light: 'gray.800',
    dark: 'blue.300',
  },
  teal: {
    light: 'gray.800',
    dark: 'teal.300',
  },
}

export const SectionMenuItem = ({ to, children, colorScheme = 'blue', ...props }) => {
  const themeColorFontTitleSelected = useColorModeValue(
    COLOR_TITLE_SELECTED[colorScheme].light,
    COLOR_TITLE_SELECTED[colorScheme].dark
  )
  const themeColorFontTitle = useColorModeValue('gray.500', 'gray.500')
  const location = useLocation()
  const { pathname } = location
  const isSelected = pathname.includes(to)
  return (
    <Link to={to}>
      <Flex
        mr="10px"
        alignItems="center"
        fontSize="0.9rem"
        pl="10px"
        pr="10px"
        role="group"
        position="relative"
        height="32px"
        lineHeight={1}
        whiteSpace="nowrap"
        overflow="hidden"
        transition="0.2s"
        cursor="pointer"
        _focus={{
          outline: 'none',
          boxShadow: 'outline',
          borderColor: 'gray.300',
        }}
        _hover={{
          opacity: '0.8',
        }}
        {...props}
      >
        <Box
          fontWeight="500"
          transition="0.2s"
          color={isSelected ? themeColorFontTitleSelected : themeColorFontTitle}
          flex={1}
        >
          {children}
        </Box>
      </Flex>
    </Link>
  )
}
