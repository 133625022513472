import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import useQuery from 'components/useQuery'
import { api } from 'api'

const useFetchReferralTransfer = () => {
  const toast = useToast()
  const { isError, isLoading, response, request } = useQuery((data) =>
    api.referral.fetchTransfer(data)
  )

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось получить данные',
        status: 'error',
      })
    }
  }, [isError])

  return {
    data: response,
    getReferralTransfer: request,
    isLoading,
  }
}

export default useFetchReferralTransfer
