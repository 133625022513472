import { api } from 'api'
import { useMutation, useQueryClient } from 'react-query'
import toast from 'utils/toast'

export const useFaqRemove = (id, options) => {
  const queryClient = useQueryClient()

  return useMutation(
    () => {
      return api.faq.remove(id)
    },
    {
      onSuccess: () => {
        if (options?.onSuccess) {
          options?.onSuccess()
        }
        queryClient.invalidateQueries(['faqList'])

        toast.showSuccess({
          title: 'Элемент удален успешно',
        })
      },
      onError: (err) => {
        toast.showError({
          title: 'Произошла ошибка при попытке удален элемент',
          description: err.response?.data?.message || err.message,
        })
      },
    }
  )
}
