import axios from 'axios'
import { API_URL, SERVER } from 'config/api'

const ENTITY = 'ekeng'

export const ekeng = Object.freeze({
  async fetchSettings() {
    const response = await axios.get(`${SERVER}${API_URL}/${ENTITY}/settings`)
    return response.data
  },
  async updateSettings(id, data) {
    const response = await axios.put(`${SERVER}${API_URL}/${ENTITY}/settings/${id}`, data)
    return response.data
  },
})
