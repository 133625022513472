import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import { api } from 'api'
import useQuery from 'components/useQuery'

const useCreateCatalogItem = () => {
  const toast = useToast()
  const { isError, isLoading, isSuccess, response, request } = useQuery((data) =>
    api.referral.createCatalogItem(data)
  )

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось получить данные',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Готово',
        description: 'Событие успешно создано',
        status: 'success',
      })
    }
  }, [isSuccess])

  return {
    createItem: request,
    isLoadingCreateItem: isLoading,
    isSuccessCreateItem: isSuccess,
    dataCreate: response,
  }
}

export default useCreateCatalogItem
