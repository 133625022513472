import React from 'react'
import { Button, useDisclosure } from '@chakra-ui/react'
import { Form, Modal, TextAreaForm } from 'chakra-lib'
import useCompletePayment from './services/useCompletePayment'
import { useDispatch } from 'react-redux'
import { getPaymentsItem } from 'store/payments.reducer'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { formFields } from './CompletePaymentModal.constants'

export const validationSchema = Yup.object().shape({
  [formFields.retryComment]: Yup.string()
    .matches(/\S/, 'Только пробелы не допустимы')
    .min(3, 'Минимум 3 символа')
    .required('Комментарий обязательное поле'),
})

export const CompletePaymentModal = ({ id }) => {
  const dispatch = useDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { competePayment, isLoading } = useCompletePayment(id)

  const handleSubmit = async (values) => {
    await competePayment(values)
    dispatch(getPaymentsItem(id))
    onClose()
  }

  return (
    <>
      <Button width="100%" onClick={onOpen} colorScheme="green">
        Провести
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="sm" title="Проведение платежа">
        <Formik
          initialValues={{}}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(formProps) => {
            return (
              <Form
                footer={
                  <Button
                    colorScheme="orange"
                    isLoading={isLoading}
                    onClick={() => formProps.handleSubmit()}
                  >
                    Подтвердить
                  </Button>
                }
              >
                <Field
                  component={TextAreaForm}
                  name={formFields.retryComment}
                  placeholder="Введите комментарий"
                />
              </Form>
            )
          }}
        </Formik>
      </Modal>
    </>
  )
}

