import axios from 'axios'
import { API_URL, SERVER } from 'config/api'

const ENTITY = 'identifications'

export const identifications = Object.freeze({
  async fetchList(id) {
    const response = await axios.get(
      `${SERVER}${API_URL}/${ENTITY}/${id}/list?size=${1000}&page=${0}`
    )
    return response.data
  },
  async approveIdentification(id, data) {
    const response = await axios.post(
      `${SERVER}${API_URL}/${ENTITY}/${id}/approveIdentification`,
      data
    )
    return response.data
  },
  async declineIdentification(id, data) {
    const response = await axios.post(
      `${SERVER}${API_URL}/${ENTITY}/${id}/declineIdentification`,
      data
    )
    return response.data
  },
  async resetIdentification(id) {
    const response = await axios.post(`${SERVER}${API_URL}/${ENTITY}/${id}/reset`)
    return response.data
  },
  async changeIdentification(id, data) {
    const response = await axios.post(
      `${SERVER}${API_URL}/${ENTITY}/${id}/changeIdentification`,
      data
    )
    return response.data
  },
})
