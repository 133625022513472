import { api } from 'api'
import { useDispatch } from 'react-redux'
import { useMutation } from 'react-query'
import toast from 'utils/toast'
import { getPaymentsItem } from 'store/payments.reducer'

export const useFinishPayment = (id) => {
  const dispatch = useDispatch()

  return useMutation((data) => api.payments.finishPayment(id, data), {
    onError: (err) => {
      toast.showError({
        title: 'Произошла ошибка при завершении платежа',
        description: err.response?.data?.message || err.message,
      })
    },
    onSuccess: () => {
      dispatch(getPaymentsItem(id))
    },
  })
}
