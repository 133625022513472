import { Box, Button, Text, useBoolean } from '@chakra-ui/react'
import { Modal } from 'chakra-lib'
import { useResetUserIdentification } from './services/useResetUserIdentification'

export const ResetUserIdentification = ({ userId, isDisabled }) => {
  const { mutate, isLoading } = useResetUserIdentification(userId)
  const [isOpen, setOpenModal] = useBoolean()

  const handleSubmit = () => {
    mutate()
    setOpenModal.off()
  }

  return (
    <>
      <Button
        width="100%"
        onClick={setOpenModal.on}
        colorScheme="orange"
        isLoading={isLoading}
        isDisabled={isDisabled}
      >
        Сбросить
      </Button>
      <Modal isOpen={isOpen} onClose={setOpenModal.off} title="Сбросить идентификацию">
        <Text>Статус идентификации будет сброшен</Text>
        <Box pt="30px">
          <Button colorScheme="orange" isLoading={isLoading} onClick={handleSubmit}>
            Продолжить
          </Button>
        </Box>
      </Modal>
    </>
  )
}
