export const userIdentification = {
  FAILED_DUE_BLACKLISTED: 'FAILED_DUE_BLACKLISTED',
  FAILED_DUE_INVALID_PASSPORT: 'FAILED_DUE_INVALID_PASSPORT',
  FULL: 'FULL',
  FULL_DOSSIER: 'FULL_DOSSIER',
  READY: 'READY',
  UNIDENTIFIED: 'UNIDENTIFIED',
  FAILED: 'FAILED',
  SUPPORT_REVIEW: 'SUPPORT_REVIEW',
}

export const userIdentificationLabel = {
  [userIdentification.FAILED_DUE_BLACKLISTED]: 'В черном списке',
  [userIdentification.FAILED_DUE_INVALID_PASSPORT]: 'Невалидный паспорт',
  [userIdentification.FULL]: 'Упрощенная идентификация',
  [userIdentification.FULL_DOSSIER]: 'Полная идентификация',
  [userIdentification.READY]: 'В ожидании',
  [userIdentification.UNIDENTIFIED]: 'Нет',
  [userIdentification.FAILED]: 'Ошибка',
  [userIdentification.SUPPORT_REVIEW]: 'Проверка данных',
}

export const userIdentificationColor = {
  [userIdentification.FULL]: 'purple',
  [userIdentification.FULL_DOSSIER]: 'green',
  [userIdentification.UNIDENTIFIED]: 'gray',
  [userIdentification.READY]: 'gray',
  [userIdentification.FAILED]: 'red',
  [userIdentification.SUPPORT_REVIEW]: 'orange',
}

export const TYPE_USER_COUNTRY = {
  RUSSIA: 'Россия',
  ARMENIA: 'Армения',
}

export const TYPE_USER_PLATFORM = {
  ANDROID: 'Android',
  IPHONE: 'iPhone',
}

export const ROLES = {
  user: 'USER',
  admin: 'ADMIN',
  marketing: 'MARKETING',
}

export const ROLES_LABEL = {
  [ROLES.user]: 'Пользователь',
  [ROLES.admin]: 'Администратор',
  [ROLES.marketing]: 'Маркетинг',
}

export const typeStatus = {
  active: 'active',
  deactivated: 'deactivated',
  blocked: 'blocked',
}

export const typeStatusTitle = {
  active: 'Активен',
  deactivated: 'Деактивирован',
  blocked: 'Заблокирован',
}

export const typeStatusOptions = [typeStatus.active, typeStatus.deactivated].map((item) => ({
  value: item === typeStatus.active,
  label: typeStatusTitle[item],
}))

export const getTypeStatus = ({ email, enabled }) => {
  if (email?.includes('_blocked')) {
    return typeStatus.blocked
  } else if (enabled) {
    return typeStatus.active
  } else {
    return typeStatus.deactivated
  }
}
export const getTypeStatusTitle = (item) => {
  return typeStatusTitle[getTypeStatus(item)]
}

export const TYPE_ENABLED_COLOR = {
  true: 'blue',
  false: 'red',
}
