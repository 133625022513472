import useQuery from 'components/useQuery'
import { api } from 'api'

const useUpdateSettings = () => {
  const { request } = useQuery((data) => api.referral.updateSettings(data))

  return {
    updateSettings: request,
  }
}

export default useUpdateSettings
