import { MenuItem, useBoolean } from '@chakra-ui/react'
import { Modal, Tab, TabList, TabPanel, TabPanels, Tabs } from 'chakra-lib'
import { RbForm } from './RbForm'
import { RuForm } from './RuForm'

export const Registry = () => {
  const [isOpen, setIsOpen] = useBoolean()

  return (
    <>
      <MenuItem onClick={setIsOpen.on}>Отправка реестров</MenuItem>
      <Modal isOpen={isOpen} onClose={setIsOpen.off} title="Отправка реестров">
        <Tabs variant="soft-rounded" colorScheme="orange">
          <TabList>
            <Tab>РА</Tab>
            <Tab>РБ</Tab>
          </TabList>
          <TabPanels pt={0}>
            <TabPanel pb={0}>
              <RuForm />
            </TabPanel>
            <TabPanel pb={0}>
              <RbForm />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Modal>
    </>
  )
}
