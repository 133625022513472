import React from 'react'
import { Button } from '@chakra-ui/react'
import { useFetchData } from './useFetchData'

export const ExportExcel = ({ size }) => {
  const { getData, isLoading } = useFetchData(size)
  return (
    <Button isLoading={isLoading} size="sm" width="100%" onClick={getData}>
      Выгрузить Excel
    </Button>
  )
}
