import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import { api } from 'api'
import useQuery from 'components/useQuery'

const useRoleMappingsFetch = (id) => {
  const toast = useToast()
  const { isError, response, request } = useQuery(() => api.keycloak.fetchUserRoleMappings(id))

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось получить данные',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [isError])

  useEffect(() => {
    request()
  }, [])

  return {
    data: response?.data,
    fetchRoleMappingItem: request,
  }
}

export default useRoleMappingsFetch
