import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn,
  Form,
  FormSection,
} from 'chakra-lib/layout'
import { Modal, SelectForm, TextAreaForm } from 'chakra-lib'
import { Field, Formik } from 'formik'
import { Box, Button, Center, SimpleGrid, useBoolean } from '@chakra-ui/react'
import * as Yup from 'yup'
import { useUpdateRewards } from './services/useUpdateRewards'
import React, { useMemo } from 'react'
import useFetchReferralCatalog from '../Catalog/List/services/useFetchReferralCatalog'

const formSchema = Yup.object().shape({
  eventCode: Yup.string().required('Событие обязательное поле'),
  phones: Yup.string().required('Номера телефонов обязательное поле'),
})

export const RewardDistribution = () => {
  const [resultIsOpen, setOpenResult] = useBoolean()

  const { data: eventsList } = useFetchReferralCatalog()

  const {
    mutate: updateRewards,
    data,
    isLoading,
  } = useUpdateRewards({
    onSuccess: () => {
      setOpenResult.on()
    },
  })

  const handleForm = ({ phones, eventCode }) => {
    const phone = phones
      .split('\n')
      .map((item) => item.trim())
      .filter((item) => !!item)

    updateRewards({
      eventCode,
      phone,
    })
  }

  const eventsOptions = useMemo(() => {
    return eventsList
      ?.filter((item) => !item?.hidden)
      .map((item) => ({
        label: item.description,
        value: item.eventCode,
      }))
  }, [eventsList])

  return (
    <Container>
      <CardContainer indentTop={120} isHideBack>
        <ContentContainer>
          <ContentContainerColumn>
            <Formik
              initialValues={{}}
              enableReinitialize
              onSubmit={handleForm}
              validationSchema={formSchema}
            >
              {(props) => (
                <Form
                  onSubmit={props.handleSubmit}
                  footer={
                    <Button type="submit" colorScheme="orange" isLoading={isLoading}>
                      Отправить
                    </Button>
                  }
                >
                  <FormSection>
                    <Field
                      name="eventCode"
                      component={SelectForm}
                      label="Событие"
                      placeholder="Укажите событие"
                      isDisabled={isLoading}
                      options={eventsOptions}
                    />
                    <Field
                      name="phones"
                      component={TextAreaForm}
                      label="Введите номера телефонов"
                      isDisabled={isLoading}
                      placeholder={
                        '7932337751\n7932337752\n7932337753\n7932337754\n7932337755\n...'
                      }
                      rows={30}
                    />
                  </FormSection>
                </Form>
              )}
            </Formik>
          </ContentContainerColumn>
        </ContentContainer>
      </CardContainer>

      <Modal
        isOpen={resultIsOpen}
        onClose={setOpenResult.off}
        title="Результат"
        scrollBehavior="inside"
        footer={
          <Button colorScheme="orange" onClick={setOpenResult.off}>
            Закрыть
          </Button>
        }
      >
        <Box minH="200px">
          {!!data && data.length !== 0 ? (
            <SimpleGrid columns={2} spacing={1}>
              <Box fontWeight="bold">Номер</Box>
              <Box fontWeight="bold">Бонус</Box>
              {data.map((item) => (
                <>
                  <Box>{item.customerId}</Box>
                  <Box>{item.reward}</Box>
                </>
              ))}
            </SimpleGrid>
          ) : (
            <Center>Список пуст</Center>
          )}
        </Box>
      </Modal>
    </Container>
  )
}
