import React from 'react'
import { Button, useBoolean } from '@chakra-ui/react'
import { useFinishPayment } from './services/useFinishPayment'
import { Form, Modal, TextAreaForm } from 'chakra-lib'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { formFields } from './FinishedPaymentModal.constants'

export const validationSchema = Yup.object().shape({
  [formFields.retryComment]: Yup.string()
    .matches(/\S/, 'Только пробелы не допустимы')
    .min(3, 'Минимум 3 символа')
    .required('Комментарий обязательное поле'),
})

export const FinishedPaymentModal = ({ id }) => {
  const { mutate: finishPayment, isLoading } = useFinishPayment(id)
  const [isOpen, setOpenModal] = useBoolean()

  const handleSubmit = async (values) => {
    finishPayment(values)
    setOpenModal.off()
  }

  return (
    <>
      <Button width="100%" onClick={setOpenModal.on} colorScheme="orange" isLoading={isLoading}>
        Завершить
      </Button>
      <Modal isOpen={isOpen} onClose={setOpenModal.off} title="Завершить платеж">
        <Formik
          initialValues={{}}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(formProps) => {
            return (
              <Form
                footer={
                  <Button
                    colorScheme="orange"
                    isLoading={isLoading}
                    onClick={() => formProps.handleSubmit()}
                  >
                    Завершить
                  </Button>
                }
              >
                <Field
                  component={TextAreaForm}
                  name={formFields.retryComment}
                  placeholder="Укажите кем и когда иницируется завершение платежа"
                />
              </Form>
            )
          }}
        </Formik>
      </Modal>
    </>
  )
}
