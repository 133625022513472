import { useState } from 'react'
import { api } from 'api'
import { useInfiniteQuery } from 'react-query'
import toast from 'utils/toast'

const useFetchReportsList = (initialParams) => {
  const [params, setParams] = useState(initialParams)

  let list = []
  let lastPage

  const query = useInfiniteQuery(
    ['reportsList', params],
    ({ pageParam = 0 }) => {
      return api.payments.fetchReports({
        ...params,
        page: pageParam,
        size: 20,
      })
    },
    {
      getNextPageParam: ({ page }) => page + 1,
      keepPreviousData: true,
      onError: (err) => {
        toast.showError({
          title: 'Произошла ошибка при загрузки отчетов',
          description: err.message,
        })
      },
    }
  )

  const registryHistory = query.data?.pages

  if (registryHistory) {
    const content = query.data.pages.reduce((acc, item) => [...acc, ...item.registryHistory], [])
    list = content?.length ? content : []
    lastPage = query.data.pages[query.data.pages.length - 1]
  }

  return {
    fetch: setParams,
    list,
    hasMore: lastPage?.page < lastPage?.totalPages - 1,
    lastPage,
    ...query,
  }
}

export default useFetchReportsList
