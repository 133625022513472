import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import { api } from 'api'
import useQuery from 'components/useQuery'

const useUserItemFetch = (id) => {
  const toast = useToast()
  const { isError, isLoading, isSuccess, response, request } = useQuery(() =>
    api.keycloak.fetchUserItem(id)
  )

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось получить данные',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [isError])

  useEffect(() => {
    if (id) {
      request()
    }
  }, [])

  return {
    data: response?.data,
    fetchItem: request,
    isLoadingFetchItem: isLoading,
    isSuccessFetchItem: isSuccess,
  }
}

export default useUserItemFetch
