import useQuery from 'components/useQuery'
import { api } from 'api'

const useFetchCustomerRelatedBy = () => {
  const { isLoading, response, request } = useQuery((id) => api.referral.fetchCustomerRelatedBy(id))

  return {
    fetchCustomerRelatedBy: request,
    dataCustomerRelatedBy: response,
    isLoadingCustomerRelatedBy: isLoading,
  }
}

export default useFetchCustomerRelatedBy
