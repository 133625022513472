import useQuery from 'components/useQuery'
import { api } from 'api'

const useFetchCustomer = (id) => {
  const { isLoading, response, request } = useQuery(() => api.referral.fetchCustomerItem(id))

  return {
    fetchCustomer: request,
    dataCustomer: response,
    isLoadingCustomer: isLoading,
  }
}

export default useFetchCustomer
