import React, { useState } from 'react'
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { TextArea } from 'chakra-lib'
import useApproveIdentification from './services/useApproveIdentification'
import { getUserItem } from 'store/user.reducer'
import { useDispatch } from 'react-redux'

export const ApproveIdentificationModal = ({ userId }) => {
  const dispatch = useDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { approve, isLoading } = useApproveIdentification(userId)
  const [commentValue, setCommentValue] = useState('')

  const handleSubmit = async () => {
    const dataRequest = {
      identificationSupportComment: commentValue,
    }
    await approve(dataRequest)
    await dispatch(getUserItem(userId))
    onClose()
  }

  return (
    <>
      <Button width="100%" onClick={onOpen} colorScheme="green">
        Подтвердить
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Подтверждение идентификации</ModalHeader>
          <ModalCloseButton mt={1} />
          <ModalBody>
            <TextArea
              value={commentValue}
              onChange={(e) => setCommentValue(e.target.value)}
              placeholder="Введите комментарий"
            />
            <Box pt="30px" pb="20px">
              <Button
                colorScheme="orange"
                isLoading={isLoading}
                onClick={handleSubmit}
                isDisabled={commentValue === ''}
              >
                Подтвердить
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
