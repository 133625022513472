import { Button } from '@chakra-ui/react'
import { Snippets, SnippetsHeading, SnippetsItem, SnippetsList } from 'chakra-lib'
import {
  Container,
  FilterHeader,
  FilterStack,
  SnippetsContainer,
  SnippetsContainerMain,
  SnippetsContainerRightAside,
} from 'chakra-lib/layout'
import { HeadingItem, ItemIcon, ItemText } from 'chakra-lib/Snippets'
import { IoDocument } from 'react-icons/io5'
import { useHistory } from 'react-router-dom'
import { useFetchBlackList } from './services/useFetchBlackList'
import { ListFilter } from './ListFilter'
import { useCallback, useRef } from 'react'
import { ROLES } from 'model/user.model'
import { useSelector } from 'react-redux'
import { getAccess } from 'components/Private'
import { getDateTimeReadFormat } from 'utils/dateFormatting'

export const List = () => {
  const history = useHistory()
  const { list, hasMore, fetchNextPage, isLoading, fetch } = useFetchBlackList()
  const filtersRef = useRef()
  const user = useSelector((state) => state.authentication.user)
  const isAdmin = getAccess(user.roles, [ROLES.admin])

  const onChangeFilters = useCallback((state) => {
    filtersRef.current = state
    const { subject } = state

    fetch({ subject })
  }, [])

  return (
    <Container>
      <SnippetsContainer indentTop={122}>
        <SnippetsContainerMain>
          <Snippets columns="42px minmax(170px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr)">
            <SnippetsHeading position="sticky" top="0" zIndex={3}>
              <HeadingItem />
              <HeadingItem>Номер карты/телефона</HeadingItem>
              <HeadingItem>Комментарий </HeadingItem>
              <HeadingItem>Создан </HeadingItem>
            </SnippetsHeading>
            <SnippetsList
              data={list}
              isLoading={isLoading}
              hasMore={hasMore}
              onNext={() => fetchNextPage()}
            >
              {(item) => (
                <SnippetsItem>
                  <ItemIcon icon={IoDocument} />
                  <ItemText>{item?.subject}</ItemText>
                  <ItemText>{item?.description}</ItemText>
                  <ItemText>{getDateTimeReadFormat(item?.created)}</ItemText>
                </SnippetsItem>
              )}
            </SnippetsList>
          </Snippets>
        </SnippetsContainerMain>
        <SnippetsContainerRightAside>
          <FilterHeader>
            <Button
              width="100%"
              onClick={() => history.push(`/services/black-list/new`)}
              colorScheme="orange"
              isDisabled={!isAdmin}
            >
              Добавить
            </Button>
          </FilterHeader>
          <FilterStack>
            <ListFilter onChange={onChangeFilters} />
          </FilterStack>
        </SnippetsContainerRightAside>
      </SnippetsContainer>
    </Container>
  )
}
