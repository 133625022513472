import React from 'react'
import {
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { updateIncomingTransfers } from 'store/user.reducer'

export const LimitPopup = () => {
  const dispatch = useDispatch()
  const { limit } = useSelector((state) => state.user.incomingTransfers)
  const handleSlider = (e) => {
    dispatch(
      updateIncomingTransfers({
        limit: e,
      })
    )
  }
  return (
    <>
      <Popover>
        <PopoverTrigger>
          <Button width="86px" size="sm">
            {limit} шт.
          </Button>
        </PopoverTrigger>
        <PopoverContent
          pt="8px"
          pl="20px"
          pr="20px"
          borderWidth={0}
          boxShadow="rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.1) 0px 9px 24px"
          _focus={{
            boxShadow:
              'rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.1) 0px 9px 24px',
          }}
        >
          <PopoverArrow />
          <PopoverBody>
            <Slider defaultValue={limit} min={20} max={300} step={20} onChange={handleSlider}>
              <SliderTrack>
                <Box position="relative" right={10} />
                <SliderFilledTrack bg="orange.400" />
              </SliderTrack>
              <SliderThumb boxSize={6} />
            </Slider>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  )
}
