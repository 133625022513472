import exceljs from 'exceljs'
import fs from 'file-saver'

export const exportExcelFile = (data, { sheetName, fileName, columns }) => {
  const workbook = new exceljs.Workbook()
  const worksheet = workbook.addWorksheet(sheetName)
  worksheet.columns = columns

  data.forEach((rowData, index) => {
    const rowEntry = columns.map(({ accessor, ...column }) => {
      let value = rowData[accessor] || rowData[column.key]

      if (typeof accessor === 'function') {
        value = accessor(rowData)
      }

      return [column.key, value]
    })
    const row = Object.fromEntries(rowEntry)
    worksheet.insertRow(index + 2, row)
  })

  autoWidthColumns(worksheet)

  workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    fs.saveAs(blob, fileName || sheetName + '.xlsx')
  })
}

const autoWidthColumns = (worksheet) => {
  worksheet.columns.forEach((column) => {
    let dataMax = 0
    column.eachCell({ includeEmpty: true }, (cell) => {
      const length = cell.value?.toString().length
      dataMax = length > dataMax ? length : dataMax
    })

    column.width = dataMax + 2
  })
}
