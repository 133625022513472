import { combineReducers } from 'redux'
import { authenticationReducer } from './authentication.reducer'
import { userReducer } from './user.reducer'
import { loadingBarReducer } from 'react-redux-loading-bar'
import { paymentsReducer } from './payments.reducer'
import { documentReducer } from './document.reducer'

export const rootReducer = combineReducers({
  authentication: authenticationReducer,
  user: userReducer,
  payments: paymentsReducer,
  document: documentReducer,
  loadingBar: loadingBarReducer,
})
