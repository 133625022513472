import React from 'react'
import { Box, Button, VStack } from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import { InputDateForm } from 'chakra-lib/InputDateForm'
import { Form } from 'chakra-lib/layout'
import { SelectForm } from 'chakra-lib'
import { reportTypeOptions } from '../Reports.constants'
import * as Yup from 'yup'
import moment from 'moment/moment'

const formSchema = Yup.object({
  type: Yup.string().required('Не заполнен тип'),
  date: Yup.object({
    from: Yup.date()
      .typeError('Некорректная дата "от"')
      .required('Не выбрана дата "от"')
      .nullable(),
    to: Yup.date()
      .typeError('Некорректная дата "до"')
      .required('Не выбрана дата "до"')
      .test('3 month more', 'Диапазон больше 3 месяцев недопустим', async (value, context) => {
        const momentFrom = moment(context.parent.from).add(1, 'd')
        const momentTo = moment(value)
        const diff = momentTo.diff(momentFrom, 'month')

        return diff < 3
      })
      .test('to_limit', 'Конец диапазона не должен превышать начало', async (value, context) => {
        return value >= context.parent.from
      })
      .nullable(),
  }),
})

export const ReportsMutate = ({ onSubmit, isLoading }) => {
  return (
    <Formik enableReinitialize initialValues={{}} onSubmit={onSubmit} validationSchema={formSchema}>
      {(props) => {
        return (
          <Form>
            <VStack spacing="20px">
              <Field
                name="type"
                component={SelectForm}
                label="Тип"
                placeholder="Выберите тип"
                options={reportTypeOptions}
                isDisabled={isLoading}
                isClearable
              />

              <Field
                isDisabled={isLoading}
                name="date"
                label="Дата"
                placeholder="Выберите диапазон"
                component={InputDateForm}
                dayPickerProps={{
                  disabledDays: {
                    after: moment().add(-1, 'd').toDate(),
                  },
                }}
                isRangePick
              />
            </VStack>

            <Box pt="30px">
              <Button
                onClick={() => props.handleSubmit()}
                colorScheme="orange"
                isLoading={isLoading}
              >
                Отправить
              </Button>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}
