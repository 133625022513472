import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import useQuery from 'components/useQuery'
import { api } from 'api'

const useIdentificationsListFetch = (id) => {
  const toast = useToast()
  const { isError, isLoading, response, request } = useQuery(() =>
    api.identifications.fetchList(id)
  )

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось получить данные',
        status: 'error',
      })
    }
  }, [isError])

  useEffect(() => {
    request()
  }, [])

  return {
    identificationsList: response,
    isLoading,
  }
}

export default useIdentificationsListFetch
