import React from 'react'
import { Flex } from '@chakra-ui/react'

export const FormFooter = ({ children }) => {
  return (
    <Flex borderTopWidth={1} pt={4} mt={8}>
      {children}
    </Flex>
  )
}
