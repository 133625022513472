import useQuery from 'components/useQuery'
import { api } from 'api'
import { useEffect } from 'react'
import { useToast } from '@chakra-ui/react'

const useUpdateSettings = () => {
  const toast = useToast()
  const { isError, isSuccess, isLoading, request } = useQuery((data) =>
    api.settings.updateSwitch(data)
  )

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось получить данные',
        status: 'error',
      })
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Готово',
        description: 'Настройки успешно сохранены',
        status: 'success',
      })
    }
  }, [isSuccess])
  return {
    isLoadingUpdate: isLoading,
    updateSettings: request,
  }
}

export default useUpdateSettings
