import React, { useEffect, useState } from 'react'
import { FormControl, Icon, Input, InputGroup, InputRightElement, Text } from '@chakra-ui/react'
import { useDebounce } from 'use-debounce'
import { Search2Icon, SmallCloseIcon } from '@chakra-ui/icons'
import { FormLabel } from 'chakra-lib'

export const InputCardSearch = ({
  onChange = null,
  defaultValue = '',
  value = '',
  placeholder,
  label,
  name = 'search',
  hideIcon = false,
  ...props
}) => {
  const [text, setText] = useState(defaultValue ? defaultValue : value)
  const [valueDebounce] = useDebounce(text, 700)
  const maxSize = 16
  const isInvalid = text.length < maxSize && text.length !== 0

  
  useEffect(() => {
    if (value !== valueDebounce) {
      if (valueDebounce.length === maxSize || valueDebounce.length === 0) {
        onChange && onChange(valueDebounce)
      }
    }
  }, [valueDebounce])

  useEffect(() => {
    if (value !== text) {
      setText(value)
    }
  }, [value])

  return (
    <FormControl isInvalid={isInvalid}>
      {label && <FormLabel name={name}>{label}</FormLabel>}
      <InputGroup width="100%">
        <InputRightElement>
          {text ? (
            <Icon as={SmallCloseIcon} color="gray.600" onClick={() => setText('')} />
          ) : (
            !hideIcon && <Icon as={Search2Icon} color="gray.300" />
          )}
        </InputRightElement>
        <Input
          id={name}
          value={text || ''}
          onChange={(e) => {
            e.target.value.length <= maxSize && setText(e.target.value)
          }}
          placeholder={placeholder}
          {...props}
        />
      </InputGroup>
      <Text fontSize="0.76rem" opacity="0.5" mt={1}>
        {text.length} из {maxSize}
      </Text>
    </FormControl>
  )
}
