import React, { useCallback } from 'react'
import { Button, Text, useBoolean } from '@chakra-ui/react'
import {
  Container,
  FilterHeader,
  SnippetsContainer,
  SnippetsContainerMain,
  SnippetsContainerRightAside,
} from 'chakra-lib/layout'
import { ReportsList } from './ReportsList/ReportsList'
import { Modal } from '../../chakra-lib'
import { ReportsMutate } from './ReportsMutate'
import { useSendPaymentsRegistry } from './hooks/useSendPaymentsRegistry'
import useFetchReportsList from './hooks/useFetchReportsList'

export const Reports = () => {
  const [createFormIsOpen, setOpenCreate] = useBoolean()
  const { mutate: sendRegistry, isLoading: formIsLoading } = useSendPaymentsRegistry({
    onSuccess: () => {
      setOpenCreate.off()
    },
  })
  const { lastPage, list, isLoading: listIsLoading, hasMore, fetchNextPage } = useFetchReportsList()

  const onSubmitHandle = useCallback((values) => {
    sendRegistry(values)
  }, [])

  return (
    <Container>
      <SnippetsContainer indentTop={70}>
        <SnippetsContainerMain>
          <ReportsList
            data={list}
            hasMore={hasMore}
            isLoading={listIsLoading}
            onNext={fetchNextPage}
          />
        </SnippetsContainerMain>
        <SnippetsContainerRightAside>
          <FilterHeader>
            <Button width="100%" onClick={setOpenCreate.on} colorScheme="orange">
              Отправить заявку
            </Button>
            <Text
              fontSize="0.8rem"
              color="gray.300"
              mt={2}
              textAlign="center"
            >{`Найдено: ${lastPage?.totalElements}`}</Text>
          </FilterHeader>
        </SnippetsContainerRightAside>
      </SnippetsContainer>
      <Modal isOpen={createFormIsOpen} onClose={setOpenCreate.off} title="Отправить заявку">
        <ReportsMutate onSubmit={onSubmitHandle} isLoading={formIsLoading} />
      </Modal>
    </Container>
  )
}
