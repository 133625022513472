import axios from 'axios'
import { SERVER_KEYCLOAK } from 'config/api'

const ENTITY = 'keycloak/auth/admin/realms/idpay_realm'

export const keycloak = Object.freeze({
  async fetchRolesList() {
    return await axios.get(`${SERVER_KEYCLOAK}${ENTITY}/roles`)
  },
  async fetchUserList() {
    return await axios.get(`${SERVER_KEYCLOAK}${ENTITY}/users`)
  },
  async fetchUserItem(id) {
    return await axios.get(`${SERVER_KEYCLOAK}${ENTITY}/users/${id}`)
  },
  async fetchUserRoleMappings(id) {
    return await axios.get(`${SERVER_KEYCLOAK}${ENTITY}/users/${id}/role-mappings`)
  },
  async createUserRoleMappings(id, data) {
    return await axios.post(`${SERVER_KEYCLOAK}${ENTITY}/users/${id}/role-mappings/realm`, data)
  },
  async deleteUserRoleMappings(id, data) {
    const response = await axios.request({
      url: `${SERVER_KEYCLOAK}${ENTITY}/users/${id}/role-mappings/realm`,
      method: 'DELETE',
      data,
    })
    return response.data
  },
  async createUserItem(data) {
    return await axios.post(`${SERVER_KEYCLOAK}${ENTITY}/users`, data)
  },
  async deleteUserItem(id) {
    return await axios.delete(`${SERVER_KEYCLOAK}${ENTITY}/users/${id}`)
  },
  async updateUserItem(id, data) {
    return await axios.put(`${SERVER_KEYCLOAK}${ENTITY}/users/${id}`, data)
  },
  async updatePasswordUserItem(id, data) {
    return await axios.put(`${SERVER_KEYCLOAK}${ENTITY}/users/${id}/reset-password`, data)
  },
})
