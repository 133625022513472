import axios from 'axios'
import { API_URL, SERVER } from 'config/api'
import JSONbig from 'json-bigint'

const JSONBigString = JSONbig({
  strict: false,
  storeAsString: true,
})

const ENTITY = 'payments'

export const payments = Object.freeze({
  async fetchPaymentsList(data) {
    const response = await axios.post(`${SERVER}${API_URL}/${ENTITY}/search`, data, {
      transformResponse: [(data) => JSONBigString.parse(data)],
    })
    return response.data
  },
  async competePayment(id, data) {
    const response = await axios.post(`${SERVER}${API_URL}/${ENTITY}/${id}/completePayment`, data)
    return response.data
  },
  async finishedPayment(id) {
    const response = await axios.get(`${SERVER}${API_URL}/${ENTITY}/manual2finish`, {
      params: { id },
    })
    return response.data
  },
  async finishPayment(id, data) {
    const response = await axios.post(`${SERVER}${API_URL}/${ENTITY}/${id}/finishPayment`, data)
    return response.data
  },
  async fetchSettings() {
    const response = await axios.get(`${SERVER}${API_URL}/${ENTITY}/settings`)
    return response.data
  },
  async fetchHistory(id) {
    const response = await axios.get(
      `${SERVER}${API_URL}/${ENTITY}/${id}/paymentStateHistory?page=0&size=1000`
    )
    return response.data
  },
  async updateSettings(id, data) {
    const response = await axios.put(`${SERVER}${API_URL}/${ENTITY}/settings/${id}`, data)
    return response.data
  },
  async sendSettingsRegistry(data) {
    const response = await axios.post(`${SERVER}${API_URL}/${ENTITY}/settings/registry`, data)
    return response.data
  },
  async sendParityRegistry(data) {
    const response = await axios.post(`${SERVER}${API_URL}/${ENTITY}/settings/parity/registry`, data)
    return response.data
  },
  async fetchSum(data) {
    const response = await axios.post(`${SERVER}${API_URL}/${ENTITY}/sum`, data)
    return response.data
  },
  async fetchReports(params) {
    const response = await axios.get(`${SERVER}${API_URL}/${ENTITY}/settings/monthlyRegistry`, {
      params,
    })
    return response.data
  },
  async createReport(payload) {
    const { type, ...data } = payload

    const response = await axios.post(
      `${SERVER}${API_URL}/${ENTITY}/settings/monthlyRegistry`,
      data,
      {
        params: {
          type,
        },
      }
    )
    return response.data
  },
})
