import React, { useCallback, useRef } from 'react'
import { ContentContainer, ContentContainerColumn } from 'chakra-lib/layout'
import { Snippets, SnippetsHeading, SnippetsItem, SnippetsList } from 'chakra-lib'
import { HeadingItem, ItemBadge, ItemIcon, ItemLink, ItemText } from 'chakra-lib/Snippets'
import {
  TYPE_PAYMENT_COLOR,
  TYPE_PAYMENT_ICON,
  TYPE_PAYMENT_LABEL,
  TYPE_PAYMENT_STATE_COLOR,
  TYPE_PAYMENT_STATE_LABEL,
} from 'model/payments.model'
import { Badge, Stack } from '@chakra-ui/react'
import { getDateTimeReadFormat, getDateTimeToSubmit } from 'utils/dateFormatting'
import { PaymentsFilters } from './elements/PaymentsFilters'
import { PaymentsActions } from './elements/PaymentsActions'
import { useFetchPaymentsList } from './hooks/useFetchPaymentsList'

export const Payments = ({ user }) => {
  const filtersRef = useRef()
  const { fetch, list, hasMore, fetchNextPage, isFetching } = useFetchPaymentsList(
    {
      enabled: !!user.phone,
    },
    { payerPhone: user.phone }
  )

  const onChangeFilters = useCallback(
    (state) => {
      filtersRef.current = state

      const { paymentDateFrom, paymentDateTo, paymentState } = state

      fetch({
        paymentDateFrom: paymentDateFrom ? getDateTimeToSubmit(paymentDateFrom) : undefined,
        paymentDateTo: paymentDateTo ? getDateTimeToSubmit(paymentDateTo) : undefined,
        paymentState: paymentState !== 'all' || !paymentState ? paymentState : undefined,
        isFromUserPage: true
      })
    },
    [user]
  )

  return (
    <ContentContainer templateColumns="minmax(600px, 2000px) 300px" gap="30px" pb={0}>
      <ContentContainerColumn>
        <Snippets
          columns="42px 180px 120px 120px minmax(150px, 1fr) 180px 180px 220px 200px 200px"
          itemWidth="1300px"
        >
          <SnippetsHeading>
            <HeadingItem />
            <HeadingItem>ID</HeadingItem>
            <HeadingItem>Сумма</HeadingItem>
            <HeadingItem>Комиссия</HeadingItem>
            <HeadingItem>Получатель</HeadingItem>
            <HeadingItem>Дата</HeadingItem>
            <HeadingItem>Источник</HeadingItem>
            <HeadingItem>Тип</HeadingItem>
            <HeadingItem>Статус</HeadingItem>
          </SnippetsHeading>
          <SnippetsList
            data={list}
            scrollableTarget="scrollableCardContainer"
            hasMore={hasMore}
            isLoading={isFetching}
            onNext={() => {
              fetchNextPage()
            }}
          >
            {(item) => (
              <SnippetsItem to={`/payments/show/0/${item.id}`}>
                <ItemIcon
                  icon={TYPE_PAYMENT_ICON[item.paymentType]}
                  color={TYPE_PAYMENT_COLOR[item.paymentType]}
                  boxSize="1.1rem"
                />
                <ItemText>{item.id}</ItemText>
                <ItemLink>
                  <b>{item.amount}</b>
                  <Badge
                    ml={2}
                    fontSize="0.7rem"
                    fontWeight="500"
                    verticalAlign="middle"
                    colorScheme="gray"
                  >
                    {item.currency}
                  </Badge>
                </ItemLink>

                <ItemLink>
                  <b>{item.commission}</b>
                  <Badge
                    ml={2}
                    fontSize="0.7rem"
                    fontWeight="500"
                    verticalAlign="middle"
                    colorScheme="gray"
                  >
                    {item.commissionCurrency}
                  </Badge>
                </ItemLink>
                <ItemText>{item.payeePhone}</ItemText>
                <ItemText>{getDateTimeReadFormat(item.paymentDate)}</ItemText>
                <ItemText>{item.paymentSource}</ItemText>
                <ItemText>{TYPE_PAYMENT_LABEL[item.paymentType]}</ItemText>
                <ItemText>
                  <ItemBadge colorScheme={TYPE_PAYMENT_STATE_COLOR[item.state] || 'gray'}>
                    {TYPE_PAYMENT_STATE_LABEL[item.state] || item.state}
                  </ItemBadge>
                </ItemText>
              </SnippetsItem>
            )}
          </SnippetsList>
        </Snippets>
      </ContentContainerColumn>
      <ContentContainerColumn>
        <Stack spacing="24px" position="sticky" top="10px">
          <PaymentsFilters onChange={onChangeFilters} />
          <PaymentsActions user={user} filters={filtersRef.current} />
        </Stack>
      </ContentContainerColumn>
    </ContentContainer>
  )
}
