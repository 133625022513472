import React, { useEffect, useRef, useState } from 'react'
import { TYPE_PAYMENT_STATE_LABEL } from 'model/payments.model'
import { FilterStackGroup } from 'chakra-lib/layout'
import { InputDate, Select } from 'chakra-lib'
import { Button } from '@chakra-ui/react'

export const PaymentsFilters = ({ onChange }) => {
  const [state, setState] = useState({})
  const prevState = useRef(state)

  useEffect(() => {
    if (state !== prevState.current) {
      onChange(state)
      prevState.current = state
    }
  }, [state])

  const handleFilter = (value) => {
    setState((state) => ({ ...state, ...value }))
  }

  const handleResetFilter = () => {
    setState({})
  }

  return (
    <>
      <Select
        name="paymentState"
        label="Статус"
        isSearchable={false}
        placeholder="Выберите"
        value={state?.paymentState}
        options={[
          {
            value: 'all',
            label: 'Все',
          },
          ...Object.entries(TYPE_PAYMENT_STATE_LABEL).map(([id, field]) => ({
            value: id,
            label: field,
          })),
        ]}
        onChange={(e) => handleFilter({ paymentState: e.value })}
      />
      <FilterStackGroup>
        <InputDate
          value={state?.paymentDateFrom}
          showCurrentDate={false}
          name="paymentDateFrom"
          placement="bottom"
          onChange={(e) => handleFilter({ paymentDateFrom: e })}
          label="Дата платежа"
          placeholder="C"
        />
        <InputDate
          value={state?.paymentDateTo}
          showCurrentDate={false}
          name="paymentDateTo"
          placement="bottom"
          onChange={(e) => handleFilter({ paymentDateTo: e })}
          placeholder="По"
        />
      </FilterStackGroup>
      <Button size="sm" width="full" onClick={handleResetFilter}>
        Очистить фильтр
      </Button>
    </>
  )
}
