import React from 'react'
import { Input } from '../Input'

export const InputForm = ({ field, form, sizeInput, label, ...props }) => {
  return (
    <Input
      name={field.name}
      label={label}
      isInvalid={form.errors[field.name] && form.touched[field.name]}
      {...field}
      {...props}
    />
  )
}