import React, { useEffect, useState } from 'react'
import { Box, Button, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { Input, Modal } from 'chakra-lib'
import useResetPassword from './useResetPassword'

export const Password = ({ id }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [value, setValue] = useState('')
  const { request, isLoading, isSuccess } = useResetPassword(id)

  const handleForm = async () => {
    const dataRequest = {
      type: 'password',
      temporary: false,
      value,
    }
    await request(dataRequest)
  }

  useEffect(() => {
    if (isSuccess) {
      setValue('')
      onClose()
    }
  }, [isSuccess])

  return (
    <>
      <Stack spacing={2}>
        <Text>Для нового пользователя необходимо вручную установить пароль</Text>
        <Box>
          <Button colorScheme="gray" isLoading={isLoading} onClick={onOpen} size="sm">
            Изменить пароль
          </Button>
        </Box>
      </Stack>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title="Изменение пароля"
        footer={
          <Button colorScheme="orange" isLoading={isLoading} onClick={handleForm}>
            Изменить
          </Button>
        }
      >
        <Input
          autoFocus
          type="password"
          name="value"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          label="Пароль"
          isDisabled={isLoading}
        />
      </Modal>
    </>
  )
}
