import React from 'react'
import {Route} from 'react-router-dom'
import {Box} from '@chakra-ui/react'

/*export const getAccess = (authorities, hasAnyRole) => {
    if (authorities && authorities.length !== 0) {
        if (hasAnyRole.length === 0) {
            return true;
        }
        return hasAnyRole.includes(authorities);
    }
    return false;
};*/

export const getAccess = (authorities, hasAnyRoles) => {
  if (authorities && authorities.length !== 0) {
    if (hasAnyRoles.length === 0) {
      return true
    }
    return hasAnyRoles.some((auth) => authorities.includes(auth))
  }
  return false
}

export const PrivateRoute = ({ path, role, hasAnyRole, children, ...props }) => {
  const access = getAccess(role, hasAnyRole)
  return (
    <Route path={path} {...props}>
      {access ? children : 'Нет доступа'}
    </Route>
  )
}

export const PrivateBox = ({ role, hasAnyRole, children, ...props }) => {
  const access = getAccess(role, hasAnyRole)
  if (access) {
    return <Box {...props}>{children}</Box>
  }
  return null
}

export const Private = ({ role, hasAnyRole, children }) => {
  const access = getAccess(role, hasAnyRole)
  return <>{access ? children : null}</>
}
