export const columns = [
  {
    accessor: 'amount',
    header: 'Сумма списания',
    key: 'amount',
  },
  {
    accessor: 'currency',
    header: 'Валюта списания',
    key: 'currency',
  },
  {
    accessor: 'commission',
    header: 'Сумма комиссии',
    key: 'commission',
  },
  {
    accessor: 'commissionCurrency',
    header: 'Валюта комиссии',
    key: 'commissionCurrency',
  },
  {
    accessor: 'destinationAmount',
    header: 'Сумма к выдаче получателю',
    key: 'destinationAmount',
  },
  {
    accessor: 'destinationCurrency',
    header: 'Валюта к выдаче получателю',
    key: 'destinationCurrency',
  },
  {
    accessor: 'payeeFIO',
    header: 'ФИО получателя',
    key: 'payeeFIO',
  },
  {
    accessor: 'paymentDestination',
    header: 'Номер карты Получателя',
    key: 'paymentDestination',
  },
  {
    accessor: 'payeePhone',
    header: 'Номер телефона Получателя',
    key: 'payeePhone',
  },
  {
    accessor: 'payerPhone',
    header: 'Номер телефона отправителя',
    key: 'payerPhone',
  },
  {
    accessor: 'paymentDate',
    header: 'Дата и время перевода',
    key: 'paymentDate',
  },
  {
    accessor: 'paymentSource',
    header: 'Источник (карта, счет)',
    key: 'paymentSource',
  },
  {
    accessor: 'paymentType',
    header: 'Тип перевода',
    key: 'paymentType',
  },
  {
    accessor: 'state',
    header: 'Статус перевода',
    key: 'state',
  },
  {
    accessor: 'id',
    header: 'PaymentID',
    key: 'id',
  },
]
