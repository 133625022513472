import axios from 'axios'
import {API_URL, SERVER} from 'config/api'

const ENTITY = 'config'
const SMS_API_URL = 'sms-service/api/v1'

const SMS_URL = `${SERVER}${SMS_API_URL}/${ENTITY}`
const MAILING_URL = `${API_URL}/${ENTITY}`

export const config = Object.freeze({
  async getMailingProviders() {
    const response = await axios.get(`${MAILING_URL}/providers`)
    return response.data
  },
  async saveMailingProviders(data) {
    const response = await axios.post(`${MAILING_URL}/providers`, data)
    return response.data
  },
  async getSmsProviders() {
    const response = await axios.get(`${SMS_URL}/providers`)
    return response.data
  },
  async saveSmsProviders(data) {
    const response = await axios.post(`${SMS_URL}/providers`, data)
    return response.data
  },
})
