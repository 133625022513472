import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import { Spinner } from 'chakra-lib'

export const Loading = () => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      alignContent="center"
      alignItems="center"
      padding="60px"
    >
      <Text>Загрузка...</Text>
      <Spinner color="blue.500" size="md" marginTop="20px" />
    </Box>
  )
}
