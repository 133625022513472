import { FAILURE, REQUEST, SUCCESS } from './actions-type'
import axios from 'axios'
import { API_URL, SERVER } from 'config/api'

const PAGE_SIZE = 20
const START_PAGE = 0

export const ACTION_TYPES = {
  FETCH_LIST: 'document/FETCH_LIST',
  RESET_LIST: 'document/RESET_LIST',
  UPDATE_FILTER: 'document/UPDATE_FILTER',
  CLEAR_FILTER: 'document/CLEAR_FILTER',
  RESET_FILTER: 'document/RESET_FILTER',
  FETCH_ITEM: 'document/FETCH_ITEM',
  CREATE_ITEM: 'document/CREATE_ITEM',
  UPDATE_ITEM: 'document/UPDATE_ITEM',
  RESET_ITEM: 'document/RESET_ITEM',
  RESET_ERROR: 'document/RESET_ERROR',
  RESET: 'document/RESET',
}

const initialState = {
  list: [],
  item: {},
  loading: false,
  loadingUpdate: false,
  errorMessage: '',
  error: false,
  totalItems: 0,
  totalPages: 0,
  activePage: START_PAGE,
  filter: {
    removed: 'false',
  },
}

export const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ITEM): {
      return {
        ...state,
        loading: true,
        error: false,
      }
    }
    case REQUEST(ACTION_TYPES.CREATE_ITEM):
    case REQUEST(ACTION_TYPES.UPDATE_ITEM): {
      return {
        ...state,
        loadingUpdate: true,
        errorMessage: '',
        error: false,
      }
    }
    case FAILURE(ACTION_TYPES.FETCH_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ITEM):
    case FAILURE(ACTION_TYPES.CREATE_ITEM):
    case FAILURE(ACTION_TYPES.UPDATE_ITEM): {
      return {
        ...state,
        loading: false,
        loadingUpdate: false,
        errorMessage: action.payload.response.data?.errorText,
        error: true,
      }
    }
    case SUCCESS(ACTION_TYPES.FETCH_LIST): {
      const list =
        action.payload.data.page === 0
          ? action.payload.data.documents
          : state.list.concat(action.payload.data.documents)
      return {
        ...state,
        loading: false,
        list,
        totalItems: action.payload.data.totalElements,
        totalPages: action.payload.data.totalPages,
        activePage: action.payload.data.page,
        error: false,
      }
    }
    case SUCCESS(ACTION_TYPES.FETCH_ITEM): {
      return {
        ...state,
        item: action.payload.data,
        loading: false,
        error: false,
      }
    }
    case SUCCESS(ACTION_TYPES.CREATE_ITEM): {
      return {
        ...state,
        //item: action.payload.data,
        loading: false,
        loadingUpdate: false,
        errorMessage: '',
        error: false,
      }
    }
    case SUCCESS(ACTION_TYPES.UPDATE_ITEM): {
      return {
        ...state,
        item: action.payload.data,
        loading: false,
        loadingUpdate: false,
        errorMessage: '',
        error: false,
      }
    }
    case ACTION_TYPES.UPDATE_FILTER: {
      return {
        ...state,
        list: [],
        filter: {
          ...initialState.filter,
          ...action.filter,
        },
      }
    }
    case ACTION_TYPES.CLEAR_FILTER: {
      return {
        ...state,
        list: [],
        filter: {
          ...initialState.filter,
        },
      }
    }
    case ACTION_TYPES.RESET_FILTER: {
      return {
        ...state,
        filter: {
          ...initialState.filter,
        },
      }
    }
    case ACTION_TYPES.RESET_LIST: {
      return {
        ...state,
        list: [],
        loading: false,
        loadingUpdate: false,
        totalItems: 0,
        totalPages: 0,
        activePage: START_PAGE,
        error: false,
      }
    }
    case ACTION_TYPES.RESET_ITEM: {
      return {
        ...state,
        item: {},
        loading: false,
        loadingUpdate: false,
        error: false,
      }
    }
    case ACTION_TYPES.RESET: {
      return {
        ...initialState,
      }
    }
    case ACTION_TYPES.RESET_ERROR: {
      return {
        ...state,
        errorMessage: '',
        error: false,
      }
    }
    default:
      return state
  }
}

const API_URL_DOCUMENT = `${SERVER}${API_URL}/documents`

export const getUserList = (page = 1, pageSize = 20) => {
  const requestUrl = `${API_URL_DOCUMENT}?size=${pageSize}&page=${page}`
  return {
    type: ACTION_TYPES.FETCH_LIST,
    payload: axios.get(requestUrl),
  }
}

export const getDocumentListSearch = (dataRequest) => (dispatch, getState) => {
  const filter = getState().document.filter
  const requestUrl = `${API_URL_DOCUMENT}/search`

  dispatch({
    type: ACTION_TYPES.FETCH_LIST,
    payload: axios.post(requestUrl, {
      size: PAGE_SIZE,
      page: START_PAGE,
      removed: filter.removed,
      style: filter.style,
      ...dataRequest,
    }),
  })
}

export const getNextPage = () => (dispatch, getState) => {
  const activePage = getState().document.activePage
  dispatch(
    getDocumentListSearch({
      page: activePage + 1,
    })
  )
}

export const getDocumentItem = (id) => {
  const requestUrl = `${API_URL_DOCUMENT}/${id}`
  return {
    type: ACTION_TYPES.FETCH_ITEM,
    payload: axios.get(requestUrl),
  }
}

export const updateFilter = (params) => async (dispatch) => {
  await dispatch({
    type: ACTION_TYPES.UPDATE_FILTER,
    filter: {
      ...params,
    },
  })
  dispatch(
    getDocumentListSearch({
      page: START_PAGE,
    })
  )
}

export const clearFilter = () => async (dispatch) => {
  await dispatch({
    type: ACTION_TYPES.CLEAR_FILTER,
  })
  dispatch(
    getDocumentListSearch({
      page: START_PAGE,
    })
  )
}

export const createDocument = (dataRequest) => {
  const requestUrl = `${API_URL_DOCUMENT}`
  return {
    type: ACTION_TYPES.CREATE_ITEM,
    payload: axios.post(requestUrl, { ...dataRequest }),
  }
}

export const updateDocument = (dataRequest) => {
  const requestUrl = `${API_URL_DOCUMENT}`
  return {
    type: ACTION_TYPES.UPDATE_ITEM,
    payload: axios.patch(requestUrl, { ...dataRequest }),
  }
}

export const resetFilter = () => {
  return {
    type: ACTION_TYPES.RESET_FILTER,
  }
}

export const resetDocumentList = () => {
  return {
    type: ACTION_TYPES.RESET_LIST,
  }
}

export const resetDocumentItem = () => {
  return {
    type: ACTION_TYPES.RESET_ITEM,
  }
}

export const resetError = () => {
    return {
        type: ACTION_TYPES.RESET_ERROR,
    }
}

export const reset = () => {
    return {
        type: ACTION_TYPES.RESET,
    }
}
