import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { Login } from '../Login'
import { useSelector } from 'react-redux'
import { AccessList } from './AccessList/AccessList'
import { AccessUpdate } from './AccessUpdate'

const Routes = () => {
  const match = useRouteMatch()
  const isAuthenticated = useSelector((state) => state.authentication.isAuthenticated)
  return isAuthenticated ? (
    <>
      <Switch>
        <Route path={`${match.url}/new`} exact>
          <AccessUpdate />
        </Route>
        <Route path={`${match.url}/edit/:id`} exact>
          <AccessUpdate />
        </Route>
        <Route path={`${match.url}`}>
          <AccessList />
        </Route>
      </Switch>
    </>
  ) : (
    <Login />
  )
}

export default Routes
