import { api } from 'api'
import toast from 'utils/toast'
import { useInfiniteQuery } from 'react-query'
import { useState } from 'react'

export const useFetchBlackList = (initialParams) => {
  const [params, setParams] = useState(initialParams)

  let list = []
  let lastPage

  const query = useInfiniteQuery(
    ['blackList', params],
    ({ pageParam = 0 }) => {
      return api.blackList.fetchList({ pageNumber: pageParam, pageSize: 20, ...params })
    },
    {
      getNextPageParam: ({ number }) => number + 1,
      keepPreviousData: true,
      onError: (err) => {
        toast.showError({
          title: 'Произошла ошибка при загрузки черного списка',
          description: err.message,
        })
      },
    }
  )

  if (query.data?.pages) {
    const content = query.data.pages.reduce((acc, item) => [...acc, ...item.content], [])
    list = content?.length ? content : []
    lastPage = query.data.pages[query.data.pages.length - 1]
  }

  return {
    fetch: setParams,
    list,
    hasMore: !lastPage?.last,
    ...query,
  }
}
