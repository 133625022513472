export const FILTER_USER_PARAMS = [
  'clientId',
  'firstName',
  'lastName',
  'middleName',
  'passportNumber',
  'phone',
  'email',
  'platform',
  'applicationVersion',
  'identificationDateFrom',
  'identificationDateTo',
  'registrationDateFrom',
  'registrationDateTo',
  'identification',
  'enabled',
  'trusted',
]
