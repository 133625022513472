export const bannerTypes = {
  market: 'MARKET',
  alert: 'ALERT',
  info: 'INFO',
}

export const bannerIdentification = {
  nome: 'NONE',
  full: 'FULL',
  fullDossier: 'FULL_DOSSIER',
}

export const bannerIdentificationTitle = {
  [bannerIdentification.nome]: 'Неидентифицированные пользователи',
  [bannerIdentification.full]: 'УПРИД',
  [bannerIdentification.fullDossier]: 'Полная ИД',
}

export const bannerIdentificationId = {
  [bannerIdentification.nome]: 2,
  [bannerIdentification.full]: 4,
  [bannerIdentification.fullDossier]: 3,
}

export const bannerIdentificationOptions = Object.values(bannerIdentification).map((item) => ({
  value: item,
  label: bannerIdentificationTitle[item],
}))
