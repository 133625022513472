import { passportCountry } from './UserEditForm.constants'

export const getPassportSeriesAndNumber = (passport: string, country: string) => {
  const prepared = passport ? passport.replaceAll(' ', '') : passport

  if (country === passportCountry.RU) {
    return {
      series: prepared?.slice(0, 4),
      number: prepared?.slice(4),
    }
  }

  if (country === passportCountry.ARM) {
    return {
      series: prepared?.slice(0, 2),
      number: prepared?.slice(2),
    }
  }
}
