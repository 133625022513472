import { Heading } from 'chakra-lib'
import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn,
  HeadingContainer,
} from 'chakra-lib/layout'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { ItemForm } from './ItemForm'
import { useBlackListAdd } from './services/useBlackListAdd'

export const Item = () => {
  const history = useHistory()
  const handleBack = () => {
    history.goBack()
  }
  const { mutate: blackListAdd, isLoading } = useBlackListAdd({
    onSuccess: () => {
      handleBack()
    },
  })

  const onSubmit = useCallback((values) => {
    blackListAdd(values)
  }, [])

  return (
    <Container>
      <CardContainer onBack={handleBack} indentTop={120}>
        <ContentContainer>
          <ContentContainerColumn>
            <HeadingContainer>
              <Heading>Добавить</Heading>
            </HeadingContainer>
            <ItemForm onSubmit={onSubmit} isLoading={isLoading} />
          </ContentContainerColumn>
        </ContentContainer>
      </CardContainer>
    </Container>
  )
}
