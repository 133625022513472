import React from 'react'
import useIdentificationsListFetch from './services/useIdentificationsListFetch'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
} from '@chakra-ui/react'
import { getDateTimeReadFormat } from 'chakra-lib/utils/dateFormatting'
import {
  ContentContainer,
  ContentContainerColumn,
  GridField,
  GridFieldItem,
} from 'chakra-lib/layout'

export const IdentificationsList = ({ userId }) => {
  const { identificationsList, isLoading } = useIdentificationsListFetch(userId)
  return (
    <ContentContainer>
      <ContentContainerColumn>
        <Accordion defaultIndex={[0]} allowMultiple>
          {identificationsList?.map((item) => (
            <AccordionItem key={item.id}>
              <AccordionButton>
                <Flex flex="1" textAlign="left" minH="46px" alignItems="center">
                  {getDateTimeReadFormat(item.creationTime)}
                </Flex>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <GridField>
                  <GridFieldItem label="Дата попытки идентификации" isLoading={isLoading} isReady>
                    {getDateTimeReadFormat(item?.failureDate)}
                  </GridFieldItem>
                  <GridFieldItem label="Статус идентификации" isLoading={isLoading} isReady>
                    {item?.state}
                  </GridFieldItem>
                  <GridFieldItem label="Причина ошибки идентификации" isLoading={isLoading} isReady>
                    {item?.description}
                  </GridFieldItem>
                  <GridFieldItem label="Liveness" isLoading={isLoading} isReady>
                    {item?.liveness}
                  </GridFieldItem>
                  <GridFieldItem
                    label="Совпадение эталонного фото из базы данных Армении и селфи пользователя"
                    isLoading={isLoading}
                    isReady
                  >
                    {item?.idBankSimilarityRate &&
                      `${(item?.idBankSimilarityRate * 100).toFixed(2)} %`}
                  </GridFieldItem>
                  <GridFieldItem
                    label="Совпадение фотографии из паспорта и селфи пользователя"
                    isLoading={isLoading}
                    isReady
                  >
                    {item?.mobileAppSimilarityRate &&
                      `${(item?.mobileAppSimilarityRate * 100).toFixed(2)} %`}
                  </GridFieldItem>
                  <GridFieldItem label="Комментарий поддержки" isLoading={isLoading} isReady>
                    {item?.identificationSupportComment}
                  </GridFieldItem>
                </GridField>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </ContentContainerColumn>
    </ContentContainer>
  )
}
