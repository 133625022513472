export const FILTER_PAYMENTS_PARAMS = [
  'payeePhone',
  'payerPhone',
  'paymentAmountMax',
  'paymentAmountMin',
  'paymentDateFrom',
  'paymentDateTo',
  'paymentId',
  'paymentState',
  'payeeFIO',
  'payeeCardNumber',
  'paymentType',
]
