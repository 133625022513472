import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import { api } from 'api'
import useQuery from 'components/useQuery'

const useResetPassword = (id) => {
  const toast = useToast()
  const { isError, isSuccess, isLoading, request } = useQuery((data) =>
    api.keycloak.updatePasswordUserItem(id, data)
  )

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось установить пароль',
        status: 'error',
      })
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Готово',
        description: 'Пароль успешно установлен',
        status: 'success',
      })
    }
  }, [isSuccess])

  return {
    request,
    isLoading,
    isSuccess,
  }
}

export default useResetPassword
