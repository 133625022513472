import React from 'react'

import { Snippets, SnippetsHeading, SnippetsItem, SnippetsList } from 'chakra-lib'
import { HeadingItem, ItemIcon, ItemText } from 'chakra-lib/Snippets'
import { SiSublimetext } from 'react-icons/si'
import { getDateTimeReadFormat } from 'utils/dateFormatting'
import { useFetchJournalList } from './services'

export const UserEditHistory = ({ userId }) => {
  const { list, hasMore, fetchNextPage, isLoading } = useFetchJournalList({ objectId: userId })

  return (
    <Snippets
      columns="42px minmax(100px, 1fr) minmax(150px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) 220px 220px"
      itemWidth="1000px"
    >
      <SnippetsHeading>
        <HeadingItem />
        <HeadingItem>Таблица</HeadingItem>
        <HeadingItem>Поле</HeadingItem>
        <HeadingItem>Предыдущие данные</HeadingItem>
        <HeadingItem>Внесенные данные</HeadingItem>
        <HeadingItem>Дата изменения</HeadingItem>
        <HeadingItem>Кто изменил</HeadingItem>
      </SnippetsHeading>
      <SnippetsList
        data={list}
        isLoading={isLoading}
        hasMore={hasMore}
        scrollableTarget="scrollableCardContainer"
        onNext={() => fetchNextPage()}
      >
        {(item) => (
          <SnippetsItem>
            <ItemIcon icon={SiSublimetext} />
            <ItemText>{item?.tableName}</ItemText>
            <ItemText>{item?.columnName}</ItemText>
            <ItemText>{item?.valueOld}</ItemText>
            <ItemText>{item?.valueNew}</ItemText>
            <ItemText>{getDateTimeReadFormat(item?.created)}</ItemText>
            <ItemText>{item?.userName}</ItemText>
          </SnippetsItem>
        )}
      </SnippetsList>
    </Snippets>
  )
}