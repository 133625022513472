import React from 'react'
import { Box, VStack } from '@chakra-ui/react'

export const FilterStackGroup = ({ children }) => {
  return (
    <Box width="100%">
      <VStack spacing={3}>{children}</VStack>
    </Box>
  )
}
