import { api } from 'api'
import { useQuery, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import toast from 'utils/toast'
import { getUserItem } from 'store/user.reducer'

export const useSyncUser = ({ id }) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  return useQuery('useSyncUser', () => api.users.syncUser(id), {
    onError: (err) => {
      toast.showError({
        title: 'Произошла ошибка синхронизации',
        description: err.response?.data?.message || err.message,
      })
    },
    onSuccess: () => {
      dispatch(getUserItem(id))
      queryClient.invalidateQueries(['passportJournal'])

      toast.showSuccess({
        title: 'Данные успешно синхронизированы',
      })
    },
    enabled: false,
  })
}
