import axios from 'axios'
import { API_URL, SERVER } from 'config/api'

const ENTITY = 'black_list'

export const blackList = Object.freeze({
  async fetchList(params) {
    const response = await axios.get(`${SERVER}${API_URL}/${ENTITY}`, { params })
    return response.data
  },
  async add(data) {
    const response = await axios.post(`${SERVER}${API_URL}/${ENTITY}/add`, data)
    return response.data
  },
})
