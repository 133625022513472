import { api } from 'api'
import { useMutation } from 'react-query'
import toast from 'utils/toast'
import { DATE_FORMAT } from 'utils/dateFormatting'
import moment from 'moment/moment'

export const useFetchParityRegistry = () => {
  return useMutation(
    ({ date }) => {
      const momentDate = moment(date, DATE_FORMAT)

      return api.payments.sendParityRegistry({
        from: date ? momentDate.format(dateFormat) : undefined,
        to: date ? momentDate.add(1, 'd').format(dateFormat) : undefined,
      })
    },
    {
      onSuccess: () => {
        toast.showSuccess({
          title: 'Запрос на формирование отчета отправлен успешно',
        })
      },
      onError: (err) => {
        toast.showError({
          title: 'Произошла ошибка при отправке реестров',
          description: err.response?.data?.message || err.message,
        })
      },
    }
  )
}

const dateFormat = 'YYYY-MM-DD'
