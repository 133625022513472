import React from 'react'
import { Flex, useColorModeValue } from '@chakra-ui/react'

export const RadioButton = ({ isActive, children, ...props }) => {
  const themeBg = useColorModeValue('gray.100', 'gray.700')
  const themeBgHover = useColorModeValue('gray.200', 'gray.600')
  const themeBgPress = useColorModeValue('gray.300', 'gray.700')
  const themeBgActive = useColorModeValue('orange.500', 'orange.200')
  const themeBgPressActive = useColorModeValue('orange.700', 'orange.400')
  const themeBgHoverActive = useColorModeValue('orange.600', 'orange.300')
  const themeColor = useColorModeValue('white', 'black')
  return (
    <Flex
      minHeight="var(--chakra-sizes-8)"
      paddingInlineStart="var(--chakra-space-3)"
      paddingInlineEnd="var(--chakra-space-3)"
      fontWeight="500"
      alignItems="center"
      borderRadius="4px"
      backgroundColor={isActive ? themeBgActive : themeBg}
      cursor="pointer"
      transition="0.2s"
      {...{ color: isActive && themeColor }}
      {...props}
      _hover={{
        backgroundColor: isActive ? themeBgHoverActive : themeBgHover,
      }}
      _active={{
        backgroundColor: isActive ? themeBgPressActive : themeBgPress,
      }}
    >
      {children}
    </Flex>
  )
}
