import axios from 'axios'
import { API_URL, SERVER } from 'config/api'

const ENTITY = 'settings'

export const settings = Object.freeze({
  async fetchOpenApi() {
    const response = await axios.get(`${SERVER}${API_URL}/${ENTITY}/o`)
    return response.data
  },
  async updateOpenApi(data) {
    const response = await axios.put(`${SERVER}${API_URL}/openapi/${ENTITY}/1`, data)
    return response.data
  },
  async fetchSwitch() {
    const response = await axios.get(`${SERVER}${API_URL}/${ENTITY}/s`)
    return response.data
  },
  async updateSwitch(data) {
    const response = await axios.put(`${SERVER}${API_URL}/switch/${ENTITY}/0`, data)
    return response.data
  },
})
