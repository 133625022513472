import useQuery from 'components/useQuery'
import { api } from 'api'

const useFetchRef = () => {
  const { response, request } = useQuery((id) => api.referral.fetchRef(id))

  return {
    fetchRef: request,
    dataRef: response,
  }
}

export default useFetchRef
