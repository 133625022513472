import React, { useMemo } from 'react'
import { Field, Formik } from 'formik'

import { Heading, InputForm } from 'chakra-lib'
import { Button, Flex, VStack } from '@chakra-ui/react'
import { Form, FormGrid } from 'chakra-lib/layout'
import { useSelector } from 'react-redux'
import { useUpdateUserInternal } from './services'
import { formFields, formFieldsLabel } from './UserEditFormInternal.constants'

export const UserEditFormInternal = () => {
  const item = useSelector((state) => state.user.item)
  const loading = useSelector((state) => state.user.loading)

  const { mutate: sendUserData, isLoading: userDataIsMutate } = useUpdateUserInternal()

  const handleForm = async (values) => {
    sendUserData({
      id: item.id,
      ...values,
    })
  }

  const initialValues = useMemo(
    () => ({
      accountNumber: item.accountNumber || undefined,
      contractNumber: item.contractNumber || undefined,
      clientId: item.clientId || undefined,
    }),
    [item]
  )

  const fieldsIsDisabled = userDataIsMutate

  return (
    <VStack alignItems="flex-start" maxW="1000px">
      <Formik initialValues={initialValues} enableReinitialize onSubmit={handleForm}>
        {(formProps) => {
          return (
            <Form
              w="100%"
              onSubmit={formProps.handleSubmit}
              footer={
                <Flex w="100%" justifyContent="flex-end">
                  <Button
                    type="submit"
                    colorScheme="orange"
                    isLoading={loading || userDataIsMutate}
                    isDisabled={!formProps.isValid}
                  >
                    Обновить
                  </Button>
                </Flex>
              }
            >
              <Heading mb={4}>Документы</Heading>
              <FormGrid columns={2} alignItems="none">
                <Field
                  name={formFields.accountNumber}
                  label={formFieldsLabel[formFields.accountNumber]}
                  component={InputForm}
                  isDisabled={fieldsIsDisabled}
                />
                <Field
                  name={formFields.contractNumber}
                  label={formFieldsLabel[formFields.contractNumber]}
                  component={InputForm}
                  isDisabled={fieldsIsDisabled}
                />
                <Field
                  name={formFields.clientId}
                  label={formFieldsLabel[formFields.clientId]}
                  component={InputForm}
                  isDisabled={fieldsIsDisabled}
                />
              </FormGrid>
            </Form>
          )
        }}
      </Formik>
    </VStack>
  )
}
