import { api } from 'api'
import toast from 'utils/toast'
import { useMutation } from 'react-query'

export const useFetchStats = () => {
  return useMutation((data) => api.payments.fetchSum(data), {
    onError: (err) => {
      toast.showError({
        title: 'Произошла ошибка при загрузке статистики',
        description: err.response?.data?.message || err.message,
      })
    },
    cacheTime: 0,
  })
}
