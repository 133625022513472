export const passportCountry = {
  RU: 'RUSSIA',
  ARM: 'ARMENIA',
}

export const passportCountryLabel = {
  [passportCountry.RU]: 'РФ',
  [passportCountry.ARM]: 'РА',
}

export const passportCountryOptions = Object.values(passportCountry).map((item) => ({
  label: passportCountryLabel[item],
  value: item,
}))

export const errorCode = {
  DUPLICATE: 4
}

export const formFields = {
  series: 'series',
  number: 'number',
  dateIssue: 'dateIssue',
  issueOrgCode: 'issueOrgCode',
  issue: 'issue',
  identityCountry: 'identityCountry',
  phone: 'phone',
  email: 'email',
  firstName: 'firstName',
  lastName: 'lastName',
  middleName: 'middleName',
  country: 'country',
  regionName: 'regionName',
  regionType: 'regionType',
  districtName: 'districtName',
  settlementName: 'settlementName',
  settlementType: 'settlementType',
  streetName: 'streetName',
  streetType: 'streetType',
  houseNumber: 'houseNumber',
  block: 'block',
  unitNumber: 'unitNumber',
}

export const formFieldsLabel = {
  [formFields.series]: 'Серия',
  [formFields.number]: 'Номер',
  [formFields.dateIssue]: 'Дата выдачи',
  [formFields.issueOrgCode]: 'Код организации',
  [formFields.issue]: 'Организация выдавшая документ',
  [formFields.identityCountry]: 'Страна',
  [formFields.phone]: 'Телефон',
  [formFields.email]: 'Email',
  [formFields.firstName]: 'Имя',
  [formFields.lastName]: 'Фамилия',
  [formFields.middleName]: 'Отчество',
  [formFields.country]: 'Страна',
  [formFields.regionName]: 'Регион',
  [formFields.regionType]: 'Тип региона',
  [formFields.districtName]: 'Район',
  [formFields.settlementName]: 'Населенный пункт',
  [formFields.settlementType]: 'Тип населенного пункта',
  [formFields.streetName]: 'Улица',
  [formFields.streetType]: 'Тип улицы',
  [formFields.houseNumber]: 'Дом',
  [formFields.block]: 'Номер корпуса',
  [formFields.unitNumber]: 'Номер квартиры',
}

