import React from 'react'
import { Flex } from '@chakra-ui/react'

export const HeadingContainer = ({ children, ...props }) => {
  return (
    <Flex alignItems="center" justifyContent="space-between" height="44px" mb="30px" {...props}>
      {children}
    </Flex>
  )
}
