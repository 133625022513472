import React from 'react'
import { Box, Flex, useColorModeValue } from '@chakra-ui/react'
import { Link, useLocation } from 'react-router-dom'

const COLOR_TITLE_SELECTED = {
  orange: {
    light: 'orange.600',
    dark: 'orange.300',
  },
  blue: {
    light: 'blue.600',
    dark: 'blue.300',
  },
}

const COLOR_SELECTED = {
  orange: {
    light: 'orange.100',
    dark: 'orange.300',
  },
  blue: {
    light: 'blue.100',
    dark: 'blue.300',
  },
}

export const NavigationMenuItem = ({ isHidden, to, children, colorScheme = 'blue', ...props }) => {
  const themeBgSelected = useColorModeValue('gray.50', 'gray.700')
  const themeBgHover = useColorModeValue('gray.100', 'gray.600')
  const themeBgActive = useColorModeValue('gray.200', 'gray.800')
  const themeBgActiveSelected = useColorModeValue(
    COLOR_SELECTED[colorScheme].light,
    COLOR_SELECTED[colorScheme].dark
  )
  const themeColorFontTitleSelected = useColorModeValue(
    COLOR_TITLE_SELECTED[colorScheme].light,
    COLOR_TITLE_SELECTED[colorScheme].dark
  )
  const themeColorFontTitle = useColorModeValue('black', 'white')
  const location = useLocation()
  const { pathname } = location

  const isSelected = pathname.includes(to)
  if (!isHidden) {
    return (
      <Flex
        as={Link}
        to={to}
        alignItems="center"
        pl="10px"
        pr="10px"
        role="group"
        position="relative"
        height="32px"
        lineHeight={1}
        whiteSpace="nowrap"
        overflow="hidden"
        backgroundColor={isSelected ? themeBgSelected : 'transparent'}
        borderRadius={6}
        transition="0.2s"
        cursor="pointer"
        _active={{
          backgroundColor: isSelected ? themeBgActiveSelected : themeBgActive,
        }}
        _focus={{
          outline: 'none',
          boxShadow: 'outline',
          borderColor: 'gray.300',
        }}
        _hover={{
          backgroundColor: !isSelected && themeBgHover,
        }}
        {...props}
      >
        <Box
          fontWeight="normal"
          transition="0.2s"
          color={isSelected ? themeColorFontTitleSelected : themeColorFontTitle}
          flex={1}
        >
          {children}
        </Box>
      </Flex>
    )
  } else {
    return null
  }
}
