import * as Yup from 'yup'
import { passportCountry, formFields } from './UserEditForm.constants'
import { authorityCodeRegExp, fioRegExp, phoneRegExp } from 'utils/validationRegExp'

export const validationSchema = Yup.object().shape({
  [formFields.firstName]: Yup.string()
    .matches(fioRegExp, 'Недопустимые символы в имени')
    .required(),
  [formFields.lastName]: Yup.string()
    .matches(fioRegExp, 'Недопустимые символы в фамилии')
    .required(),
  [formFields.middleName]: Yup.string().matches(fioRegExp, 'Недопустимые символы в отчестве'),
  [formFields.email]: Yup.string().trim().email('Email'),
  [formFields.dateIssue]: Yup.string().matches(/\d{4}-\d{2}-\d{2}/, 'Неверный формат даты'),
  [formFields.issueOrgCode]: Yup.string().matches(
    authorityCodeRegExp,
    'Код подразделения не верный'
  ),
  [formFields.phone]: Yup.string()
    .matches(phoneRegExp, 'Телефон указан некорректно')
    .min(11, 'too short')
    .max(12, 'too long'),
  [formFields.series]: Yup.string()
    .when(formFields.identityCountry, {
      is: passportCountry.RU,
      then: Yup.string().matches(/^\d{4}$/),
    })
    .when(formFields.identityCountry, {
      is: passportCountry.ARM,
      then: Yup.string().matches(/^[A-Za-z]{2}$/),
    }),
  [formFields.number]: Yup.string()
    .when(formFields.identityCountry, {
      is: passportCountry.RU,
      then: Yup.string().matches(/^\d{6}$/),
    })
    .when(formFields.identityCountry, {
      is: passportCountry.ARM,
      then: Yup.string().matches(/^\d{7}$/),
    }),
})