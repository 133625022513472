import { api } from 'api'
import { useQuery } from 'react-query'
import toast from 'utils/toast'

export const useFetchProvidersSettings = (options) => {
  return useQuery('useFetchProvidersSettings', api.config.getSmsProviders, {
    onError: (err) => {
      toast.showError({
        title: 'Произошла ошибка при загрузке конфигурации провайдеров',
        description: err.response?.data?.message || err.message,
      })
    },
    cacheTime: 0,
    ...options,
  })
}
