import React from 'react'
import { Box, Flex, Image } from '@chakra-ui/react'
import FlagRu from '../../images/flag-ru.svg'
import FlagAm from '../../images/flag-am.svg'

const TYPE_FLAG = {
  RUSSIA: FlagRu,
  ARMENIA: FlagAm,
}

export const CountryWidget = ({ lang, children, ...props }) => {
  return (
    <Flex align="center" {...props}>
      <Image boxSize="20px" src={TYPE_FLAG[lang]} mr="10px" />
      <Box>{children}</Box>
    </Flex>
  )
}
