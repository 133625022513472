import React from 'react'

import { Tab, TabList } from '@chakra-ui/react'
import { TabPanel, TabPanels, Tabs } from 'chakra-lib'
import { UserEditForm } from './UserEditForm'
import { UserEditFormInternal } from './UserEditFormInternal'
import { UserEditHistory } from './UserEditHistory'
import { useSelector } from 'react-redux'
import { getAccess } from '../../../../components/Private'
import { ROLES } from '../../../../model/user.model'

export const UserEdit = ({ userId }) => {
  const user = useSelector((state) => state.authentication.user)
  const isAdmin = getAccess(user.roles, [ROLES.admin])

  return (
    <Tabs variant="soft-rounded" colorScheme="orange" size="sm">
      <TabList>
        {isAdmin && <Tab>Главная</Tab>}
        {isAdmin && <Tab>Дополнительно</Tab>}
        <Tab>История</Tab>
      </TabList>
      <TabPanels>
        {isAdmin && (
          <TabPanel>
            <UserEditForm />
          </TabPanel>
        )}
        {isAdmin && (
          <TabPanel>
            <UserEditFormInternal />
          </TabPanel>
        )}
        <TabPanel>
          <UserEditHistory userId={userId} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
