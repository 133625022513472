import { InfoIcon } from '@chakra-ui/icons'
import { Box, Button, HStack, Icon, Text, Tooltip, VStack, useBoolean } from '@chakra-ui/react'
import { useCallback } from 'react'
import { getDateTimeToSubmit } from 'utils/dateFormatting'
import { useClearChache } from '../hooks/useClearChache'
import { useFetchStats } from '../hooks/useFetchStats'
import { useFetchUserBalance } from '../hooks/useFetchUserBalance'
import { Modal } from 'chakra-lib'

export const PaymentsActions = ({ user, filters }) => {
  const [fetchUserBalance, { isLoading: userBalanceIsLoading, data: balance }] =
    useFetchUserBalance(user.id)

  const { mutate: fetchStats, data: stats, isLoading: statsIsLoading } = useFetchStats()
  const [IsOpenLimitModal, setOpenLimitModal] = useBoolean()
  const { mutate: clearLimit, isLoading: limitIsClearing } = useClearChache(user.id, {
    onSuccess: () => {
      setOpenLimitModal.off()
    },
  })

  const onShowBalance = useCallback(() => {
    fetchUserBalance()
  }, [])

  const onUpdateStats = useCallback(() => {
    const { paymentDateFrom, paymentDateTo, paymentState } = filters

    fetchStats({
      payerPhone: user.phone,
      paymentDateFrom: paymentDateFrom ? getDateTimeToSubmit(paymentDateFrom) : undefined,
      paymentDateTo: paymentDateTo ? getDateTimeToSubmit(paymentDateTo) : undefined,
      paymentState: paymentState !== 'all' || !paymentState ? paymentState : undefined,
    })
  }, [filters])

  const actionStatsIsEnabled = filters?.paymentState

  return (
    <>
      <VStack spacing={4} alignItems="stretch" width="full" mb="20px">
        <Box border="1px solid" borderColor="gray.300" p={4} borderRadius="20px">
          <Text fontSize="0.82rem" color="gray.400" mb={2}>
            Действия
          </Text>
          <Button
            w="full"
            colorScheme="gray"
            onClick={setOpenLimitModal.on}
            isLoading={limitIsClearing}
          >
            Сбросить лимит
          </Button>
        </Box>

        <Box>
          <HStack spacing={1}>
            <Text fontSize="18px">Статистика</Text>
            <Tooltip label="Для подсчета ежемесячных лимитов платежей необходимо выбрать диапазон дат с первого по последнее число месяца и статус платежа (Проведен)">
              <Icon cursor="pointer" as={InfoIcon} color="orange.300" mb="20px" />
            </Tooltip>
          </HStack>
          <Box>
            <Text fontSize="13px">{`Платежей найдено: ${
              stats?.numberOfPaymentsFound ?? ' - '
            }`}</Text>
            <Text fontSize="13px">{`Сумма платежей: ${
              stats?.paymentsTotalRub ? stats?.paymentsTotalRub + ' руб' : ' - '
            }`}</Text>
            <Text fontSize="13px">{`Сумма комиссий: ${
              stats?.commissionsTotalRub ? stats?.commissionsTotalRub + ' руб' : ' - '
            }`}</Text>
            <Text fontSize="13px">{`Сумма платежей c учетом комиссий: ${
              stats?.paymentsAndCommissionsTotalRub
                ? stats?.paymentsAndCommissionsTotalRub + ' руб'
                : ' - '
            }`}</Text>
          </Box>
          <Button
            isDisabled={!actionStatsIsEnabled}
            isLoading={statsIsLoading}
            mt="15px"
            size="sm"
            width="100%"
            onClick={onUpdateStats}
          >
            {!stats ? 'Получить' : 'Обновить'}
          </Button>
        </Box>

        <Box>
          <Box fontSize="14px" mb="10px">
            <Text fontWeight="bold">Баланс счета СКБ:</Text>
            <Box>
              <Text as="span">Все средства на счете (Saldo): </Text>
              <Text as="span" fontWeight="bold">
                {balance?.saldo ?? ' - '}
              </Text>
            </Box>
            <Box>
              <Text as="span">Доступные средства (Free no shadow): </Text>
              <Text as="span" fontWeight="bold">
                {balance?.freeNoShadow ?? ' - '}
              </Text>
            </Box>
            <Text fontWeight="bold">Локальный баланс:</Text>
            <Box>
              <Text as="span">Дебет: </Text>
              <Text as="span" fontWeight="bold">
                {balance?.localDebet ?? ' - '}
              </Text>
            </Box>
            <Box>
              <Text as="span">Резерв: </Text>
              <Text as="span" fontWeight="bold">
                {balance?.localReserve ?? ' - '}
              </Text>
            </Box>
          </Box>
          <HStack>
            <Button
              colorScheme="orange"
              size="sm"
              onClick={onShowBalance}
              isLoading={userBalanceIsLoading}
            >
              Показать баланс
            </Button>
          </HStack>
        </Box>
      </VStack>

      <Modal isOpen={IsOpenLimitModal} onClose={setOpenLimitModal.off} title="Сбросить лимит">
        <Text>Данное действие нельзя будет отменить.</Text>
        <Box pt="30px">
          <Button colorScheme="orange" isLoading={limitIsClearing} onClick={() => clearLimit()}>
            Продолжить
          </Button>
        </Box>
      </Modal>
    </>
  )
}
