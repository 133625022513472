import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Login } from '../../Login'
import React from 'react'
import { List } from './List'
import { Item } from './Item'

const Routes = () => {
  const match = useRouteMatch()
  const isAuthenticated = useSelector((state) => state.authentication.isAuthenticated)
  return isAuthenticated ? (
    <>
      <Switch>
        <Route path={`${match.url}/new`} exact>
          <Item />
        </Route>
        <Route path={`${match.url}/show/:id`} exact>
          <Item />
        </Route>
        <Route path={`${match.url}`}>
          <List />
        </Route>
      </Switch>
    </>
  ) : (
    <Login />
  )
}

export default Routes
