/* eslint-disable no-new */
import React, { useEffect, useRef, useState } from 'react'
import { isEmpty } from 'lodash'
import Compressor from 'compressorjs'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'

import {
  Box,
  Button,
  Collapse,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import { DropZoneImage } from '../DropZoneImage'
import { SegmentControl } from '../SegmentControl'

export const ImageCropper = ({
  title,
  isOpen,
  file,
  colorScheme = 'blue',
  onClose,
  onChange,
  isLoading,
  isCropRequired,
  optionsCroppedCanvas,
  optionsCropper,
  optionsCompress,
}) => {
  const toast = useToast()
  const containerTab = useRef()
  const [activeTab, setActiveTab] = useState(0)
  const [fileState, setFileState] = useState(null)
  const [isCropped, setIsCropped] = useState(false)
  const cropperRef = useRef(null)

  useEffect(() => {
    if (!isOpen) {
      return
    }
    if (!isEmpty(file)) {
      setFileState(file)
    } else {
      setFileState(null)
    }
    setActiveTab(0)
  }, [file, isOpen])

  const getCropData = () => {
    const imageElement = cropperRef?.current
    const cropper = imageElement?.cropper
    const result = {
      name: fileState.name,
      type: fileState.type,
      uri: cropper
        .getCroppedCanvas({
          fillColor: '#fff',
          imageSmoothingEnabled: false,
          imageSmoothingQuality: 'high',
          ...optionsCroppedCanvas,
        })
        .toDataURL(),
    }
    setIsCropped(true)
    setFileState(result)

    return result
  }

  const handleUpload = (acceptedFiles) => {
    const uploadFile = acceptedFiles[0]
    new Compressor(uploadFile, {
      ...optionsCompress,
      success(result) {
        const reader = new FileReader()
        reader.readAsDataURL(result)
        reader.onloadend = () => {
          setIsCropped(false)
          setFileState({
            name: uploadFile.name,
            type: uploadFile.type,
            uri: reader.result,
          })
          if (isCropRequired) {
            setActiveTab(1)
          }
        }
      },
      error(err) {
        console.error(err)
        toast({
          description: `Ошибка конвертирования`,
          variant: 'solid',
          status: 'error',
        })
      },
    })
  }

  const handleClose = () => {
    onClose && onClose()
  }

  const handleSave = () => {
    if (isCropRequired && activeTab === 0) {
      toast({
        description: `Отредактируйте изображение, чтобы сохранить`,
        variant: 'solid',
        status: 'warning',
      })
      return
    }

    if (activeTab === 1) {
      const cropData = getCropData()
      if (cropData?.uri.slice(0, 4) === 'data') {
        onChange && onChange(cropData)
      }
    } else if (fileState?.uri.slice(0, 4) === 'data') {
      onChange && onChange(fileState)
    }

    handleClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title ? title : 'Загрузка изображения'}</ModalHeader>
        <ModalCloseButton mt={1} />
        <ModalBody>
          <Box ref={containerTab}>
            <Collapse in={!!fileState?.uri} unmountOnExit={false}>
              {!!fileState?.uri && (
                <SegmentControl
                  width="100%"
                  value={activeTab}
                  onChange={(e) => setActiveTab(e)}
                  mb={4}
                  containerRef={containerTab}
                  options={[
                    {
                      label: 'Загрузка',
                      value: 0,
                    },
                    {
                      label: 'Обрезка',
                      value: 1,
                    },
                  ]}
                />
              )}
            </Collapse>
          </Box>
          {activeTab === 0 && (
            <DropZoneImage
              maxFiles={1}
              maxSize={4194304}
              height="528px"
              width="528px"
              multiple={false}
              uploadedImage={fileState?.uri}
              onUpload={handleUpload}
              onError={() => {
                toast({
                  description: `Изображение слишком большое`,
                  variant: 'solid',
                  status: 'error',
                })
              }}
            />
          )}
          {activeTab === 1 && !isEmpty(fileState) && (
            <Cropper
              src={fileState?.uri}
              style={{ height: 528, width: 528 }}
              guides={false}
              zoomable={false}
              checkCrossOrigin={false}
              ref={cropperRef}
              {...optionsCropper}
            />
          )}
        </ModalBody>
        <ModalFooter paddingBottom="24px" justifyContent="space-between">
          <Flex>
            {activeTab === 1 && isCropRequired && !isCropped && (
              <Text fontSize="0.8rem" color={`${colorScheme}.500`}>
                Отредактируйте изображение
              </Text>
            )}
          </Flex>
          <Box>
            <Button onClick={handleClose} mr={3}>
              Закрыть
            </Button>
            <Button
              colorScheme={colorScheme}
              onClick={handleSave}
              isDisabled={!fileState?.uri}
              isLoading={isLoading}
            >
              {activeTab === 0 ? 'Сохранить' : 'Обрезать и сохранить'}
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
