import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { Item } from './Item'
import { List } from './List'

export const Faq = () => {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.url}/new`} exact>
        <Item />
      </Route>
      <Route path={`${match.url}/show/:id`} exact>
        <Item />
      </Route>
      <Route path={`${match.url}`}>
        <List />
      </Route>
    </Switch>
  )
}
